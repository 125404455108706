import IconButton from "../components/IconButton";
import InputWithLabel from "../components/InputWithLabel";
import addIcon from "../assets/icons/AddIcon.png";

import { useEffect, useState } from "react";

import { common_file_upload } from "../constants/apis";
import { useAppContext } from "../context/AppContext";
import { useFieldArray, useFormContext } from "react-hook-form";
import { QualityAssuranceType } from "../schemas/QuallityAssuranceType";
import { toast } from "react-toastify";
import { useFetch } from "../hooks/useFetch";
import { fileToBase64 } from "../utils";
// import Spinner from "../components/Spinner";

import CrossIcon from "../components/Icons/CrossIcon";
import Dropdown from "../components/DropDown";
import { usePartnersDataContext } from "../context/PartnersDataContext";
import {
  company_address,
  company_name,
  company_reg_number,
  loading_inspection,
  quality_assurance_inspection,
  supplier_audit_inspection,
} from "../constants/tooltipContents";
import Tooltip from "../components/Tooltip";

const QualityAssurance = ({
  selectedCompany,
  setSelectedCompany,
  openModal,
}: {
  selectedCompany: QualityAssuranceType | undefined;
  setSelectedCompany: React.Dispatch<
    React.SetStateAction<QualityAssuranceType | undefined>
  >;
  openModal: (type: "update" | "add") => void;
}) => {
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const {
    register,
    formState: { errors },
    setValue,
    unregister,
    control,
    watch,
    clearErrors,
    getValues,
  } = useFormContext<QualityAssuranceType>();
  const { data: partnersData, loading: partnersDataLoading } =
    usePartnersDataContext();

  const watchSelectedCompany = watch("company_name");

  // useEffect(() => {
  //   const filteredData = partnersData
  //     ?.filter((el) => el.company_name === getValues("company_name"))
  //     .at(0);
  //   setSelectedCompany(filteredData);
  // }, [partnersDataLoading]);

  useEffect(() => {
    if (!watchSelectedCompany) return;
    const company = partnersData?.find(
      (el) => el.company_name === watchSelectedCompany
    );

    if (!company) return;

    const { payment_terms, limit_request, account_details, ...rest } = company;

    setSelectedCompany(rest);

    Object.entries(rest).forEach(([key, value]) => {
      setValue(key as keyof QualityAssuranceType, value);
    });
  }, [watchSelectedCompany, partnersData, setValue]);

  function handleSupplierChange(checked: boolean): void {
    setIsChecked(checked);
  }

  // console.log('qualityAssuranceData',qualityAssuranceData?.quality_assurance_data)

  return (
    <section className="bg-white rounded-md">
      <div className="flex flex-row justify-between items-center">
        <h1 className=" text-2xl font-bold">Quality Assurance</h1>
        <div className="flex justify-end my-4">
          <button
            type="button"
            className="h-[44px] px-2 text-custom-blue-medium font-semibold border border-custom-blue-medium rounded-lg"
            onClick={() => openModal("add")}
          >
            Add New Company
          </button>
        </div>
      </div>

      <div>
        <div>
          {selectedCompany === null && (
            <div
              style={{
                backgroundColor: "rgba(,.5)",
                backdropFilter: `blur(4px)`,
                borderRadius: "10px",
                position: "absolute",
                top: 60,
                left: 0,
                bottom: 0,
                right: 0,
                zIndex: 3,
              }}
            >
              <p className="text-center pt-20 text-red-500">
                Please Add or Edit New Company
              </p>
            </div>
          )}

          <div className="flex gap-6">
            <Dropdown
              label="Company Name"
              placeholder="Select a Supplier"
              options={
                partnersData?.map((el) => ({
                  label: el.company_name,
                  value: el.company_name,
                })) || []
              }
              id="beneficiaryCompanyName"
              error={errors?.company_name?.message}
              {...register("company_name")}
            />
            <div className="pt-5">
              <button
                type="button"
                className="h-[43px] w-[100px] px-2 text-custom-blue-medium font-semibold border border-custom-blue-medium rounded-lg disabled:border-gray-600 disabled:text-gray-600 disabled:cursor-not-allowed"
                onClick={() => openModal("update")}
                disabled={!selectedCompany}
              >
                Edit
              </button>
            </div>
            <InputWithLabel
              label={"Company Regsitration Number"}
              element={<Tooltip content={company_reg_number} />}
              {...register("company_registration_number")}
              // error={errors?.company_registration_number?.message}
              // defaultValue={selectedCompany?.company_registration_number}
              disabled
            />
          </div>
          <div className="flex gap-6">
            <InputWithLabel
              element={<Tooltip content={company_address} />}
              label={"Address Of Supplier"}
              {...register("company_address")}
              // error={errors?.company_address?.message}
              // defaultValue={selectedCompany?.company_address}
              disabled
            />
            <InputWithLabel
              // element={<Tooltip content={} />}

              label={"Address Of Inspection"}
              {...register("company_address")}
              // error={errors?.company_registration_number?.message}
              // defaultValue={selectedCompany?.company_registration_number}
              disabled
            />
          </div>
          <div className="flex gap-6 pb-4">
            <InputWithLabel
              // element={<Tooltip content={company} />}

              label={"Company Contact Person Name"}
              {...register("contact_person_name")}
              // error={errors?.contact_person_name?.message}
              // defaultValue={selectedCompany?.contact_person_name}
              disabled
            />
            <InputWithLabel
              label={"Company Email"}
              {...register("email")}
              // error={errors?.email?.message}
              // defaultValue={selectedCompany?.email}
              disabled
            />
            <InputWithLabel
              label={"Company Phone Number"}
              {...register("phone_number")}
              // error={errors?.phone_number?.message}
              // defaultValue={selectedCompany?.phone_number}
              disabled
            />
          </div>
          <hr className="border-t-[#D0D5DD] pb-5" />

          <QACheckedFields />
        </div>
      </div>
    </section>
  );
};

export default QualityAssurance;

function QACheckedFields() {
  const { jwtToken } = useAppContext();

  const {
    register,
    formState: { errors },
    setValue,
    unregister,
    control,
    watch,
    clearErrors,
    getValues,
  } = useFormContext<QualityAssuranceType>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "quality_specifications_file",
  });

  const handleChangeUploadFile = async (
    e: React.FormEvent<HTMLInputElement>,
    index: number
  ) => {
    const file = e.currentTarget.files?.[0];
    if (file) {
      try {
        const base64String = await fileToBase64(file);
        const res = await fetch(common_file_upload, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            base64File: base64String,
            folder_name: "quality_assurance",
          }),
        });
        const data = await res.json();

        if (data.status === "success") {
          clearErrors(`quality_specifications_file.${index}.file`);
          setValue(
            `quality_specifications_file.${index}.url`,
            data.url as string
          );
          clearErrors(`quality_specifications_file.${index}.url`);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  const isSupplierAuditChecked = watch("supplier_audit", false);
  const isQualityAssuranceChecked = watch("quality_assurance", false);
  const isLoadingInspectionChecked = watch("loading_inspection", false);

  useEffect(() => {
    console.log(isSupplierAuditChecked);
    if (!isSupplierAuditChecked) {
      console.log(isSupplierAuditChecked);

      clearErrors(["supplier_audit", "audit_specification"]);
      // unregister(["audit_specification"]);
    }
    // else {
    //   register("audit_specification");
    // }
  }, [isSupplierAuditChecked, unregister, register, clearErrors]);

  useEffect(() => {
    if (!isQualityAssuranceChecked) {
      clearErrors("quality_assurance");
      unregister("quality_assurance");
    } else {
      register("quality_assurance");
    }
  }, [isQualityAssuranceChecked, unregister, register, clearErrors]);

  useEffect(() => {
    if (!isLoadingInspectionChecked) {
      clearErrors("loading_inspection");
      unregister("loading_inspection");
    } else {
      register("loading_inspection");
    }
  }, [isLoadingInspectionChecked, unregister, register, clearErrors]);

  return (
    <div className="flex gap-3">
      <div className="w-full space-y-2">
        <div className="flex justify-between">
          <div className="inline-flex items-center gap-2 mb-5">
            <input
              type="checkbox"
              id="supplier_audit"
              {...register("supplier_audit")}
              className="w-6 h-6"
            />
            <label htmlFor="supplier_audit" className="font-semibold text-xl">
              Supplier Audit
            </label>
          </div>
          <Tooltip content={supplier_audit_inspection} />
        </div>

        <InputWithLabel
          // disabled={!isSupplierAuditChecked}
          label={"Audit Specifications"}
          {...register("audit_specification", {
            disabled: !isSupplierAuditChecked,
          })}
          placeholder="Add required Specifications"
          error={
            // isSupplierAuditChecked ?
            errors?.audit_specification?.message
            //  : ""
          }
        />
      </div>

      <div className="w-full space-y-2 border-l-[#D0D5DD] border-l-2 border-r-2 border-r-[#D0D5DD] px-3">
        <div className="flex justify-between">
          <div className="inline-flex items-center gap-2 mb-5">
            <input
              type="checkbox"
              id="quality_assurance"
              {...register("quality_assurance")}
              className="w-6 h-6"
            />
            <label
              htmlFor="quality_assurance"
              className="font-semibold text-xl"
            >
              Quality Assurance
            </label>
          </div>
          <Tooltip content={quality_assurance_inspection} />
        </div>

        <div className="space-y-2 ">
          {fields.map((field, index) => (
            <div key={field.id}>
              <div className="flex items-center gap-1 w-full">
                <InputWithLabel
                  // disabled={!isQualityAssuranceChecked}
                  type="file"
                  accept=".jpg,.pdf,.docx,.png,.jpeg"
                  {...register(`quality_specifications_file.${index}.file`, {
                    disabled: !isQualityAssuranceChecked,
                  })}
                  onChange={(e) => {
                    handleChangeUploadFile(e, index);

                    toast.promise(handleChangeUploadFile(e, index), {
                      pending: "File is uploading",
                      success: "File has been uploaded 👌",
                      error: "Rejected, please try again 🤯",
                    });
                  }}
                  label={`Quality Specifications - ${index + 1}`}
                  error={errors?.quality_specifications_file?.[index]?.message}
                />

                {index === 0 && (
                  <IconButton
                    height="30px"
                    width="35px"
                    buttonWidth="50px"
                    buttonHeight="50px"
                    onClick={() => append({ url: "", file: "" })}
                    iconSource={addIcon}
                    disabled={!isQualityAssuranceChecked}
                  />
                )}

                {index !== 0 && (
                  <button onClick={() => remove(index)}>
                    <CrossIcon className="size-8 text-red-500" />
                  </button>
                )}
              </div>
              {getValues().quality_specifications_file?.[index]?.url &&
                getValues().quality_specifications_file?.[
                  index
                ]?.url.startsWith("https:") && (
                  <a
                    className="flex float-right -mt-2 text-custom-blue hover:underline"
                    target="_blank"
                    rel="noopener noreferrer" // Important for security
                    href={getValues().quality_specifications_file?.[index]?.url}
                  >
                    View File
                  </a>
                )}
            </div>
          ))}

          <InputWithLabel
            {...register("qa_consignment_value", {
              disabled: !isQualityAssuranceChecked,
            })}
            label={"Consignment Value"}
            disabled={!isQualityAssuranceChecked}
            error={errors.qa_consignment_value?.message}
          />
        </div>
      </div>

      <div className="w-full space-y-2">
        <div className="flex justify-between">
          <div className="inline-flex items-center gap-2 mb-5">
            <input
              type="checkbox"
              id="loading_inspection"
              {...register("loading_inspection")}
              className="w-6 h-6"
            />
            <label
              htmlFor="loading_inspection"
              className="font-semibold text-xl"
            >
              Loading Inspection
            </label>
          </div>
          <Tooltip content={loading_inspection} />
        </div>

        <div className="space-y-2">
          <InputWithLabel
            // disabled={!isLoadingInspectionChecked}
            {...register("no_of_containers", {
              disabled: !isLoadingInspectionChecked,
            })}
            error={errors?.no_of_containers?.message}
            label={"No. Of Containers"}
          />
          <InputWithLabel
            // disabled={!isLoadingInspectionChecked}
            {...register("loading_consignment_value", {
              disabled: !isLoadingInspectionChecked,
            })}
            error={errors?.loading_consignment_value?.message}
            label={"Consignment Value"}
          />
          <InputWithLabel
            // disabled={!isLoadingInspectionChecked}
            {...register("goods_specifications", {
              disabled: !isLoadingInspectionChecked,
            })}
            error={errors?.goods_specifications?.message}
            label={"Goods Specifications"}
          />
        </div>
      </div>
    </div>
  );
}
