import { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { SearchIcon } from "../Icons";
import { useAppContext } from "../../context/AppContext";
import { useFetch } from "../../hooks/useFetch";
import { get_vendors } from "../../constants/apis";
import { VendorsRes } from "../../pages/Admin/VendorPage";
import CardSkeleton from "../CardSkeleton";
import { debounce } from "../../utils";

export default function VendorProfiles() {
  const [search, setSearch] = useState("");
  const { adminJWTToken } = useAppContext();

  const { data, loading } = useFetch<VendorsRes>(
    `${get_vendors}?limit=4&search=${search}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${adminJWTToken}`,
        "content-type": "application/json",
      },
    }
  );

  const vendorsData = data?.vendor_data;

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const getDebouncedSearch = useCallback(debounce(handleOnChange, 500), []);

  return (
    <section className="card">
      <div className="flex justify-between items-center mb-3 pb-3 border-b border-gray-200">
        <h2 className="text-[#030303] font-semibold text-xl">
          Vendor Profiles
        </h2>
        <Link className="text-custom-blue font-semibold" to={"/admin/vendors"}>
          View All
        </Link>
      </div>

      <div className="space-y-2">
        <div className="relative">
          <SearchIcon className="size-6 absolute top-[10px] left-2 text-gray-500" />
          <input
            type="search"
            placeholder="Search"
            className="w-full p-[10px] pl-10 border border-gray-300 rounded-lg bg-[#fcfcfd] text-[0.9rem] text-gray-900 transition duration-300 focus:border-blue-500 focus:ring focus:ring-blue-200 outline-none"
            onChange={getDebouncedSearch}
          />
        </div>

        <div className="space-y-2">
          {loading
            ? [1, 2, 3].map(() => <CardSkeleton />)
            : vendorsData?.map((el) => (
                <div
                  key={el.vendor_id}
                  className="flex items-center gap-2 py-2"
                >
                  <div>
                    <div className="w-[48px] h-[48px] bg-gray-400 rounded-full flex justify-center items-center">
                      {el.company_name.at(0)?.toUpperCase()}
                    </div>
                  </div>

                  <div>
                    <p className="text-sm font-semibold leading-2">
                      {el.company_name}
                    </p>
                    <small className="text-gray-400">
                      {el.services_offered}
                    </small>
                  </div>
                </div>
              ))}

          {!loading && !data && (
            <p className="text-center text-gray-600">No record found</p>
          )}
        </div>
      </div>
    </section>
  );
}
