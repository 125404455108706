import { Link, useNavigate } from "react-router-dom";
import { useSimplePaymentDataContext } from "../../context/SimplePaymentDataContext";
import { formattedDate } from "../../utils";
import CardSkeleton from "../CardSkeleton";

export default function Drafts() {
  const navigate = useNavigate();
  const { data, loading } = useSimplePaymentDataContext();

  const drafts = data?.filter((el) => el.simple_payment.save_as_draft === 1);

  return (
    <section className="card col-span-1" id="payment-draft">
      <div className="flex items-center justify-between mb-3">
        <h2 className="text-[#030303] font-semibold text-xl">Drafts</h2>

        <div>
          <Link to={""} className="text-custom-blue-new font-semibold">
            View all
          </Link>
        </div>
      </div>

      <div>
        {loading ? (
          <div className="">
            {[1, 2, 3].map((el) => (
              <CardSkeleton key={el} />
            ))}
          </div>
        ) : (
          <div className="h-[300px] overflow-y-scroll custom-scrollbar">
            {data !== null && drafts && drafts?.length > 0 ? (
              drafts?.map(({ simple_payment, beneficiary_details }, index) => (
                <div
                  key={simple_payment.simple_payment_id}
                  className={`flex items-center justify-between py-3 px-1 cursor-pointer hover:bg-blue-50 hover:rounded-lg ${
                    index + 1 !== data.length && "border-b"
                  }`}
                  onClick={() =>
                    navigate(
                      `/simple-payment?simple_payment_id=${simple_payment.simple_payment_id}` // this needs to be partners id TODO
                    )
                  }
                >
                  <div>
                    <div className="flex items-center gap-3">
                      <div className="w-10 h-10 bg-slate-300 rounded-full flex justify-center items-center font-medium">
                        {/* <img src={el.logo} alt="logo" /> */}
                        {beneficiary_details?.company_name.at(0)?.toUpperCase()}
                      </div>

                      <div>
                        <p className="text-sm font-medium">
                          {beneficiary_details?.company_name}{" "}
                        </p>
                        <small className="text-gray-400">
                          {beneficiary_details?.company_registration_number}
                        </small>
                      </div>
                    </div>
                  </div>

                  <div>
                    <small className="text-gray-400 flex flex-col items-center gap-1">
                      <span className="bg-orange-100 px-1 rounded-lg border border-orange-300 text-xs">
                        Saved as draft
                      </span>

                      <span>{formattedDate(simple_payment?.created_at)}</span>
                    </small>
                  </div>
                </div>
              ))
            ) : (
              <p className="text-center text-gray-500">No records found</p>
            )}
          </div>
        )}
      </div>
    </section>
  );
}
