import { Link } from "react-router-dom";
import { tasks } from "../Dashboard/Task";

export default function Tasks() {
  return (
    <section className="card col-span-full">
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-[#030303] font-semibold text-xl">Tasks</h2>

        <div>
          <Link
            to={"/admin/tasks"}
            className="text-custom-blue-new font-semibold"
          >
            View all
          </Link>
        </div>
      </div>

      <div className="space-y-5">
        {tasks.map((task) => (
          <div key={task.id} className="flex items-center gap-3">
            <div>
              <div className="w-[48px] h-[48px] bg-gray-400 rounded-full"></div>
            </div>

            <div>
              <p className="text-sm font-semibold leading-2">
                {task.name}{" "}
                <span className="font-normal text-xs text-gray-400">
                  {task.time}
                </span>
              </p>
              <small className="text-gray-400">{task.message}</small>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}
