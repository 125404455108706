import { useState, DragEvent } from "react";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { fileToBase64 } from "../utils";
import { whatsapp_file_upload } from "../constants/apis";

export default function UploadPage() {
  const [file, setFile] = useState<File | null>(null);
  const [uploading, setUploading] = useState(false);
  const [message, setMessage] = useState<string | null>(null);

  const [searchParams] = useSearchParams();

  const token = searchParams.get("token");

  // Handle file selection
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setFile(event.target.files[0]);
      setMessage(null);
    }
  };

  // Handle Drag & Drop
  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (event.dataTransfer.files.length > 0) {
      setFile(event.dataTransfer.files[0]);
      setMessage(null);
    }
  };

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  // Handle file upload
  const handleUpload = async () => {
    if (!file) {
      setMessage("Please select a file.");
      return;
    }

    setUploading(true);
    const formData = new FormData();
    formData.append("file", file);

    try {
      const base64String = await fileToBase64(file);

      const res = await fetch(whatsapp_file_upload, {
        method: "POST",
        headers: {
          Content_Type: "application/json",
        },
        body: JSON.stringify({
          base64File: base64String,
          token,
        }),
      });

      const data = await res.json();

      if (data.status === "success") {
        toast.success(`File "${file.name}" uploaded successfully!`);

        setFile(null); // Clear file input after upload
      } else {
        toast.error("File upload went wrong please try again");
      }
    } catch (error) {
      toast.error("Upload failed. Please try again.");
    } finally {
      setUploading(false);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white shadow-lg rounded-lg p-6 w-96">
        <h2 className="text-xl font-semibold text-center mb-4">Upload File</h2>

        {/* Drag & Drop Box */}
        <div
          className="border-2 border-dashed border-gray-400 p-6 text-center rounded-md cursor-pointer bg-gray-50 hover:bg-gray-100"
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          onClick={() => document.getElementById("fileInput")?.click()}
        >
          {file ? (
            <p className="text-green-600">{file.name}</p>
          ) : (
            <p className="text-gray-600">
              Drag & Drop a file here or click to browse
            </p>
          )}
        </div>

        {/* Hidden File Input */}
        <input
          type="file"
          id="fileInput"
          className="hidden"
          onChange={handleFileChange}
        />

        {/* Message Display */}
        {message && (
          <small className="mt-4 block text-center text-red-400">
            {message}
          </small>
        )}

        {/* Upload Button */}
        <button
          onClick={handleUpload}
          className="w-full bg-blue-500 text-white py-2 mt-4 rounded-md hover:bg-blue-600 transition disabled:opacity-50"
          disabled={uploading}
        >
          {uploading ? "Uploading..." : "Upload"}
        </button>
      </div>
    </div>
  );
}
