import { HTMLProps, ReactNode } from "react";

interface Props extends HTMLProps<HTMLInputElement> {
  icon?: ReactNode;
}

const IconTextInput = ({
  width,
  height,
  icon,
  placeholder,
  className,
  ...rest
}: Props) => {
  return (
    <div className="relative w-full">
      {icon && <div className="absolute left-2 top-[12px]">{icon}</div>}
      <input
        className={`${icon ? "pl-10" : ""} ${className}`}
        placeholder={placeholder}
        {...rest}
      />
    </div>
  );
};
export default IconTextInput;
