import { useState } from "react";

const replyContent = [
  {
    content:
      "Based on your input, we recommend our Express Air Shipping option, which will deliver your package in 2-3 business days at an estimated cost of $25.00. Would you like to proceed with this booking?",
    user_select: "Book a Shipment.",
    id: 1,
  },
  {
    content:
      "For a package weighing 5 kg (11 lbs) with dimensions 30x20x10 cm, shipping from Los Angeles, USA to Berlin, Germany, our Express Air Shipping option costs $45.00 and will arrive in 3-4 business days. Would you like to proceed with this option?",
    user_select: "Get a Shipping Quote",
    id: 2,
  },
  {
    content:
      "Please enter your tracking number to get real-time updates on your shipment. You can find your tracking number in your confirmation email or shipping receipt. If your package is delayed, we will also provide the latest expected delivery time and reasons for any delays.",
    user_select: "Track My Shipment.",
    id: 3,
  },
];

export default function AIChat() {
  const [isOpen, setIsOpen] = useState(false);
  const [content, setContent] = useState<string | null>(null);
  const toggleChatBox = () => {
    setIsOpen(!isOpen);
  };

  function sendResponse(id: number) {
    const content = replyContent.find((obj) => obj.id === id);
    const response = content
      ? content.content
      : "Our executive will constact you with few minutes. ";
    setContent(response);
  }

  return (
    <>
      <div
        onClick={toggleChatBox}
        className="bg-custom-dark-blue shadow-2xl fixed cursor-pointer rounded-full bottom-10 right-10 p-2"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={45}
          height={45}
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            stroke="#ffffff"
            strokeLinecap="round"
            strokeWidth={1.5}
            d="M16 12c0-.845-.927-1.414-2.78-2.553C11.34 8.292 10.4 7.715 9.7 8.139 9 8.563 9 9.709 9 12c0 2.291 0 3.437.7 3.861.701.424 1.64-.153 3.52-1.308C15.072 13.414 16 12.844 16 12Z"
          />
          <path
            stroke="#ffffff"
            strokeLinecap="round"
            strokeWidth={1.5}
            d="M17 3.338A9.954 9.954 0 0 0 12 2C6.477 2 2 6.477 2 12c0 1.6.376 3.112 1.043 4.453.178.356.237.763.134 1.148l-.595 2.226a1.3 1.3 0 0 0 1.591 1.591l2.226-.595a1.634 1.634 0 0 1 1.149.133A9.958 9.958 0 0 0 12 22c5.523 0 10-4.477 10-10 0-1.821-.487-3.53-1.338-5"
          />
        </svg>
      </div>
      {isOpen && (
        <div className="shadow-lg transition-all transition-discrete bg-white fixed w-[400px] h-[500px] rounded-md bottom-28 right-10">
          <div className="bg-custom-dark-blue rounded-t-md px-4 py-4 flex justify-between">
            <div className="flex flex-col">
              <span className="uppercase text-white text-sm">Taram</span>
              <span className=" text-white text-xl">AI Assitant</span>
            </div>

            <button
              onClick={() => setIsOpen(!isOpen)}
              className="text-custom-blue-new bg-white h-[20px] w-[20px] flex pl-1 rounded-full items-center cursor-pointer text-lg"
            >
              &times;
            </button>
          </div>
          <div className=" w-[96%]  rounded-lg h-[67%] overflow-y-scroll text-sm space-y-2  m-2 py-2">
            <p className="bg-slate-300 w-[270px] px-4 py-2 rounded">
              Hello! I am Asin? I am here to assist you. <br /> Select relevant
              questions from below.
            </p>
            {replyContent.map((reply: any, index: number) => (
              <div
                key={reply.id || index}
                onClick={() => sendResponse(reply.id)}
              >
                <p className="bg-slate-300  transition delay-150 duration-300 ease-in-out hover:translate-y-1 hover:bg-blue-300 hover:text-white cursor-pointer w-fit px-4 py-2 rounded">
                  {reply.user_select}
                </p>
              </div>
            ))}
            {content && (
              <div className="flex justify-end pr-4">
                <p className="  bg-custom-blue text-white w-[270px] px-4 py-2 rounded">
                  {content}
                </p>
              </div>
            )}
          </div>
          <div className="flex justify-between mt-5 border-t p-2">
            <input
              type="text"
              className="bg-slate-200 w-[270px] rounded-full pl-4 text-sm"
              placeholder="Type a message..."
            />
            <button className="p-2 text-white shadow-2xl rounded-full px-4 bg-custom-blue-new">
              Send
            </button>
          </div>
        </div>
      )}
    </>
  );
}
