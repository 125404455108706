import { VerticalDivider } from "../components/VerticalDivider";

import mapIcon from "../assets/icons/map.png";
import mapTrack from "../assets/icons/mapTrack.png";
import {
  TrackingSteps,
  VerticalDividerWithCircle,
} from "../components/TrackingSteps";

import leftArrow from "../assets/icons/arrow-left.png";
import { useEffect, useState } from "react";
import { AltertBox } from "../components/AltertBox";
import checkIcon from "../assets/icons/check.png";
import { useLocation, useNavigate } from "react-router-dom";
import { getItemFromLocalStore } from "../utils";
import logisticNavigationImg from "../assets/img/logistic-navigation.jpg";
import { MapPinIcon } from "../components/Icons";

export type RouteDetails = {
  originalPrice: number;
  originalCurrency: string;
  price: number;
  comment: null;
  distance: string;
  transitTime: string;
  interpolation: boolean;
  co2: number;
};

export type Quote = {
  shipment_id: string;
  from_city: string;
  to_city: string;
  name: string;
  logo: string;
  co2: number;
  cost: number;
  days: string;
  valid: string;
  route_info: {
    truckFrom: RouteDetails;
    truckTo: RouteDetails;
    railFrom: RouteDetails;
    railTo: RouteDetails;
    portFrom: {
      id: number;
      name: string;
      code: string;
      countryCode: string;
      lat: number;
      lng: number;
    };
    portTo: {
      id: number;
      name: string;
      code: string;
      countryCode: string;
      lat: number;
      lng: number;
    };
  };
};

const getNumberFromString = (str: string) => parseInt(str?.split(" ").at(0)!);

export const LSummary = () => {
  const [quote] = useState<Quote>(getItemFromLocalStore("quote-details"));

  const [popup, setPopup] = useState(false);
  const navigate = useNavigate();

  function handleSubmit() {
    setPopup(!popup);
  }
  return (
    <div className="p-5">
      <div>
        <div className="flex items-center gap-1">
          <img src={quote?.logo} alt={quote?.name} className="w-16" />
          <p className="">{quote?.name}</p>
        </div>

        <div className="my-5 flex justify-between items-center">
          <div className="space-y-2 text-gray-700">
            <h3 className="font-medium text-sm flex items-center gap-1">
              <MapPinIcon className="size-4" />
              <span>FROM:</span>
              <span>{quote?.from_city}</span>
            </h3>
            <h3 className="font-medium text-sm flex items-center gap-1">
              <MapPinIcon className="size-4" />
              <span>TO:</span>
              <span>{quote?.to_city}</span>
            </h3>
          </div>

          <div className="flex items-center gap-5">
            <small className="bg-blue-50 py-1 px-2 rounded-2xl border border-custom-semi-light-blue">
              ETA: {quote?.days}
            </small>
            <small className="bg-blue-50 py-1 px-2 rounded-2xl border border-custom-semi-light-blue">
              PRICE: ${quote?.cost}
            </small>
          </div>
        </div>
      </div>

      <hr className="border-t-1 text-gray-500 mb-4" />

      <div className="flex gap-2">
        <div className="w-full">
          <h2 className="text-lg font-medium text-custom-blue-new">
            RATE BREAKDOWN
          </h2>

          <div className="space-y-2">
            <p>{quote?.from_city}</p>
            <div className="flex gap-4 items-center">
              <VerticalDividerWithCircle />
              <TrackingSteps
                location={`${quote?.route_info?.portFrom.name}, ${quote?.route_info?.portFrom.countryCode}`}
                truck={quote?.route_info.truckFrom}
                rail={quote?.route_info.railFrom}
              />
            </div>
          </div>

          <div className="flex gap-4 mt-2 items-center">
            <VerticalDividerWithCircle />

            <div className="w-[300px] h-fit p-2 border rounded-md">
              <div className="flex justify-between text-sm">
                <p className="text-custom-blue">Sea FCL</p>
                <p>
                  {getNumberFromString(quote?.days) -
                    (getNumberFromString(
                      quote?.route_info.truckFrom.transitTime
                    ) +
                      getNumberFromString(
                        quote?.route_info.truckTo.transitTime
                      ))}{" "}
                  days
                </p>
              </div>
              <div className="flex justify-between text-sm">
                <p className="text-custom-blue">RF Road freight</p>
                <p>
                  USD{" "}
                  {quote?.cost -
                    (quote?.route_info.truckFrom.price +
                      quote?.route_info.truckTo.price)}
                </p>
              </div>
            </div>
          </div>

          <div className="flex gap-4 items-end">
            <VerticalDividerWithCircle />

            <TrackingSteps
              location={`${quote?.route_info?.portTo.name}, ${quote?.route_info?.portTo.countryCode}`}
              truck={quote?.route_info.truckTo}
              rail={quote?.route_info.railTo}
            />
          </div>

          <div>
            <div className="h-5 w-5 border-[6px] border-gray-400 rounded-full" />

            <p>{quote?.to_city}</p>
          </div>
        </div>

        <div className="w-full">
          <img src={logisticNavigationImg} alt="navigation" />
        </div>
      </div>

      <div>
        <div className="mt-10 pt-4 flex justify-between border-t">
          <button
            type="button"
            className="w-[73px] h-[44px] px-2 text-[#344054] font-medium border border-[#D0D5DD] rounded-lg"
            onClick={() => navigate(-1)}
          >
            <span>Back</span>
          </button>

          <button
            className="w-[100px] h-[44px] rounded-md bg-[#0348B7] text-white font-medium"
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>

        {popup && (
          <AltertBox
            iconSource={checkIcon}
            alertText="Successfully Submitted"
            alertText1="You will recieve confirmation from the shipper in 2 business days and task to make payment after confirmation"
            alertText2=""
            buttonTitle="Go to next"
            buttonWidth="100%"
            buttonHeight="56px"
            handleClick={() => navigate("/quality-assurance")}
          />
        )}
      </div>
    </div>
  );
};
