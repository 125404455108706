const ChinaFlag = () => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <rect width={24} height={24} fill="url(#pattern0_5688_7595)" />
    <defs>
      <pattern
        id="pattern0_5688_7595"
        patternContentUnits="objectBoundingBox"
        width={1}
        height={1}
      >
        <use xlinkHref="#image0_5688_7595" transform="scale(0.0208333)" />
      </pattern>
      <image
        id="image0_5688_7595"
        width={48}
        height={48}
        preserveAspectRatio="none"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAABPklEQVR4nO2Vv0oDQRDGRyGFpAme2tmZShCb4D+WiFj6AoL4BFoIHmmSh/AlLPQVLO18AUGwSiH4ED8ZhpAr1Fxxd7sX5oOPHWZhd775syvicDgcDscygCC0ieICQiEjo40WV2CyBdNDyLOEBZyvGn/bu+5aBS7XEhaQZ41nmEoFPPfhqV/+ggbESmkBpyvweWBUe9Hh402bCV2jCcgzeNk1vu7B94lR7Zn/ryxfdS14XaNW4KYH74N58DN+DOBuvfYWoZIWuujA1/E8eLXVFzl4Sgu47Vng0yOj2upbdMF9KkP8uANv+/bGK9VWX/JDHASGAg/bcFb4wNRW3/Cfw5MZ4pA2pVIBEX5pqUxAQz1PbQIa6nlqbaEIFBcQvAJ4CxURuyXwIQ7xs4o/oyF+ZvGPLLSD4nA4HA6HLAF+AE6Ipi2QSwGFAAAAAElFTkSuQmCC"
      />
    </defs>
  </svg>
);
export default ChinaFlag;
