export default function TourIcon({ className }: { className: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      className={className}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M4.5 3.75v16.5m0-16.5l4.5 3.75L13.5 3.75l4.5 3.75L22.5 3.75v16.5M4.5 20.25l4.5-3.75 4.5 3.75 4.5-3.75 4.5 3.75"
      />
    </svg>
  );
}
