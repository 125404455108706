export default function GreetAdmin() {
  return (
    <section className="my-4 col-span-full">
      <h1 className="font-medium text-2xl text-gray-800">
        Welcome to Admin Dashboard 👋
      </h1>
      <p className="text-gray-400">Here is Your Latest Updates</p>
    </section>
  );
}
