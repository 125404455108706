import { createContext, useContext } from "react";

import { useFetch } from "../hooks/useFetch";
import { get_all_beneficiary_details } from "../constants/apis";
import { useAppContext } from "./AppContext";

export type Company = {
  beneficiary_id: number;
  company_name: string;
  company_address: string;
  company_registration_number: string;
  contact_person_name: string;
  email: string;
  phone_number: string;
  limit_request?: number | string;
  payment_terms?: string;
  account_details: CompanyAccountDetails[];
};

export type CompanyAccountDetails = {
  beneficiary_account_id: number;
  beneficiary_id: number;
  account_ref_name: string;
  account_holder_name: string;
  bank: string;
  bank_address: string;
  account_no: string;
  swift_ifsc: string;
  currency: string;
  beneficiaryAccountType: string;
  bank_city: string;
  beneficiaryCountryCode: string;
  payoutMethod: string;
  beneficiaryHashId: string;
};

type PartnersCompany = Company[];

type PartnersData = {
  data: PartnersCompany | null;
  loading: boolean;
  refetch: () => void;
};

const PartnersDatContext = createContext<PartnersData | null>(null);

export const PartnersDatContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { jwtToken } = useAppContext();

  const { data, refetch, loading } = useFetch<PartnersCompany>(
    jwtToken ? get_all_beneficiary_details : null,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        "Content-Type": "application/json",
      },
    }
  );

  return (
    <PartnersDatContext.Provider value={{ data, loading, refetch }}>
      {children}
    </PartnersDatContext.Provider>
  );
};

export const usePartnersDataContext = () => {
  const context = useContext(PartnersDatContext);

  if (!context)
    throw new Error(
      "usePartnersDataContext must be used within an PartnersDatContext provider"
    );

  return context;
};
