import { Outlet } from "react-router-dom";

import UserDropdown from "../components/UserDropdown";
import SideNavbar from "../components/VTabBar/SideNavbar";

import {
  SimplePaymentIcon,
  SmartEscrowIcon,
  DashboardIcon,
  IFIcon,
  LogisticsIcon,
  QualityAssuranceIcon,
} from "../components/Icons";

import {
  invoiceContent,
  logisiticsContent,
  paymentContent,
  qualityContent,
} from "../constants/tooltipContents";

const routes = [
  {
    id: 1,
    route: "/dashboard",
    name: "Dashboard",
    content: paymentContent,
    icon: <DashboardIcon className="size-6" />,
  },
  {
    id: 2,
    route: "/simple-payment",
    name: "Payment",
    content: paymentContent,
    icon: <SimplePaymentIcon className="size-7" />,
  },
  {
    id: 3,
    route: "/invoice-factoring",
    name: "Invoice Factoring",
    content: invoiceContent,
    icon: <IFIcon className="size-7" />,
  },
  // {
  //   id: 4,
  //   route: "/smart-escrow",
  //   name: "Smart Escrow",
  //   icon: <SmartEscrowIcon className="size-7" />,
  //   disabled: true,
  // },

  {
    id: 5,
    route: "/logistics",
    name: "Logistics",
    content: logisiticsContent,
    icon: <LogisticsIcon className="size-7" />,
  },
  {
    id: 6,
    route: "/quality-assurance",
    name: "Quality Assurance",
    content: qualityContent,
    icon: <QualityAssuranceIcon className="size-7" />,
  },

  // {
  //   id: 7,
  //   route: "/insurance",
  //   name: "Insurance",
  //   icon: <InsuranceIcon className="size-7" />,
  // },
  {
    id: 4,
    route: "/smart-escrow",
    name: "Smart Escrow",
    icon: <SmartEscrowIcon className="size-7" />,
    disabled: true,
    content: "Coming soon",
  },
  // {
  //   id: 8,
  //   route: "/transaction-summary",
  //   name: "Summary",
  //   icon: <ChartBreakoutCircle className="size-7" />,
  // },
];

export type RoutesType = typeof routes;
export type LinkProps = RoutesType[number];

const VTabBarlayout = () => {
  return (
    <main className="flex">
      <SideNavbar routes={routes} />

      <section className="flex-1 ml-[264px] min-h-screen pb-10 bg-[#EAECF0]">
        <nav className="max-w-[1128px] mx-auto flex justify-end py-4 items-center">
          <UserDropdown />
        </nav>
        <section className="max-w-[1128px] mx-auto">
          <Outlet />
        </section>
      </section>
    </main>
  );
};

export default VTabBarlayout;
