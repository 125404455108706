import React from "react";
import { useParams, Link } from "react-router-dom";
import { useFetch } from "../../hooks/useFetch";
import { TradingInfoType } from "../../schemas/TradeInfoFormSchema";
import { get_buyer_trading_info } from "../../constants/apis";
import { useAppContext } from "../../context/AppContext";

type TradingInfoResType = TradingInfoType & {
  trading_id: number;
  created_at: string;
};

const BuyerDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { adminJWTToken } = useAppContext();

  const { data, loading } = useFetch<TradingInfoResType>(
    adminJWTToken ? get_buyer_trading_info : null,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${adminJWTToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        trading_id: id,
      }),
    },
    id,
    "BUYER_DETAILS"
  );

  if (loading) return <p className="text-center">loading...</p>;

  if (!data) {
    return <div className="p-6 text-red-500 text-center">Buyer not found!</div>;
  }

  return (
    <>
      <div className="pb-4 max-w-3xl mx-auto">
        <Link className="underline" to={"/admin/dashboard"}>
          &#x2190; Back
        </Link>
      </div>

      <div className="p-6 max-w-3xl mx-auto bg-white shadow-lg rounded-lg">
        {/* Created Date */}
        <div className="flex justify-between items-center">
          <h2 className="text-3xl font-semibold text-gray-800 mb-4 text-center">
            Buyer Details
          </h2>
          <p className="text-gray-500">
            <strong>Created At:</strong> {data.created_at}
          </p>
        </div>
        <hr className="mb-4" />

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Buyer Information */}
          <div>
            <h3 className="text-xl font-semibold text-gray-700 mb-3">
              Buyer Information
            </h3>
            <p>
              <strong>Name:</strong> {data.buyer_name}
            </p>
            <p>
              <strong>Address:</strong> {data.buyer_address}
            </p>
            <p>
              <strong>Registration No:</strong> {data.buyer_reg_no}
            </p>
          </div>

          {/* Supplier Information */}
          <div>
            <h3 className="text-xl font-semibold text-gray-700 mb-3">
              Supplier Information
            </h3>
            <p>
              <strong>Name:</strong> {data.supplier_name}
            </p>
            <p>
              <strong>Address:</strong> {data.supplier_address}
            </p>
            <p>
              <strong>Registration No:</strong> {data.supplier_reg_no}
            </p>
          </div>

          {/* Trading History */}
          <div>
            <h3 className="text-xl font-semibold text-gray-700 mb-3">
              Trading History
            </h3>
            <p>
              <strong>General History:</strong> {data.trading_history}
            </p>
            <p>
              <strong>With Buyer:</strong> {data.buyer_trading_history}
            </p>
            <p>
              <strong>With Supplier:</strong> {data.supplier_trading_history}
            </p>
          </div>

          {/* Financial Information */}
          <div>
            <h3 className="text-xl font-semibold text-gray-700 mb-3">
              Financial Information
            </h3>
            <p>
              <strong>Turnover (Previous Year):</strong> $
              {data.turnover_previous_year}
            </p>
            <p>
              <strong>Expected Turnover (Current Year):</strong> $
              {data.turnover_current_year}
            </p>
            <p>
              <strong>Credit Limit:</strong> ${data.limit_amount}
            </p>
          </div>

          {/* Trading Details */}
          <div>
            <h3 className="text-xl font-semibold text-gray-700 mb-3">
              Trading Details
            </h3>
            <p>
              <strong>Goods:</strong> {data.underlying_goods}
            </p>
            <p>
              <strong>Transaction Tenor:</strong> {data.transaction_tenor}
            </p>
            <p>
              <strong>Currency:</strong> {data.currency}
            </p>
            <p>
              <strong>Incoterms:</strong> {data.incoterms}
            </p>
            <p>
              <strong>Advance Ratio:</strong> {data.advance_ratio}
            </p>
            <p>
              <strong>Payment Terms:</strong> {data.payment_terms}
            </p>
          </div>

          {/* Shipment Details */}
          <div>
            <h3 className="text-xl font-semibold text-gray-700 mb-3">
              Shipment Details
            </h3>
            <p>
              <strong>Expected Date:</strong> {data.expected_shipment_date}
            </p>
            <p>
              <strong>Value:</strong> ${data.expected_shipment_value}
            </p>
          </div>

          {/* Contact Information */}
          <div className="md:col-span-2">
            <h3 className="text-xl font-semibold text-gray-700 mb-3">
              Contact Information
            </h3>
            <p>
              <strong>Contact Person:</strong> {data.contact_person_name}
            </p>
            <p>
              <strong>Email:</strong> {data.contact_persion_email}
            </p>
            <p>
              <strong>Phone:</strong> {data.contact_persion_phone}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default BuyerDetails;
