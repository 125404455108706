import { z } from "zod";

export const TradingInfoSchema = z.object({
  buyer_name: z.string().trim().min(1, "Buyer name is required."),
  buyer_address: z.string().trim().min(1, "Buyer address is required."),
  buyer_reg_no: z
    .string()
    .trim()
    .min(1, "Buyer registration number is required."),
  supplier_name: z.string().trim().min(1, "Supplier name is required."),
  supplier_address: z.string().trim().min(1, "Supplier address is required."),
  supplier_reg_no: z
    .string()
    .trim()
    .min(1, "Supplier registration number is required."),
  trading_history: z.string().trim().min(1, "Trading history is required."),
  buyer_trading_history: z
    .string()
    .trim()
    .min(1, "Buyer trading history is required."),
  supplier_trading_history: z
    .string()
    .trim()
    .min(1, "Supplier trading history is required."),
  turnover_previous_year: z
    .string()
    .trim()
    .regex(/^\d+$/, "Turnover for the previous year must be a valid number."),
  turnover_current_year: z
    .string()
    .trim()
    .regex(/^\d+$/, "Turnover for the current year must be a valid number."),
  underlying_goods: z
    .string()
    .trim()
    .min(1, "Underlying goods information is required."),
  transaction_tenor: z
    .string()
    .trim()
    .min(1, "Transaction tenor/time is required."),
  currency: z
    .string()
    .trim()
    .min(1, "Currency is required.")
    .max(3, "Currency must be a valid three-letter code (e.g., USD, EUR)."),
  limit_amount: z
    .string()
    .trim()
    .regex(/^\d+$/, "Credit limit must be a valid number."),
  incoterms: z
    .string()
    .trim()
    .min(1, "Incoterms (CIF/FOB/C&F, etc.) are required."),
  advance_ratio: z
    .string()
    .trim()
    .min(1, "Advance ratio & payment terms are required."),
  payment_terms: z.string().trim().min(1, "Payment terms are required."),
  expected_shipment_date: z
    .string()
    .trim()
    .regex(
      /^\d{4}-\d{2}-\d{2}$/,
      "Expected shipment date must be in YYYY-MM-DD format."
    ),
  expected_shipment_value: z
    .string()
    .trim()
    .regex(/^\d+$/, "Expected shipment value must be a valid number."),
  contact_person_name: z
    .string()
    .trim()
    .min(1, "Contact person name is required."),
  contact_persion_email: z
    .string()
    .trim()
    .email("Please enter a valid email address."),
  contact_persion_phone: z.string().trim().optional(),
});

export type TradingInfoType = z.infer<typeof TradingInfoSchema>;
