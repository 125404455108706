import { SubmitHandler, useForm } from "react-hook-form";
import {
  shipping_category,
  transportation_by,
} from "../../constants/tooltipContents";
import InputWithLabel from "../InputWithLabel";
import Tooltip from "../Tooltip";
import Dropdown from "../DropDown";
import { CrossIcon } from "../Icons";
import { Link } from "react-router-dom";

type LogisticCustomBookingFields = {
  lCargoCategory?: string | number;
  lCargoValueOfGoods?: string | number;
  lCargoTransportationBy?: string | number;
  lCargoContainerType?: string | number;
  lCargoContainerQuantity?: string | number;
  lCargoFreightBasis?: string | number;
  lCargoFrom?: string | number;
  lCargoTo?: string | number;
  lCargoLoadingDate?: string | number;
  lCargoWeight?: string | number;
};

export default function CustomBooking() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LogisticCustomBookingFields>();

  const onSubmitHandler: SubmitHandler<LogisticCustomBookingFields> = (
    values
  ) => {
    console.log(values);
  };

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)} className="space-y-2">
      <div className="flex items-center gap-4">
        <InputWithLabel
          type="text"
          label={"Category"}
          element={
            <div className="flex items-center space-x-2">
              <small className="text-custom-blue-new">HS CODES</small>
              <Tooltip content={shipping_category} />
            </div>
          }
          id={"category"}
          {...register("lCargoCategory", {
            required: "Category is required",
          })}
          error={errors.lCargoCategory?.message}
        />

        <Dropdown
          label={"Transportation By"}
          element={<Tooltip content={transportation_by} />}
          placeholder="Select transportation by"
          options={[
            {
              value: "LCL",
              label: "Less container load LCL",
            },
          ]}
        />

        <div className="flex w-[250px] space-x-1">
          <input type="radio" name="byUnits" className="w-4" />
          <label htmlFor="byUnits">By Units</label>
        </div>
      </div>

      <div className="flex items-center gap-2">
        <div>
          <label htmlFor="lot" className="text-[#344054] text-sm font-medium">
            LOT
          </label>
          <input type="text" name="lot" className="input-new !w-[150px]" />
        </div>

        <div>
          <label
            htmlFor="container_quantity"
            className="text-[#344054] text-sm font-medium"
          >
            Container Quantity
          </label>
          <div className="input-new flex items-center">
            <input
              className="w-full text-center pr-1 focus:outline-none focus:ring-0 focus:border-transparent"
              type="number"
              placeholder="0"
            />

            <CrossIcon className="size-3 w-full text-gray-400" />

            <input
              className="w-full text-center px-1 focus:outline-none focus:ring-0 focus:border-transparent"
              type="number"
              placeholder="0"
            />
            <CrossIcon className="size-3 w-full text-gray-400" />

            <input
              className="w-full text-center px-1 focus:outline-none focus:ring-0 focus:border-transparent border-r-2"
              type="number"
              placeholder="0"
            />
            <select className="px-1">
              <option value="cm">cm</option>
              <option value="m">m</option>
            </select>
          </div>
        </div>
        <div>
          <label
            htmlFor="quantity"
            className="text-[#344054] text-sm font-medium"
          >
            Quantity
          </label>
          <input className="!w-[100px] input-new" type="text" />
        </div>

        <div>
          <label
            htmlFor="gross_weight"
            className="text-[#344054] text-sm font-medium"
          >
            *Gross Weight
          </label>
          <div className="input-new flex items-center">
            <input
              className="w-full px-1 focus:outline-none focus:ring-0 focus:border-transparent border-r-2"
              type="number"
              placeholder="0"
              id="gross_weight"
            />
            <select className="px-1">
              <option value="kg">kg</option>
              <option value="mt">mt</option>
            </select>
          </div>
        </div>
      </div>

      <div className="flex items-center gap-9">
        <InputWithLabel
          type="text"
          label={"From"}
          id={"logisticsForm"}
          {...register("lCargoFrom", { required: "Field is required" })}
          error={errors.lCargoFrom?.message}
        />
        <InputWithLabel
          label={"Zip Code"}
          id={"zipCode"}
          // {...register("ZipCodeFrom", { required: "Field is required" })}
          error={errors.lCargoTo?.message}
        />
      </div>

      <div className="flex items-center gap-9">
        <InputWithLabel
          label={"To"}
          id={"logisticsTo"}
          {...register("lCargoTo", { required: "Field is required" })}
          error={errors.lCargoTo?.message}
        />
        <InputWithLabel
          label={"Zip Code"}
          id={"zipCode"}
          // {...register("ZipCodeTo", { required: "Field is required" })}
          error={errors.lCargoTo?.message}
        />
      </div>

      <div className="flex gap-6">
        <InputWithLabel
          label={"FREIGHT BASIS"}
          id={"freightBasis"}
          {...register("lCargoFreightBasis", {
            required: "Freight basis is required",
          })}
          error={errors.lCargoFreightBasis?.message}
        />

        <InputWithLabel
          label={"Ready to Load"}
          id={"logisticsWeight"}
          type="date"
          // {...register("loadingDate", { required: "Field is required" })}
          error={errors.lCargoWeight?.message}
        />
      </div>

      <div className="text-[#344054]">
        <h3 className="mb-1 mt-4 font-medium">Associated Services:</h3>

        <div className="flex gap-5">
          <div className="w-full space-y-2">
            <label className="w-full border p-2 rounded-lg flex items-center gap-2 cursor-pointer">
              <input
                type="checkbox"
                className="size-4"
                // {...register("customs_clearance")}
              />
              <svg
                className="size-4"
                viewBox="0 0 11 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.5001 0.5L5.0001 1.5H10.0001L10.5001 0.5H4.5001ZM5.0001 2V3.25C5.0001 3.25 5.0001 6 7.5001 6C10.0001 6 10.0001 3.25 10.0001 3.25V2H5.0001ZM0.9841 3.486C0.719224 3.49021 0.466843 3.59935 0.282357 3.78946C0.0978713 3.97957 -0.0036423 4.23512 9.99123e-05 4.5V8.5C-2.42119e-05 8.64982 0.0335183 8.79776 0.0982491 8.93288C0.16298 9.06799 0.257244 9.18684 0.374077 9.28063C0.49091 9.37443 0.627324 9.44077 0.773241 9.47476C0.919158 9.50875 1.07085 9.50951 1.2171 9.477L4.0001 8.857V13.5L9.8791 6.57C9.73706 6.52644 9.58964 6.50288 9.4411 6.5H5.5001L2.0001 7.254V4.5C2.00199 4.36615 1.97699 4.23328 1.92657 4.10927C1.87616 3.98526 1.80136 3.87263 1.70661 3.77807C1.61186 3.6835 1.49909 3.60893 1.37498 3.55876C1.25087 3.50859 1.11795 3.48384 0.9841 3.486ZM10.7481 7.211L5.4121 13.5H11.0001V8.059C11.0001 7.745 10.9061 7.455 10.7481 7.211Z"
                  fill="black"
                />
              </svg>
              Customs Clearance
            </label>
          </div>

          <div className="w-full space-y-2 pb-4">
            <label className="w-full border p-2 rounded-lg flex items-center gap-2 cursor-pointer">
              <input
                type="checkbox"
                className="size-4"
                // {...register("insurance")}
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="size-4 text-black"
              >
                <path
                  fillRule="evenodd"
                  d="M8.603 3.799A4.49 4.49 0 0 1 12 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 0 1 3.498 1.307 4.491 4.491 0 0 1 1.307 3.497A4.49 4.49 0 0 1 21.75 12a4.49 4.49 0 0 1-1.549 3.397 4.491 4.491 0 0 1-1.307 3.497 4.491 4.491 0 0 1-3.497 1.307A4.49 4.49 0 0 1 12 21.75a4.49 4.49 0 0 1-3.397-1.549 4.49 4.49 0 0 1-3.498-1.306 4.491 4.491 0 0 1-1.307-3.498A4.49 4.49 0 0 1 2.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 0 1 1.307-3.497 4.49 4.49 0 0 1 3.497-1.307Zm7.007 6.387a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                  clipRule="evenodd"
                />
              </svg>
              Insurance
            </label>

            <InputWithLabel
              label="Invoice Amount"
              placeholder="Enter value"
              type="number"
              // {...register("invoice_amount", {
              //   required: "Enter a invoice amount",
              // })}
              // error={errors.invoice_amount?.message}
            />
          </div>
        </div>
      </div>

      <div className="flex items-center justify-between">
        <Link
          className="w-[87px] h-[44px] flex items-center justify-center border border-[#D0D5DD] text-[#344054] font-medium rounded-lg"
          to={"/logistics"}
        >
          Back
        </Link>

        <button
          type="submit"
          className="w-[146px] h-[44px] rounded-md bg-custom-blue-medium text-white font-medium"
        >
          Next
        </button>
      </div>
    </form>
  );
}
