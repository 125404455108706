export default function CheckIcon({ className }: { className: string }) {
  return (
    <svg
      width={12}
      height={9}
      viewBox="0 0 12 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M11.3346 1L4.0013 8.33333L0.667969 5"
        stroke="currentColor"
        strokeWidth={1.33333}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
