import { useRef } from "react";

import { useAppContext } from "../context/AppContext";
import { get_transaction_summary } from "../constants/apis";

import { useFetch } from "../hooks/useFetch";

import Dropdown from "../components/DropDown";
import Modal from "../components/Modal";
import { TransactionSummaryRes } from "../types/TransactionSummary";
import PaymentCard from "../components/TransactionSummary/PaymentCard";
import InvoiceFactoringCard from "../components/TransactionSummary/InvoiceFactoringCard";
import LogisticCard from "../components/TransactionSummary/LogisticCard";
import QualityAssuranceCard from "../components/TransactionSummary/QualityAssuranceCard";
import { Link } from "react-router-dom";

const TransactionSummary = () => {
  const { jwtToken } = useAppContext();

  const newTransactionModalRef = useRef<HTMLDialogElement>(null);

  const toggleModal = () => {
    newTransactionModalRef.current?.close();
  };

  const { data, loading } = useFetch<TransactionSummaryRes>(
    get_transaction_summary,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        "Content-Type": "application/json",
      },
    }
  );

  // console.log(data);

  if (loading)
    return (
      <section className="animate-pulse space-y-6 mt-2 mb-5">
        <div className="h-[60px] w-full bg-slate-300 rounded"></div>

        <div className="h-[160px] w-full bg-slate-300 rounded"></div>
        <div className="h-[160px] w-full bg-slate-300 rounded"></div>
      </section>
    );

  return (
    <>
      <section className="space-y-6">
        <div className="card flex justify-between">
          <p className="text-custom-blue-new text-xl font-semibold">
            Total Cost
          </p>
          <p className="text-gray-800 font-bold text-xl">$2360</p>
        </div>

        {data?.simple_payment_data && (
          <PaymentCard simplePaymentData={data.simple_payment_data} />
        )}

        {/* {data?.  && <SETSummaryCard />} */}

        {data?.invoice_factoring_data && (
          <InvoiceFactoringCard
            invoiceFactoringData={data.invoice_factoring_data}
          />
        )}

        {data?.logistics_data && (
          <LogisticCard logisticsData={data.logistics_data} />
        )}

        {data?.quality_assurance_data && (
          <QualityAssuranceCard
            qualityAssuranceData={data.quality_assurance_data}
          />
        )}

        {/* <InsuranceTSummaryCard /> */}

        <div className="flex justify-between items-center">
          <div className="">
            <Dropdown
              label=""
              placeholder="Add Service"
              options={[
                { label: "Payment", value: "Payment" },
                {
                  label: "Invoice Factoring",
                  value: "Invoice Factoring",
                },
                {
                  label: "Logistics",
                  value: "Logistics",
                },
                {
                  label: "Quality Assurance",
                  value: "Quality Assurance",
                },
              ]}
            />
          </div>

          <div className="space-x-4">
            <Link
              to="/dashboard"
              className="px-3 py-2 rounded-md text-custom-blue border border-custom-blue"
            >
              Dashboard
            </Link>

            <button
              className="px-3 py-2 rounded-md bg-custom-blue text-white"
              onClick={() => newTransactionModalRef.current?.showModal()}
            >
              Start a new transaction
            </button>
          </div>
        </div>
      </section>
      <Modal
        ref={newTransactionModalRef}
        className="backdrop:bg-black/40 w-[460px] rounded-xl p-8"
        toggleDialog={toggleModal}
      >
        <div className="space-y-6">
          <h3 className="text-2xl text-center mt-4">
            Do you want to start a new transaction?
          </h3>

          <div className="text-center space-x-4">
            <button className="px-3 py-2 rounded-md text-custom-blue border border-custom-blue">
              No, continue current transaction
            </button>

            <button className="px-4 py-2 rounded-md bg-custom-blue text-white">
              Yes
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default TransactionSummary;
