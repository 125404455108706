import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import {
  SimpleRegistrationSignupSchema,
  SimpleRegistrationSignupType,
} from "../schemas/SimpleRegistrationFormSchema";
import { simple_user_registeration } from "../constants/apis";

import InputWithLabel from "../components/InputWithLabel";
import Dropdown from "../components/DropDown";
import OtpPopup from "../components/OtpPopup";
import { SpinnerIcon, TaramLogo } from "../components/Icons";

import { COMPANY_REGION, PHONE_NO_COUNTRY_CODE } from "../constants/variable";

export default function SignupPage() {
  const [isAccountCreated, setIsAccountCreated] = useState(false);
  // const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    getValues,
  } = useForm<SimpleRegistrationSignupType>({
    resolver: zodResolver(SimpleRegistrationSignupSchema),
  });

  const onHandleSubmit: SubmitHandler<SimpleRegistrationSignupType> = async (
    values
  ) => {
    // console.log(values);

    try {
      const response = await fetch(simple_user_registeration, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          first_name: values.firstName,
          // middle_name: values.middleName,
          last_name: values.lastName,
          company_name: values.companyName,
          work_email: values.workEmail,
          countryCode: values.countryCode,
          company_phone_number: values.businessPhone,
          company_reg_number: values.companyRegNumber,
          company_region: values.companyRegion,
          pwd: values.password,
        }),
      });
      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }
      const data = await response.json();
      // console.log(data);
      if (data.status === "success") {
        setIsAccountCreated(true);
        toast.success(data.message);
      } else {
        setIsAccountCreated(false);
        toast.success(data.message || "Something went wrong please try again");
      }
    } catch (error) {
      console.log(error);
    }
  };
  // console.log(errors);

  return (
    <section className="p-4 lg:p-0">
      <Link to={"/"} className="hover:underline text-gray-600">
        &#x2190; Home
      </Link>
      <div className="my-2">
        <Link to={"/"}>
          <TaramLogo className="w-28" />
        </Link>
      </div>

      <form
        onSubmit={handleSubmit(onHandleSubmit)}
        className="max-w-[600px] grid grid-cols-1 gap-2 md:grid-cols-2"
      >
        <div className="col-span-full mb-2">
          <h2 className="text-gray-800 text-3xl font-bold">
            Create Your Account
          </h2>
          <p className="text-gray-600 text-sm">
            Welcome! Registering with us is quick and easy. Simply fill out the
            form below to start exploring our services and benefits.
          </p>
        </div>

        <InputWithLabel
          label={"First Name"}
          placeholder="Enter here"
          id={"firstName"}
          {...register("firstName")}
          error={errors.firstName?.message}
        />
        <InputWithLabel
          label={"Last Name"}
          placeholder="Enter here"
          id={"lastName"}
          {...register("lastName")}
          error={errors.lastName?.message}
        />

        <InputWithLabel
          label={"Company Name"}
          placeholder="Enter here"
          id={"companyName"}
          {...register("companyName")}
          error={errors.companyName?.message}
        />

        <InputWithLabel
          label={"Company Registration Number"}
          placeholder="Enter here"
          id={"companyRegNumber"}
          {...register("companyRegNumber")}
          error={errors.companyRegNumber?.message}
        />

        <Dropdown
          label="Company Region"
          placeholder="Select Company Region"
          options={COMPANY_REGION}
          {...register("companyRegion")}
          error={errors.companyRegion?.message}
        />

        <InputWithLabel
          label={"Work Email"}
          placeholder="Enter here"
          id={"workEmail"}
          {...register("workEmail")}
          error={errors.workEmail?.message}
        />

        <Dropdown
          label="Country Code"
          placeholder="Select a country code"
          options={PHONE_NO_COUNTRY_CODE.map((el) => ({
            label: el.label,
            value: el.countryCode,
          }))}
          {...register("countryCode")}
          error={errors.countryCode?.message}
        />

        <InputWithLabel
          label={"Business Phone Number"}
          placeholder="Enter here"
          id={"businessPhone"}
          {...register("businessPhone")}
          error={errors.businessPhone?.message}
        />

        <InputWithLabel
          label={"Password"}
          type="password"
          id={"password"}
          placeholder="Enter password"
          {...register("password")}
          error={errors.password?.message}
        />
        <InputWithLabel
          label={"Retype Password"}
          type="password"
          id={"retypePassword"}
          placeholder="Enter retype password"
          {...register("retypePassword")}
          error={errors.retypePassword?.message}
        />

        <button
          type="submit"
          disabled={isSubmitting}
          className="mt-2 col-span-full flex justify-center items-center bg-custom-dark-blue-new text-white py-2 rounded-lg shadow-md hover:bg-blue-900 transition"
        >
          {isSubmitting ? (
            <SpinnerIcon className="size-6 text-gray-200 animate-spin fill-blue-600" />
          ) : (
            "Sign Up"
          )}
        </button>
      </form>
      <p className="text-center text-sm text-gray-600 mt-4">
        Already have an account?{" "}
        <Link to="/login" className="text-blue-600 hover:underline">
          Log in
        </Link>
      </p>

      {isAccountCreated && <OtpPopup emailId={getValues("workEmail")} />}
    </section>
  );
}
