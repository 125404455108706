import { createContext, useContext } from "react";
import { useAppContext } from "./AppContext";
import { useFetch } from "../hooks/useFetch";
import { get_nium_wallet_balance } from "../constants/apis";

type NiumWalletRes = {
  curSymbol: string;
  isoCode: string;
  balance: number;
  withHoldingBalance: number;
  pocketName: string;
  mccData: any[];
  default: boolean;
};

type NiumErrorRes = {
  status: "BAD_REQUEST";
  message: string;
  code: string;
};

type NiumWalletData = {
  walletData: NiumWalletRes[];
  loading: boolean;
  refetch: () => void;
};

const WalletFundContext = createContext<NiumWalletData | null>(null);

export const WalletFundContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { jwtToken } = useAppContext();

  const { data, loading, refetch } = useFetch<NiumWalletRes[]>(
    jwtToken ? get_nium_wallet_balance : null,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        "Content-Type": "application/json",
      },
    }
  );

  const walletData = Array.isArray(data)
    ? data
    : [
        {
          curSymbol: "USD",
          isoCode: "USD",
          balance: 0,
          withHoldingBalance: 0,
          pocketName: "",
          mccData: [],
          default: false,
        },
      ];

  // console.log(data);

  return (
    <WalletFundContext.Provider value={{ walletData, loading, refetch }}>
      {children}
    </WalletFundContext.Provider>
  );
};

export const useWalletFundContext = () => {
  const context = useContext(WalletFundContext);

  if (!context) throw new Error("Context must be used within an provider");

  return context;
};
