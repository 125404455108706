import { useRef, useState } from "react";

import { verify_user } from "../constants/apis";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../context/AppContext";
// import SuccessIcon from "./Icons/SuccessIcon";

interface OTPProps {
  emailId: string;
}

export default function OtpPopup({ emailId }: OTPProps) {
  const [otp, setOtp] = useState<string[]>(["", "", "", "", "", ""]);
  const [error, setError] = useState("");
  const [isLoading, setIsloading] = useState(false);

  const navigate = useNavigate();
  const { setJwtToken } = useAppContext();

  // Refs to focus the next input after each digit
  const inputRefs = useRef<HTMLInputElement[]>([]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const value = e.target.value;

    // Only allow one digit per input
    if (/^\d$/.test(value) || value === "") {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      setError("");

      // Move focus to the next input if the current one is filled
      if (value && index < 5) {
        inputRefs.current[index + 1].focus();
      }

      // Focus back to the previous input if the current input is emptied
      if (value === "" && index > 0) {
        inputRefs.current[index - 1]?.focus();
      }
    }
  };

  const sendOtpToServer = async (mailId: string, verifyCode: string) => {
    setIsloading(true);

    try {
      const response = await fetch(verify_user, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: mailId,
          verify_code: verifyCode,
        }),
      });

      const data = await response.json();

      // console.log(data);

      if (data.status == "success") {
        setJwtToken(data.jwt); //setting JWTToken...

        navigate("/dashboard");
      } else if (data.status == "fail") {
        setError(data?.message);
      }
    } catch (error) {
      console.log(error);
      setError("Something went wrong please try again!");
    } finally {
      setIsloading(false);
    }
  };

  const handleSubmit = async () => {
    const otpString = otp.join("");
    if (otpString) {
      await sendOtpToServer(emailId, otpString);
    } else {
      setError("OTP is not correct");
      setOtp(["", "", "", "", "", ""]); // Reset OTP on incorrect value
      inputRefs.current[0].focus(); // Focus back to the first input
    }
  };

  return (
    <div className="fixed top-0 bottom-0 left-0 right-0 bg-black/55 flex items-center justify-center">
      <div className="w-[450px] bg-white p-10 text-center rounded-lg shadow space-y-4">
        <div>
          <h3 className="text-3xl font-bold text-gray-800">OTP Verification</h3>
          <p className="text-gray-500">Enter OTP code sent to</p>
          <p className="text-custom-blue">{emailId}</p>
        </div>

        <div className="flex justify-center gap-2">
          {otp.map((digit, index) => (
            <input
              key={index}
              type="text"
              value={digit}
              onChange={(e) => handleChange(e, index)}
              maxLength={1}
              ref={(el) => (inputRefs.current[index] = el!)} // Store refs for each input
              className={`size-14 text-center rounded-md bg-blue-100 ${
                error ? "border-2 border-red-300" : ""
              }`}
              placeholder="-"
            />
          ))}
        </div>
        <div>
          <h4>Didn’t receive OTP code?</h4>
          <button className="text-sm text-blue-500 hover:underline">
            Resend Code
          </button>
        </div>

        <small className="text-red-400 h-2">{error && error}</small>

        <div>
          <button
            type="button"
            className="bg-custom-blue-medium text-white w-full h-[50px] mt-5 rounded-lg"
            onClick={handleSubmit}
            disabled={isLoading}
          >
            {isLoading ? "verifying..." : "Verify & Proceed"}
          </button>
        </div>
      </div>
    </div>
  );
}
