const FeesIcon = ({ className }: { className: string }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 12 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4568_3896)">
        <path
          d="M2 0C0.896875 0 0 0.896875 0 2V14C0 15.1031 0.896875 16 2 16H10C11.1031 16 12 15.1031 12 14V5H8C7.44688 5 7 4.55312 7 4V0H2ZM8 0V4H12L8 0ZM2 2.5C2 2.225 2.225 2 2.5 2H4.5C4.775 2 5 2.225 5 2.5C5 2.775 4.775 3 4.5 3H2.5C2.225 3 2 2.775 2 2.5ZM2 4.5C2 4.225 2.225 4 2.5 4H4.5C4.775 4 5 4.225 5 4.5C5 4.775 4.775 5 4.5 5H2.5C2.225 5 2 4.775 2 4.5ZM6 6.75C6.275 6.75 6.5 6.975 6.5 7.25V7.79063C6.76562 7.82812 7.02188 7.8875 7.25313 7.95C7.51875 8.02187 7.67812 8.29375 7.60625 8.5625C7.53437 8.83125 7.2625 8.9875 6.99375 8.91562C6.64687 8.82187 6.30625 8.75313 5.99062 8.75C5.72813 8.74687 5.44688 8.80625 5.25313 8.92188C5.075 9.02812 5 9.15 5 9.32187C5 9.4375 5.04063 9.525 5.22813 9.6375C5.44375 9.76562 5.74687 9.85938 6.14062 9.97812L6.15625 9.98125C6.50938 10.0875 6.94688 10.2188 7.29063 10.4375C7.66875 10.675 7.99062 11.0531 8 11.6313C8.00937 12.2344 7.7 12.6719 7.28438 12.9312C7.04375 13.0812 6.77187 13.1687 6.5 13.2156V13.75C6.5 14.025 6.275 14.25 6 14.25C5.725 14.25 5.5 14.025 5.5 13.75V13.1938C5.15 13.1281 4.82188 13.0156 4.53438 12.9156C4.46875 12.8937 4.40312 12.8719 4.34062 12.85C4.07812 12.7625 3.9375 12.4781 4.025 12.2188C4.1125 11.9594 4.39687 11.8156 4.65625 11.9031C4.73438 11.9281 4.80625 11.9531 4.87813 11.9781C5.30313 12.1219 5.64687 12.2406 6.0125 12.25C6.29688 12.2594 6.57188 12.1969 6.75313 12.0844C6.9125 11.9844 7 11.8563 6.99687 11.6469C6.99375 11.5031 6.94062 11.4031 6.75625 11.2844C6.54375 11.15 6.24062 11.0531 5.85 10.9344L5.8 10.9187C5.45625 10.8156 5.04063 10.6906 4.7125 10.4906C4.3375 10.2656 4.00625 9.9 4.00313 9.325C4 8.71875 4.34062 8.3 4.74687 8.05937C4.98125 7.92188 5.24062 7.83437 5.5 7.7875V7.24687C5.5 6.97187 5.725 6.74687 6 6.74687V6.75Z"
          fill="#757575"
        />
      </g>
      <defs>
        <clipPath id="clip0_4568_3896">
          <rect width={12} height={16} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FeesIcon;
