const PortugalFlag = () => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <rect width={24} height={24} fill="url(#pattern0_5688_7623)" />
    <defs>
      <pattern
        id="pattern0_5688_7623"
        patternContentUnits="objectBoundingBox"
        width={1}
        height={1}
      >
        <use xlinkHref="#image0_5688_7623" transform="scale(0.0208333)" />
      </pattern>
      <image
        id="image0_5688_7623"
        width={48}
        height={48}
        preserveAspectRatio="none"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAADY0lEQVR4nO2YYUzUdRjHnx0yLedYs10xSV80s7VJG7Xp1ngFtIN0CPc2mC1z6RLH5IbZrkBCbtIFNdLAgQOOIw7+4nlkJwedjLgjdVSbdGd3J3dmh2lUUxlNXJ/G3ze9aevF2f60/2d79n/2e94839/zPL89+4vo6Ojo6Oj8H8i25pAKI1f+ExNdgFWvAHoL/R19iK3L9BXaWpuN5eQ6un0ZDI2kEzwjxJ3C7DvCQoHGBVR3ZzF6cSV1fZkU2TbxbucT/FAr/FYq3LAJM37h50MaFfDJmbUMdOVzrLaYjnoznUdK8TQXEmgsYOqgicuHTExW5vHt8Zf4sUX4U0sCBj5PZ3jUwFdvbaHtNWHRICCCd4fgNT/wl87arMJ4xVbGhoTgCY0IKG/cQCwmLC4K8ao8ft0vLJgKYPdu7tjzuW3PU/0/TPnMVQiJAwUEHcK4X0gWa0CAzWXkfDCdS1MG4ru2q7c9rygs0e4O86nyverfdbnU2PXyEn4vEmbfF5KHNSBAGVvDzqb1OD2riL75MtPPC3cG+tWkh8YTKKNXVf9232dM5wixN15hxiJc3yMkTmlAgNtjoM21mvpeI1/uf4Gmw8LFVicVjRM4vRE6h65QaQ8w2eKguU6Y2PcisQ+EKacw5tOAAO85A31nH+GY+zG+2WkisV4Id/Sy1zZO/0gMx9kI+45OMH2iR41FXy8i1ioEtCJAOb+GYttGyps28N2eUrXP79rtatsM+mdw+WIPZqC5WY3FLWZC7wmzZUJC0YAAa08mR/qfpKjhGZQmM8mnheD2tdy7eZOeLyJ0uMPcu3WLwA6jGoue3saNK0KoVUjWaEBAbs1znBs24PI+SkPvOkbKNuNuEOaezWTeYmG+qkr1T7UIY7s2E51exdWQMBMTftmmAQHZ1hzaB1fz9VSa+hJ1Dm4iVFbI/bQ0tWWW7P6KFUReLeSy4yku+ITJgKjfVCVPKlaJj04/jsOXwd7jWRxsz8TZZeKSzUyovoSwPZfI2waiVUJcEa59nNrkSdUyd+BkFv4LKzmqGClp3Ehtt5FwnTBXJvz0oRDxC7PVqU+eVK7TW2qyqWzPoms4A8/SOu0RrvUIyWphIe/hJI/+V8L6zxV5WDeOXgHrv5sJvQK5egvlLM8W0tHR0dHRkWXIX4CBHST3//ORAAAAAElFTkSuQmCC"
      />
    </defs>
  </svg>
);
export default PortugalFlag;
