import { CURRENCY_OPTIONS } from "../../../constants/variable";
import Dropdown from "../../DropDown";
import {
  CheckIcon,
  FeaturesIcon,
  PricingIcon,
  SimplePaymentIcon,
} from "../../Icons";
import InputWithLabel from "../../InputWithLabel";

const FEATURES_ITEMS = [
  "Open local currency accounts in 100+ currencies",
  "FX Save upto 90% on forex compared to banks",
  "Near Instant payouts Upto 220+ countries instant payouts",
  "Low Escrow & transfer fee",
  "Payment infrastructure made for Exporters & importers",
];

export default function Initiate() {
  return (
    <section className="space-y-8">
      <div>
        <h2 className="inline-flex items-center gap-2">
          <SimplePaymentIcon className="size-6" />{" "}
          <span className="text-custom-blue-medium font-semibold text-[20px]">
            Payment
          </span>
        </h2>
        <p className="text-[#475467] text-sm">
          Taram facilitates money remittance in multiple currencies across the
          world for exporters and importers.
        </p>
      </div>

      <div className="grid grid-cols-[400px_1fr] gap-5">
        <div className="p-5 border border-[#F3F3F5] rounded-[10px]">
          <h3 className="mb-4 font-semibold text-[18px] inline-flex items-center gap-2">
            <PricingIcon className="size-6" /> Pricing Estimate*
          </h3>

          <div className="space-y-2">
            <div className="flex items-center gap-4">
              <p className="text-sm text-[#475467] font-semibold w-[150px]">
                Send
              </p>
              <Dropdown label="Currency" options={CURRENCY_OPTIONS} />
              <InputWithLabel label="Amount" placeholder="Enter Here ..." />
            </div>

            <div className="flex items-center gap-4">
              <p className="text-sm text-[#475467] font-semibold w-[150px]">
                Receive
              </p>
              <Dropdown label="Currency" options={CURRENCY_OPTIONS} />
              <InputWithLabel label="Amount" placeholder="Enter Here ..." />
            </div>

            <button
              type="button"
              className="px-3 py-[6px] w-full rounded-md bg-custom-blue-medium text-white font-medium"
            >
              Calculate
            </button>

            <p className="text-sm text-[#758599] space-x-1">
              <span className="text-[#475467]">Results:</span>
              <span>Estimated FX Rate</span>
              <span className="text-green-500 font-bold">87.4 INR</span>
              <span>& fees</span>
              <span className="text-green-500 font-bold">2.4 USD</span>
            </p>
          </div>
        </div>

        <div className="p-5 border border-[#F3F3F5] rounded-[10px]">
          <h3 className="mb-4 font-semibold text-[18px] inline-flex items-center gap-2">
            <FeaturesIcon className="size-6" /> Features
          </h3>

          <div className="space-y-[10px] mt-3">
            {FEATURES_ITEMS.map((el, i) => (
              <p key={i} className="inline-flex items-center gap-2 text-sm">
                <CheckIcon className="size-4 text-custom-blue-medium" />
                <span className="text-[#475467]">{el}</span>
              </p>
            ))}
          </div>
        </div>

        <div className="col-span-full">
          <p className="text-sm space-x-1">
            <span className="text-[#DC0000]">*Disclaimer:</span>
            <span className="text-[#475467]">
              All offers of financing are subject to credit and compliance
              approvals
            </span>
          </p>
        </div>
      </div>
    </section>
  );
}
