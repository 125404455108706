import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Link } from "react-router-dom";
import { z } from "zod";
import { toast } from "react-toastify";

import { admin_login } from "../../constants/apis";
import { useAppContext } from "../../context/AppContext";

import InputWithLabel from "../../components/InputWithLabel";
import { TaramLogo } from "../../components/Icons";

const loginSchema = z.object({
  email: z.string().trim().email("Please enter a valid email address"),
  password: z
    .string()
    .trim()
    .min(4, "Password must be at least 4 characters long"),
});

type LoginFormData = z.infer<typeof loginSchema>;

export default function AdminLogin() {
  const navigate = useNavigate();
  const { setJwtToken } = useAppContext();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<LoginFormData>({
    resolver: zodResolver(loginSchema),
  });

  const onSubmit = async (values: LoginFormData) => {
    // console.log("Login Data:", values);

    try {
      const response = await fetch(admin_login, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || "Login failed. Please try again.");
      }

      // console.log("Login Successful:", data);
      if (data.status === "fail") {
        toast.error(data?.message || data?.error);
      } else if (data.status === "success") {
        setJwtToken(data.jwt, "adminToken");
        navigate("/admin/dashboard");
      }
    } catch (error) {
      // console.log(error);
      if (error instanceof Error) {
        toast.error(error.message);
      }
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
        <div className="flex justify-center mb-4">
          <TaramLogo className="w-28" />
        </div>
        <h2 className="text-2xl font-semibold text-center text-gray-700">
          Admin Login
        </h2>
        <p className="text-center text-sm text-gray-500 mb-6">
          Enter your credentials to access your account
        </p>

        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
          <InputWithLabel
            label="Email Address"
            placeholder="you@example.com"
            {...register("email")}
            error={errors.email?.message}
          />

          <InputWithLabel
            label="Password"
            type="password"
            placeholder="Enter your password"
            {...register("password")}
            error={errors.password?.message}
          />

          {/* Submit Button */}
          <button
            type="submit"
            disabled={isSubmitting}
            className="w-full bg-blue-600 text-white py-3 rounded-lg font-semibold hover:bg-blue-700 transition"
          >
            {isSubmitting ? "Logging in..." : "Login"}
          </button>

          {/* Extra Links */}
          <div className="text-center text-sm text-gray-500 mt-4">
            <Link to={"/"} className="text-blue-600 hover:underline">
              Go to home
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}
