import { Link, useNavigate } from "react-router-dom";

import CardSkeleton from "../CardSkeleton";

import { usePartnersDataContext } from "../../context/PartnersDataContext";

export default function Financing() {
  const navigate = useNavigate();

  const { data: partnersData, loading } = usePartnersDataContext();

  const filteredpartnersData = partnersData?.filter(
    (el) => parseFloat(el.limit_request as string) > 0
  );

  return (
    <section className="card h-[497px]">
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-[#030303] font-semibold text-xl">Financing</h2>

        <div>
          <Link to={""} className="text-custom-blue-new font-semibold">
            View all
          </Link>
        </div>
      </div>

      <div>
        {loading && (
          <div className="">
            {[1, 2, 3].map((el) => (
              <CardSkeleton key={el} />
            ))}
          </div>
        )}
      </div>

      <div className="h-[400px] overflow-y-scroll custom-scrollbar">
        {partnersData && filteredpartnersData && filteredpartnersData.length > 0
          ? filteredpartnersData?.map((el, index) => (
              <div
                key={el.beneficiary_id}
                className={`flex items-center justify-between py-3 px-2 cursor-pointer hover:bg-blue-50 hover:rounded-lg ${
                  index + 1 !== partnersData.length && "border-b"
                }`}
                onClick={() =>
                  navigate(
                    `/dashboard/insights?beneficiary_id=${el.beneficiary_id}` // this needs to be partners id TODO
                  )
                }
              >
                <div>
                  <div className="flex items-center gap-3">
                    <div className="w-10 h-10 bg-slate-300 rounded-full flex justify-center items-center font-medium">
                      {/* <img src={el.logo} alt="logo" /> */}
                      {el.company_name.at(0)?.toUpperCase()}
                    </div>

                    <div>
                      <p className="font-medium">{el.company_name}</p>
                      <small className="text-gray-400">
                        {el.company_registration_number}
                      </small>
                    </div>
                  </div>
                </div>

                <div>
                  <p className="text-green-500 text">{el.limit_request} USD</p>
                </div>
              </div>
            ))
          : !loading && (
              <p className="text-center text-gray-500">No records found</p>
            )}
      </div>
    </section>
  );
}
