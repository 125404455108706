import { useRef, useState } from "react";
import AddNewVendor from "../../components/Admin/AddNewVendor";
import { useFetch } from "../../hooks/useFetch";
import { get_vendors } from "../../constants/apis";
import { useAppContext } from "../../context/AppContext";
import { AddNewVendorFormType } from "../../schemas/VendorFormSchema";

export type VendorsRes = {
  pagination: {
    current_page: number;
    total_pages: number;
    total_records: number;
    limit: number;
  };
  vendor_data: AddNewVendorFormType[];
};

const VendorPage = () => {
  // const navigate = useNavigate();
  const addNewVendorDetailssRef = useRef<HTMLDialogElement>(null);
  const [formType, setFormType] = useState<"add" | "update">("add");
  const [selectedVendor, setSelectedVendor] = useState<AddNewVendorFormType>();

  const { adminJWTToken } = useAppContext();

  const { data, loading, refetch } = useFetch<VendorsRes>(get_vendors, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${adminJWTToken}`,
      "content-type": "application/json",
    },
  });

  const vendorsData = data?.vendor_data;
  const pagination = data?.pagination;

  const handleOpenModal = (type: "add" | "update") => {
    setFormType(type);
    addNewVendorDetailssRef.current?.showModal();
  };

  const closeModal = () => {
    addNewVendorDetailssRef.current?.close();
  };

  // console.log(vendorsData);
  const editVendorHandlder = (type: "add" | "update") => {
    setFormType(type);
    addNewVendorDetailssRef.current?.showModal();

    return (vendor: AddNewVendorFormType) => setSelectedVendor(vendor);
  };

  return (
    <section className="card my-10">
      <div className="flex justify-between items-center my-4 mb-3 pb-3 border-b border-gray-200">
        <h2 className="text-[#030303] font-semibold text-2xl">
          Vendor Profiles
        </h2>
        <button
          type="button"
          className="py-2 px-3 text-custom-blue-medium font-semibold border border-custom-blue-medium rounded-lg"
          onClick={() => handleOpenModal("add")}
        >
          Add New Vendor
        </button>
      </div>

      <dialog
        ref={addNewVendorDetailssRef}
        className="rounded-lg backdrop:bg-black/50"
      >
        <AddNewVendor
          onClose={closeModal}
          formType={formType}
          selectedVendor={selectedVendor}
          refetch={refetch}
        />
      </dialog>

      <div className="space-y-2">
        {loading && <p className="text-center">loading...</p>}

        {vendorsData?.map((vendor) => (
          <div
            key={vendor.vendor_id}
            className="grid grid-cols-[1fr_1fr_1fr_80px] items-center"
          >
            <div className="flex items-center gap-2">
              <div className="size-10 bg-gray-500 rounded-full flex items-center justify-center">
                <p className="text-white text-2xl">
                  {vendor?.company_name.at(0)}
                </p>
              </div>
              <div>
                <p className="text-gray-800 font-medium">
                  {vendor.company_name}
                </p>
                <small className="text-gray-400">
                  {vendor.services_offered}
                </small>
              </div>
            </div>
            <div>
              <p className="text-gray-800">Email:</p>

              <div className="space-y-1">
                {vendor.contact_details.map((el, index) => (
                  <p
                    key={`${el.email}-${index}`}
                    className="text-gray-400 text-sm"
                  >{`${el.company_division} - ${el.email}`}</p>
                ))}
              </div>
            </div>
            <div>
              <p className="text-gray-800">Phone:</p>

              <div className="space-y-1">
                {vendor.contact_details.map((el, index) => (
                  <p
                    key={`${el.phone}-${index}`}
                    className="text-gray-400 text-sm"
                  >{`${el.company_division} (${el.contact_person_name}) - ${el.phone}`}</p>
                ))}
              </div>
            </div>
            <div>
              <button
                type="button"
                className="px-4 py-1 border border-[#D0D5DD] text-[#344054] font-medium rounded-lg"
                onClick={() => editVendorHandlder("update")(vendor)}
              >
                Edit
              </button>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};
export default VendorPage;
