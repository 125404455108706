import ArrowIcon from "../components/Icons/ArrowIcon";
import InputWithLabel from "../components/InputWithLabel";

import UserDetailsSection from "../components/SecurityPageComponents/UserDetailsSection";
import ChangePasswordSection from "../components/SecurityPageComponents/ChangePasswordSection";
import SecurityQuestionsSection from "../components/SecurityPageComponents/SecurityQuestionsSection";
import TwoFASection from "../components/SecurityPageComponents/TwoFASection";
import AuthorizedPersons from "../components/SecurityPageComponents/AuthorizedPersons";

export default function Security() {
  return (
    <section className="space-y-4">
      <details
        open
        className="bg-custom-dark-blue-new rounded-xl group border border-[#f3f3f5]"
      >
        <summary className="text-white font-semibold text-lg p-4 cursor-pointer flex items-center justify-between">
          <span>Login Information</span>

          <ArrowIcon className="size-6 transition-transform duration-300 group-open:rotate-180" />
        </summary>
        <div className="mt-2 p-5 bg-white rounded-b-lg">
          <UserDetailsSection />
          <hr className="h-2 my-3" />

          <ChangePasswordSection />

          <hr className="h-2 my-3" />

          <SecurityQuestionsSection />

          <hr className="h-2 my-3" />

          <TwoFASection />
        </div>
      </details>

      <details className="bg-custom-dark-blue-new rounded-xl group border border-[#f3f3f5]">
        <summary className="text-white font-semibold text-lg p-4 cursor-pointer flex items-center justify-between">
          <span>Platform Authorization</span>

          <ArrowIcon className="size-6 transition-transform duration-300 group-open:rotate-180" />
        </summary>
        <div className="mt-2 p-5 bg-white rounded-b-lg">
          <AuthorizedPersons />
        </div>
      </details>
    </section>
  );
}
