import { NavLink } from "react-router-dom";
import type { LinkProps } from "../../layout/VTabBarLayout";

export default function VTabBarLink({ el }: { el: LinkProps }) {
  return (
    <div className="relative group">
      <NavLink
        // key={el.id}
        className={({ isActive }) =>
          `pl-4 flex items-center gap-3 py-[10px] ${
            el.disabled && "cursor-not-allowed"
          } ${el.id === 1 && "mb-8"} ${
            isActive
              ? "bg-custom-blue-new text-white rounded-lg"
              : "text-[#C6C8D0]"
          }`
        }
        to={el.route}
        end={false}
        onClick={(e) => {
          if (el.disabled) e.preventDefault();
        }}
      >
        {el.icon}
        <span>{el.name}</span>
      </NavLink>

      {/* on hover tooltip */}
      <div
        className={`absolute max-w-[200px] z-10 top-0 ${
          el.disabled ? "-right-[120px]" : "-right-[300px]"
        } transform -translate-x-2/3 w-max p-2 bg-gray-800 text-white text-xs rounded opacity-0 invisible group-hover:visible group-hover:opacity-100 transition-opacity duration-300`}
      >
        {el.content}
        <div className="absolute left-1 top-2 -z-10 transform -translate-x-1/2 w-4 h-4 bg-gray-800 rotate-45"></div>
      </div>
    </div>
  );
}
