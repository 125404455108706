import { Link } from "react-router-dom";

import { useAppContext } from "../context/AppContext";

import UserDropdown from "./UserDropdown";
import TaramLogo from "./Icons/TaramLogo";

export const NavBar = () => {
  const { jwtToken } = useAppContext();

  return (
    <nav>
      <div className="px-4 md:px-20 py-5 container mx-auto flex justify-between items-center">
        <Link to={"/"}>
          <TaramLogo className="w-[150px] h-[43px]" />
        </Link>
        {jwtToken ? (
          <UserDropdown />
        ) : (
          <div className="flex items-center gap-5">
            <Link
              className="text-custom-blue-new font-semibold hover:underline"
              to={"/login"}
            >
              Login
            </Link>

            <Link
              className="bg-custom-blue-new text-white px-4 py-[6px] rounded-lg"
              to={"/signup"}
            >
              Get started &rarr;
            </Link>
          </div>
        )}
      </div>
    </nav>
  );
};
