import { useState } from "react";

interface SwitchProps {
  checked: boolean;
  onToggle: (checked: boolean) => void;
  ariaLabel?: string;
  className?: string;
  checkPayment: boolean;
  tagOne: string;
  tagTwo: string;
}
const Switch: React.FC<SwitchProps> = ({
  checked,
  checkPayment,
  tagOne,
  tagTwo,
  onToggle,
  ariaLabel,
  className,
}) => {
  const handleChange = () => {
    onToggle(!checked);
  };

  return (
    <div className="flex items-start gap-3">
      <h3 className={`${!checked ? "font-medium text-black text-2xl mb-5" : "font-normal text-black text-2xl mb-5"}`}>
        {tagOne}
      </h3>
      <div
        className="w-[45px] h-[27px] bg-custom-blue rounded-2xl inline-flex items-center p-1 mt-1 cursor-pointer"
        role="switch"
        aria-checked={checked}
        aria-label={ariaLabel}
        onClick={handleChange}
      >
        <div
          className={`w-5 h-5 bg-white rounded-full shadow-lg transition-transform duration-300 ${
            checked ? "translate-x-4" : "translate-x-0"
          }`}
        />
      </div>
      <p className={`${checked ? "font-medium text-black text-2xl mb-5" : "font-normal text-black text-2xl mb-5"}`}>{tagTwo}</p>
    </div>
  );
};

export default Switch;
