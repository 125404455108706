import { useEffect, useState } from "react";

// import Footer from "../components/Footer";

import { useAppContext } from "../context/AppContext";
import { full_registration } from "../constants/apis";

import { AltertBox } from "../components/AltertBox";
import checkIcon from "../assets/icons/check2.png";
import { useNavigate } from "react-router-dom";

import Spinner from "../components/Spinner";

import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  FullRegistrationFormType,
  RegistrationFormSchemaStep2,
  RegistrationFormSchemaStep3,
  FullRegistrationFormSchema,
  ApplicantDetailsSchema1,
} from "../schemas/FullRegistrationFormSchema";
import BusinessDetailsFields from "../components/BusinessInfo/BusinessDetailsFields";
import FounderDetailsFields from "../components/BusinessInfo/FounderDetailsFields";
import { toast } from "react-toastify";
import { getItemFromLocalStore } from "../utils";
import ApplicantDetailsFields from "../components/BusinessInfo/ApplicantDetailsFields";

const steps = [
  {
    id: 1,
    name: "Applicant Details",
  },
  {
    id: 2,
    name: "Company Details",
  },
  { id: 3, name: "Authorized Person" },
];

const BUSINESS_INFO = "business-info";

const FullRegistrationPage = () => {
  const navigate = useNavigate();

  const {
    jwtToken,
    refetchUser,
    user: fullRegData,
    userLoading,
  } = useAppContext();

  const [currentStep, setCurrentStep] = useState(1);
  const [successfullPopup, setSuccessfullPopup] = useState(false);

  const [persistedData] = useState(
    (getItemFromLocalStore(BUSINESS_INFO) as FullRegistrationFormType) || null
  );

  const methods = useForm<FullRegistrationFormType>({
    resolver: zodResolver(FullRegistrationFormSchema),
    mode: "onChange",
    // shouldUnregister: true,
  });
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    trigger,
    setFocus,
    reset,
    watch,
  } = methods;

  useEffect(() => {
    if (fullRegData) {
      // reset(fullRegData);

      const data = {
        ...fullRegData,
        business_documents_details: fullRegData?.business_documents_details || [
          {},
        ],
        founders: fullRegData?.founders || [
          {
            company_authorisation_documents_details: [{}],
          },
        ],
      };

      reset(data);
    }
  }, [fullRegData, reset]);

  // populate form with persist data
  // useEffect(() => {
  //   if (persistedData) {
  //     reset(persistedData);
  //   }
  // }, [persistedData, reset]);

  // watch for data change and persist
  // to localStorage
  // const watchedFormData = watch();
  // useEffect(() => {
  //   if (!fullRegData) return;

  //   const excludedKey = "middle_name";

  //   const isFullRegDataEmpty = Object.entries(fullRegData).some(
  //     ([key, val]) => key !== excludedKey && (val === "" || val === null)
  //   );

  //   if (isFullRegDataEmpty) {
  //     localStorage.setItem(BUSINESS_INFO, JSON.stringify(watchedFormData));
  //   }
  // }, [watchedFormData]);

  const nextStep = async () => {
    let fieldsToValidate: any[] = [];

    if (currentStep === 1) {
      fieldsToValidate = Object.keys(ApplicantDetailsSchema1.shape);
    } else if (currentStep === 2) {
      fieldsToValidate = Object.keys(RegistrationFormSchemaStep2.shape);
    } else if (currentStep === 3) {
      fieldsToValidate = Object.keys(RegistrationFormSchemaStep3.shape);
    }

    const valid = await trigger(fieldsToValidate);

    if (valid) {
      setCurrentStep((prev) => prev + 1);
      window.scrollTo({ top: 0 });
    } else {
      const firstErrorField = Object.keys(errors)[0];
      if (firstErrorField) {
        setFocus(firstErrorField as keyof FullRegistrationFormType);
      }
    }
  };

  console.log(errors);

  const onSubmitHandler: SubmitHandler<FullRegistrationFormType> = async (
    values
  ) => {
    // console.log(values);
    localStorage.removeItem(BUSINESS_INFO);

    try {
      const response = await fetch(full_registration, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          Content_Type: "application/json",
        },
        body: JSON.stringify(values),
      });

      if (!response.ok) {
        throw new Error(`Error:${response.status}  ${response.statusText}`);
      }
      const data = await response.json();

      // console.log("data", data);
      if (data.status === "success") {
        setSuccessfullPopup(true);

        refetchUser();
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong please try again");
    }
  };

  return (
    <section className="bg-[#EAECF0] py-8">
      <div className="max-w-[1128px] mx-auto bg-white flex gap-6 rounded-md p-8 border border-[#f3f3f5]">
        <nav className="w-[240px] p-1 flex flex-col gap-[1px] border-r border-[#EAECF0]">
          {steps.map((el) => (
            <div key={el.id}>
              <div
                className={`w-full py-2 transition-colors duration-700 ${
                  el.id === currentStep
                    ? "text-custom-blue-medium"
                    : "text-[#475467]"
                }`}
              >
                <p className="flex items-center gap-1">
                  <span
                    className={`w-8 h-8 text-xs font-semibold rounded-full inline-flex items-center justify-center border-2 ${
                      el.id === currentStep
                        ? "text-custom-blue-medium border-custom-blue-medium"
                        : "border-[#D0D5DD]"
                    }`}
                  >
                    0{el.id}
                  </span>{" "}
                  {el.name}
                </p>
              </div>
              {el.id !== steps.length && (
                <div className="w-[1px] h-12 bg-[#D0D5DD] ml-4" />
              )}
            </div>
          ))}
        </nav>

        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmitHandler)} className="w-full">
            <h2 className="font-bold text-2xl mb-4">
              {steps[currentStep - 1].name}
            </h2>

            {currentStep === 1 && <ApplicantDetailsFields />}

            {currentStep === 2 && <BusinessDetailsFields />}

            {/* SHAREHOLDER TOGGLE ON */}
            {currentStep === 3 && <FounderDetailsFields />}

            <div className="mt-20 pt-10 flex justify-between border-t border-[#D0D5DD]">
              <button
                type="button"
                disabled={isSubmitting}
                className="w-[87px] h-[44px] border border-[#D0D5DD] text-[#344054] font-medium rounded-lg"
                onClick={() => navigate("/dashboard")}
              >
                Cancel
              </button>

              <div className="flex gap-4">
                {currentStep !== 1 && (
                  <button
                    type="button"
                    disabled={isSubmitting}
                    className="h-[44px] w-[100px] px-2 text-custom-blue-medium font-medium border border-custom-blue-medium rounded-lg"
                    onClick={() => {
                      setCurrentStep((prev) => prev - 1);
                      window.scrollTo({ top: 0 });
                    }}
                  >
                    Back
                  </button>
                )}

                {currentStep !== 3 && (
                  <button
                    type="button"
                    className="w-[146px] h-[44px] rounded-md bg-custom-blue-medium text-white font-medium"
                    onClick={nextStep}
                  >
                    Next
                  </button>
                )}

                {/* Submit Button */}
                {currentStep === 3 && (
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="w-[146px] h-[44px] rounded-md bg-custom-blue-medium text-white font-medium"
                  >
                    {isSubmitting ? "submitting.." : "Save"}
                  </button>
                )}
              </div>
            </div>
          </form>
        </FormProvider>
      </div>

      {successfullPopup && (
        <AltertBox
          showIcon={true}
          borderRadius="5px"
          iconSource={checkIcon}
          buttonHeight="45px"
          // iconSize="100px"
          altIcon="icon"
          alertText="You have Successfully Registered"
          alertText1="Congratulations! You have successful completed the registration process. Our team will get back to you within 4 business days on the status."
          buttonTitle="Go To Dashboard"
          handleClick={() => navigate("/dashboard")}
        />
      )}

      {userLoading && <Spinner />}
    </section>
  );
};

export default FullRegistrationPage;
