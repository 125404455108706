import { useOutletContext } from "react-router-dom";

import { GeoNames } from "../components/SearchInput";

import Switch from "../components/Switch";
import SimpleBooking from "../components/Logistics/SimpleBooking";
import CustomBooking from "../components/Logistics/CustomBooking";

export type FormValuesType = {
  from: Omit<GeoNames, "adminCode1" | "geonameId">;
  to: Omit<GeoNames, "adminCode1" | "geonameId">;
  date: string;
  shipppingType: {
    container_size: string;
    freight_type: string;
    transportation_by: string;
  };
};

type ContextType = { isChecked: string };

const LCargoDetails = () => {
  const { isChecked } = useOutletContext<ContextType>();

  return (
    <section className="relative pl-10 pr-2">
      <div>
        {/* <div className="flex justify-end">
            <Switch
              checked={isChecked}
              onToggle={handleToggle}
              checkPayment={false}
              paymentOne={"Simple Booking"}
              paymentTwo={"Custom Booking"}
            />
          </div> */}

        {isChecked == "simpleBooking" ? <SimpleBooking /> : <CustomBooking />}
      </div>
    </section>
  );
};

export default LCargoDetails;
