import { useRef } from "react";
import { useAppContext } from "../../context/AppContext";
import {
  AmountIcon,
  ChartBreakoutCircle,
  EyeIcon,
  FeesIcon,
  IFIcon,
} from "../Icons/index";

import Modal from "../Modal";
import { InvoiceFactoringData } from "../../types/TransactionSummary";
import { TWO_PERCENT } from "../../constants/variable";
import { InvoiceShowData } from "../InvoiceShowData";

export default function InvoiceFactoringCard({
  invoiceFactoringData,
}: {
  invoiceFactoringData: InvoiceFactoringData;
}) {
  const { user } = useAppContext();
  const viewServiceModalRef = useRef<HTMLDialogElement>(null);

  const toggleModal = () => {
    viewServiceModalRef.current?.close();
  };

  const { invoice_amount, finance_term } = invoiceFactoringData;

  const fees = TWO_PERCENT * parseFloat(invoice_amount);

  return (
    <div>
      <div className="card">
        <div className="flex justify-between items-start mb-4">
          <div className="flex items-center gap-2">
            <div className="border p-1 rounded-full">
              <IFIcon className="size-8" />
            </div>
            <div>
              <p className="text-custom-dark-blue-new text-lg font-bold">
                Invoice Factoring
              </p>
              <p className="text-custom-gray text-sm">{user?.company_name}</p>
            </div>
          </div>

          <p className="px-2 py-1 text-xs font-medium rounded-2xl bg-green-50 text-green-400 border border-green-300">
            &uarr; Executed
          </p>
        </div>

        <div className=" border-2 rounded-sm border-dashed px-2 h-[64px] ">
          <div className=" flex justify-between items-center  py-2">
            <div className="flex justify-between items-center gap-4 ">
              <p className="bg-gray-100 p-2 border border-gray-300 rounded-md flex items-center gap-1">
                <AmountIcon className="size-4 " />
                <span className="text-gray-600">Amount:</span> ${invoice_amount}
              </p>

              <p className="bg-gray-100 p-2 border border-gray-300 rounded-md flex items-center gap-1 ">
                <FeesIcon className="size-4" />
                <span className="text-gray-600">Fees:</span> ${fees}
              </p>

              <p className="bg-gray-100 p-2 border border-gray-300 rounded-md flex items-center gap-1 ">
                <ChartBreakoutCircle className="size-4" />
                <span className="text-gray-600">Fees:</span> {finance_term}
              </p>

              <p className="bg-gray-100 p-2 border border-gray-300 rounded-md flex items-center gap-1 ">
                <ChartBreakoutCircle className="size-4" />
                <span className="text-gray-600">Fees:</span> {TWO_PERCENT * 100}{" "}
                % p.a.
              </p>
            </div>

            <div className="flex flex-col justify-center items-center">
              <button
                className="px-2 py-1 text-custom-blue hover:underline border border-custom-blue rounded-md  flex items-center gap-2"
                onClick={() => viewServiceModalRef.current?.showModal()}
              >
                <EyeIcon className="size-5 " />
                View Detail
              </button>
            </div>
          </div>
        </div>
      </div>

      <Modal
        ref={viewServiceModalRef}
        className="backdrop:bg-black/40 w-[600px] rounded-xl p-8"
        toggleDialog={toggleModal}
      >
        <p>content here</p>
        <InvoiceShowData data={invoiceFactoringData} />
      </Modal>
    </div>
  );
}
