export const paymentContent =
  "Perform transactions across different countries and currencies.";
export const smartEascrowContent =
  "Execute condition/document based global payments ";
export const invoiceContent = "Apply for collateral-free short-term credit";
export const logisiticsContent =
  "Book for global freight logistics, insurance & customs clearance services.";
export const qualityContent =
  "Book supplier audit, quality assurance, & loading inspection services.";
export const company_reg_number = "Enter EIN/VAT/TIN/PAN number.";
export const company_name = "Enter your company registration name.";
export const company_address = "Enter your company's incorporated address.";
export const email = "Enter your business email.";
export const account_ref_name = "Enter a reference name for your bank account.";
export const account_holder_name = "Enter the bank account holder's name.";
export const bank = "Enter the bank name.";
export const bank_address = "Enter the bank address.";
export const account_no_iban = "Enter the bank account number.";
export const siwft_ifsc = "Enter the SWIFT/IFSC code.";
export const beneficiary = "Enter the details of the payment recipient.";
export const details = "Enter the details fo the transaction.";
export const conditions = "Enter the condion-based payment terms.";
export const fund_your_transactions =
  "Request new credit or use existing credit.";
export const if_summary =
  "Review the transaction details to confirm the transaction.";
export const buyer_shipment_date =
  "Date on which the shipment is loaded at the port.";
export const buyer_finance_term = "Days until repayment of credit.";
export const yrs_of_trading_history = "Trading history with the counterparty.";
export const description_of_goods =
  "Describe in 20 words the description of goods.";
export const currency = "Currency of the contract.";
export const limit_request = "Enter your required credit.";
export const cargo_details = "input the frieght logistics booking details.";
export const logisitics_quotes =
  "Select from quote estimates for furthur action.";
export const logisitics_summary =
  "Review booking information to confirm quote request.";
export const shipping_type = "Select the mode of shipping.";
export const shipping_category =
  "Select the category of goods from the HS codes.";
export const transportation_by = "Select the type of container load.";
export const container_type = "Select the type of container & size.";
export const frieght_basis =
  "Refer the Liner Terms to select the appropriate freight basis.";
export const loading_date =
  "Enter the date that the container is to be loaded at the origin port.";
export const loading_weight = "";
export const customs_clearance =
  "Select if you require customs clearance quotes for the cargo.";
export const insurance =
  "Select if you require insurance quotes for the cargo.";
export const quality_assurance =
  "Request supplier audit, quality assurance & loading inspection services.";
export const supplier_audit_inspection = "Evaluation of supplier legitimacy.";
export const quality_assurance_inspection =
  "Evaluate quality of goods to be shipped.";
export const loading_inspection =
  "Inspect the quality of goods at container loading.";
export const incoterms = "Select from the incoterms.";
export const invoice_amount = "Value of the invoice.";
export const credit_limit = "Select from approved credit limit providers.";
export const payment_request =
  "Select amount of credit to request for the invoice amount.";
export const summary =
  "Review the transaction details to confirm the transaction.";
export const currency_account = "Select the currency of the bank account.";
export const choose_payment_mode =
  "Choose between payment modes for speed of execution. Please note that Local Payment reflects as a local payment from our partner bank";
export const trade_name =
  "Enter the Trade Name if different from the Company Name";
export const business_type = "Select business type";
export const description = "Describe in 20 words the business description";
export const registration_date =
  "Mention the date of incorporation of the business";
export const business_documents = "Submit the business incorporation documents";
export const authorised_company_document =
  "Submit business incorporation document naming authorized person.";
export const authorised_document_type =
  "Business incorporation document / Letter of authorization from company board.";
export const authorised_document_number =
  "Specify the document number if applicable.";
export const authorised_issuance_country =
  "Mention country of issuance of the document.";
export const authorised_expiry_date = "";
export const booking_person_company_document =
  "Submit business incorporation document naming authorized person.";
export const booking_person_document_type =
  "Letter of authorization from authorized person";
export const booking_person_document_number =
  "Specify the document number if applicable";
export const booking_person_issuance_country =
  "Mention country of issuance of the document.";
export const booking_person_expiry_date = "";
