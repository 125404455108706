import { useAppContext } from "../../context/AppContext";

export default function GreetUser() {
  const { user, userLoading } = useAppContext();

  return userLoading ? (
    <section className="animate-pulse space-y-3 mt-2 mb-5">
      <div className="h-6 w-[400px] bg-slate-300 rounded"></div>

      <div className="h-4 w-[200px] bg-slate-300 rounded"></div>
    </section>
  ) : (
    <section className="mt-2 mb-5">
      <h1 className="font-medium text-2xl text-gray-800">
        Welcome {user?.first_name} 👋
      </h1>
      <p className="text-gray-400">Here is Your Latest Updates</p>
    </section>
  );
}
