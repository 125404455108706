import { useNavigate } from "react-router-dom";

import { useAppContext } from "../context/AppContext";
import { SetStateAction, useRef, useState } from "react";

import { IFSummary } from "../pages/IFSummary";
import { IFDetailsSchema, IFDetailsType } from "../schemas/IFDetailsType";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import FundYourTransaction from "../components/InvoiceFactoring/FundYourTransaction";
import BuyerInformation, {
  InvoiceFactoring,
} from "../components/InvoiceFactoring/BuyerInformation";
import RequestCreditLimit, {
  BuyerFormSchema,
} from "../components/RequestCreditLimit";
import AddBuyerCompany from "../components/AddBuyerCompany";
import { usePartnersDataContext } from "../context/PartnersDataContext";
import IFPasswordAndOtpPopup from "../components/InvoiceFactoring/IFPasswordAndOtpPopup";
import {
  get_all_invoice_factoring_details,
  service_submit_mail,
} from "../constants/apis";
import { toast } from "react-toastify";
import { useFetch } from "../hooks/useFetch";

import { BuyerCompanyFormType } from "../schemas/BuyerCompanyformType";
import Initiate from "../components/InvoiceFactoring/Initiate";
import InputWithLabel from "../components/InputWithLabel";

export const InvoiceFactoringLayout = () => {
  return (
    <div className="card">
      <InvoiceFactoringForm />
    </div>
  );
};

const steps = [
  { id: 1, name: "Initiate" },
  {
    id: 2,
    name: "Fund Your Transaction",
  },
  { id: 3, name: "Summary" },
];

function InvoiceFactoringForm() {
  const [currentStep, setCurrentStep] = useState(1);
  const [values, setValues] = useState<IFDetailsType | null>(null);
  const [step, setStep] = useState(false);

  const navigate = useNavigate();

  const addNewCreditLimitRef = useRef<HTMLDialogElement>(null);
  const addBuyerCompanyDetailsRef = useRef<HTMLDialogElement>(null);
  const invoicePasswordAndOtpPopupRef = useRef<HTMLDialogElement>(null);

  const { jwtToken } = useAppContext();
  const { data: partnersData } = usePartnersDataContext();

  const { data: invoiceFactoringData, refetch: invoiceFactoringRefetch } =
    useFetch<InvoiceFactoring[]>(get_all_invoice_factoring_details, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        "Content-Type": "application/json",
      },
    });

  const openRequestNewCreditLimitModal = () => {
    addNewCreditLimitRef.current?.showModal();
  };

  const closeRequestNewCreditLimitModal = () => {
    addNewCreditLimitRef.current?.close();
  };

  const openBuyerCompanyModal = () => {
    addBuyerCompanyDetailsRef.current?.showModal();
  };

  const methods = useForm<IFDetailsType>({
    resolver: zodResolver(IFDetailsSchema),
    mode: "onChange",
  });

  const {
    handleSubmit,
    getValues,
    formState: { errors, isSubmitting },
    reset,
    trigger,
    setFocus,
  } = methods;
  console.log(errors);
  const nextStep = async () => {
    const fieldsForCurrentStep = ["company_name", "company_address"];

    if (currentStep === 1) {
      setCurrentStep((prev) => prev + 1);
      window.scrollTo({ top: 0 });

      return;
    }

    const valid = await trigger();

    if (valid) {
      setCurrentStep((prev) => prev + 1);
      window.scrollTo({ top: 0 });
    } else {
      const firstErrorField = Object.keys(errors)[0];
      if (firstErrorField) {
        setFocus(firstErrorField as keyof IFDetailsType);
      }
      toast.error("Please fix the errors before proceeding.");
    }
  };

  const onSubmitHandler: SubmitHandler<IFDetailsType> = async (values) => {
    // console.log(values);

    setValues(values);

    try {
      const res = await fetch(service_submit_mail, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...values, type: "invoice factoring" }),
      });

      if (!res.ok) {
        throw new Error("Something went wrong please try again");
      }

      const data = await res.json();
      if (data.status === "success") {
        invoicePasswordAndOtpPopupRef.current?.showModal();
      }
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
      }
    }
  };

  return (
    <div className="flex gap-4 p-4">
      <nav className="w-[300px] p-1 flex flex-col gap-[1px] border-r border-[#EAECF0]">
        {steps.map((el) => (
          <div key={el.id}>
            <div
              className={`w-full py-2 transition-colors duration-700 ${
                el.id === currentStep
                  ? "text-custom-blue-medium"
                  : "text-[#475467]"
              }`}
            >
              <p className="flex items-center gap-1">
                <span
                  className={`w-8 h-8 text-xs font-semibold rounded-full inline-flex items-center justify-center border-2 ${
                    el.id === currentStep
                      ? "text-custom-blue-medium border-custom-blue-medium"
                      : "border-[#D0D5DD]"
                  }`}
                >
                  0{el.id}
                </span>{" "}
                <span>{el.name}</span>
              </p>
            </div>
            {el.id !== steps.length && (
              <div className="w-[1px] h-12 bg-[#D0D5DD] ml-4" />
            )}
          </div>
        ))}
      </nav>
      {/* <Switch
        checked={step}
        onToggle={()=>setStep(!step)}
        checkPayment={step}
        tagOne={"Request Credit Limit"}
        tagTwo={"Fund Your Transaction"}
      /> */}
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmitHandler)} className="w-full">
          {currentStep === 1 && <Initiate />}
          {currentStep === 2 && (
            <>
              <FundYourTransaction
                step={step}
                setStep={() => setStep(!step)} // {...{
                //   openRequestNewCreditLimitModal,
                //   closeRequestNewCreditLimitModal,
                // }}
              />
              {/* <RequestCreditLimit/> */}

              {/* <BuyerInformation
                {...{
                  openBuyerCompanyModal,
                  invoiceFactoringData,
                }}
              /> */}
            </>
          )}

          {currentStep === 3 && <IFSummary />}

          <div className="mt-4 py-6 flex justify-between items-center">
            {/* {currentStep === 1 && (
              <button
                type="button"
                className="w-[87px] h-[44px] border border-[#D0D5DD] text-[#344054] font-medium rounded-lg"
                onClick={() => navigate("/dashboard")}
              >
                Cancel
              </button>
            )} */}

            {currentStep === 1 && (
              <div>
                <label className="flex items-center gap-2 text-[#475467] text-sm cursor-pointer">
                  <input type="checkbox" className="size-4" />
                  Skip this page while initiating the next invoice factoring
                  transaction
                </label>
              </div>
            )}

            {currentStep !== 1 && (
              <button
                type="button"
                // disabled={isSubmitting}
                className="w-[87px] h-[44px] border border-[#D0D5DD] text-[#344054] font-medium rounded-lg"
                onClick={() => {
                  setCurrentStep((prev) => prev - 1);
                  window.scrollTo({ top: 0 });
                }}
              >
                Back
              </button>
            )}

            <div>
              {currentStep < 2 && (
                <button
                  type="button"
                  className="w-[146px] h-[44px] rounded-md bg-custom-blue-medium text-white font-medium"
                  onClick={nextStep}
                >
                  {currentStep === 1 ? "Next" : "Save and Next"}
                </button>
              )}

              {/* Submit Button */}
              {currentStep === 2 && (
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="w-[146px] h-[44px] rounded-md bg-custom-blue-medium text-white font-medium"
                >
                  {isSubmitting ? "submitting.." : "Submit"}
                </button>
              )}
            </div>
          </div>
        </form>
      </FormProvider>

      <dialog
        ref={addNewCreditLimitRef}
        className="rounded-xl backdrop:bg-black/50"
      >
        <RequestCreditLimit />
      </dialog>

      <dialog
        ref={addBuyerCompanyDetailsRef}
        className="rounded-xl backdrop:bg-black/50"
      >
        {/* TODO: rename as edit partner company */}
        <AddBuyerCompany
          onClose={() => addBuyerCompanyDetailsRef.current?.close()}
          selectedCompany={partnersData?.find(
            (el) => el.company_name === getValues("company_name")
          )}
          reset={reset}
        />
      </dialog>

      <dialog
        ref={invoicePasswordAndOtpPopupRef}
        className="backdrop:bg-black/50 rounded-md shadow-lg"
      >
        <IFPasswordAndOtpPopup {...{ values, invoiceFactoringRefetch }} />
      </dialog>
    </div>
  );
}
