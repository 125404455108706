import { useCallback, useState } from "react";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronUpDownIcon,
  SearchIcon,
} from "../../components/Icons";
import { useFetch } from "../../hooks/useFetch";
import { admin_get_all_users } from "../../constants/apis";
import { useAppContext } from "../../context/AppContext";

import type { FullRegistrationFormType as User } from "../../schemas/FullRegistrationFormSchema";
import { TableRowSkeleton } from "../../components/Dashboard/Quotes";
import { useNavigate } from "react-router-dom";
import { debounce } from "../../utils";

export type AllUsersRes = {
  pagination: {
    current_page: number;
    total_pages: number;
    total_records: number;
    limit: number;
  };
  user_data: User[];
};

export default function CustomerProfiles() {
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();

  const { adminJWTToken } = useAppContext();

  const { data, loading } = useFetch<AllUsersRes>(
    `${admin_get_all_users}?page=${currentPage}&search=${search}`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${adminJWTToken}`,
        "Content-Type": "application/json",
      },
    },
    currentPage
  );

  const allUsersData = data?.user_data;
  const pagination = data?.pagination;

  // console.log(allUsersData, currentPage);

  const nextPage = () => setCurrentPage((prev) => prev + 1);

  const previousPage = () => {
    setCurrentPage((prev) => prev - 1);
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const getDebouncedSearch = useCallback(debounce(handleOnChange, 500), []);

  return (
    <section className="mt-10 card p-6">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-semibold mb-4 text-gray-800">
          Customer Profiles
        </h2>
        <div className="relative mb-4">
          <SearchIcon className="absolute left-3 top-3 h-5 w-5 text-gray-400" />
          <input
            type="search"
            placeholder="Search"
            className="pl-10 pr-4 py-2 border border-gray-300 rounded-lg w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
            onChange={getDebouncedSearch}
          />
        </div>
      </div>
      <hr />

      <table className="w-full text-left border-collapse">
        <thead>
          <tr className="border-b">
            <th className="py-2 px-4 font-medium">Customer</th>
            <th className="py-2 px-4 font-medium">Location</th>
            <th className="py-2 px-4 font-medium flex items-center justify-center gap-2">
              <span>Tasks</span>
              <button>
                <ChevronUpDownIcon className="size-6" />
              </button>
            </th>
            <th className="py-2 px-4 font-medium">
              <div className="flex items-center justify-center gap-2">
                <span>Messages</span>
                <button>
                  <ChevronUpDownIcon className="size-6" />
                </button>
              </div>
            </th>
          </tr>
        </thead>

        <tbody>
          {loading
            ? [1, 2, 3].map((el) => <TableRowSkeleton key={el} />)
            : allUsersData &&
              allUsersData?.map((user) => (
                <tr
                  key={user.uid}
                  className="hover:bg-gray-100 hover:cursor-pointer"
                  onClick={() => navigate(`${user.uid}`)}
                >
                  <td className="p-4 flex items-center">
                    <div className="inline-flex items-center gap-2">
                      <div className="w-10 h-10 bg-slate-300 rounded-full flex justify-center items-center font-medium">
                        {user.company_name.at(0)?.toUpperCase()}
                      </div>
                      <span>{user.company_name}</span>
                    </div>
                  </td>
                  <td className="p-4">
                    {user.registered_city}, {user.registered_country}
                  </td>
                  <td className="p-4 text-center">1</td>
                  <td className="p-4 text-center">1</td>
                </tr>
              ))}
        </tbody>
      </table>
      {!loading && !data && (
        <p className="text-center text-gray-600 mt-2">No record found</p>
      )}

      {data && (
        <div className="flex justify-between items-center mt-20 pt-4 border-t">
          <button
            onClick={previousPage}
            disabled={pagination?.current_page === 1}
            className="px-4 py-1 border-2 border-gray-200 text-[#344054] font-medium rounded-lg flex items-center gap-1 disabled:text-gray-400"
          >
            <ArrowLeftIcon className="size-4" /> Previous
          </button>
          <span>
            Page {currentPage} / {pagination?.total_pages}
          </span>
          <button
            onClick={nextPage}
            disabled={currentPage === pagination?.total_pages}
            className="px-4 py-1 border-2 border-gray-200 text-[#344054] font-medium rounded-lg flex items-center gap-1 disabled:text-gray-400"
          >
            Next <ArrowRightIcon className="size-4" />
          </button>
        </div>
      )}
    </section>
  );
}
