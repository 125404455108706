const SingaporeFlag = () => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <rect width={24} height={24} fill="url(#pattern0_5688_7620)" />
    <defs>
      <pattern
        id="pattern0_5688_7620"
        patternContentUnits="objectBoundingBox"
        width={1}
        height={1}
      >
        <use xlinkHref="#image0_5688_7620" transform="scale(0.0208333)" />
      </pattern>
      <image
        id="image0_5688_7620"
        width={48}
        height={48}
        preserveAspectRatio="none"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAABr0lEQVR4nO2Vvy9DURTHn4pIJ4nEz81mYhVKg0UkbS0MFkL8qAibEJv4kTToJLEIi4iIf8AoxlYHbYUIUabmvd5Wy9OfX3n3pWgHa++r80lOXu69b7ifc889V5IIgiAIohxAtwQjhfRvBLL2RrwfroMFfYh6LqFuTiI3WGsMgdRMJ5TQE2QlAtXlBKwmPp/rNyO5ZENuoEZcgexQM5TXEGQWg7ozX7hma0Dk4RbqxoS4AonjHb55FrgGrJV8Lj3erq9bTVww62gqnBdJIHIf4AKJo00+zoy08Kx/rg4Xnoa9EezGA9U1K5aAEg5zgQ/3oj7XW4XUnIWLFP+bmu1CZrRVLIHInb/gBPKR66v+cyyMQOJ4W78DQd/3Hcja6hE/20Ny2QH0VPCsx8/3kXJaBO1CL896Ge0u/IiduJFcGdKz32/G28Up0mNt4glA6y7THVCeH/V3QGul2juQD+2f4q9oAuBlU4f3gzUwvxdR7xXUrSnjvMQQOKSyE5BZDEYKiQQYnQCohH5T6pKQ6RKz0mdVpjbKSp9ZmR4yZoyQiin1hmQSYAYvIYIgCIKQDMgXjBXDu1V/E5kAAAAASUVORK5CYII="
      />
    </defs>
  </svg>
);
export default SingaporeFlag;
