import { z } from "zod";

export const QualityAssuranceFormSchema = z.object({
  beneficiary_id: z.number().optional(),
  company_name: z.string().trim().min(1, "Company name is required"),

  company_registration_number: z.string().min(1, "Filed is required"),
  company_address: z.string().min(1, "Filed is required"),
  address_of_inspection: z.string().min(1, "Filed is required"),
  contact_person_name: z.string().min(1, "Filed is required"),
  email: z.string().min(1, "Filed is required"),
  phone_number: z.string().min(1, "Filed is required"),

  supplier_audit: z.boolean(),
  quality_assurance: z.boolean(),
  loading_inspection: z.boolean(),
  audit_specification: z
    .string({
      message: "Enter a audit Specifications",
    })
    .min(1, "Filed is required"),
  qa_consignment_value: z
    .string({ message: "Expect a Consignment Value" })
    .trim()
    .min(1, "Filed is required"),
  no_of_containers: z
    .string({ message: "Enter a Containers quantity" })
    .trim()
    .min(1, "Enter a Containers quantity"),

  // .int({ message: "Enter a integer value" })
  loading_consignment_value: z
    .string({ message: "Enter a consignment value" })
    .trim()
    .min(1, "Enter a consignment value"),

  // .int({ message: "Enter a integer value" }),
  goods_specifications: z
    .string({ message: "Enter goods specification" })
    .trim()
    .min(1, "Enter goods specification"),
  quality_specifications_file: z.array(
    z.object({
      url: z
        .string({ message: "Quality Specifications file" })
        .min(1, "Document is required")
        .includes("https://", { message: "Please select a document" }),

      file: z.instanceof(FileList, { message: "Please select a document" }),
    })
  ),
});

export type QualityAssuranceType = z.infer<
  typeof QualityAssuranceFormSchema
> & {};
