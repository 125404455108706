import { HTMLProps, ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../context/AppContext";

interface Props extends HTMLProps<HTMLButtonElement> {
  children: ReactNode;
}

export default function LogOutBtn(props: Props) {
  const { children, className, type, ...rest } = props;
  const navigate = useNavigate();

  const { removeJwtToken } = useAppContext();

  const handleLogout = () => {
    sessionStorage.clear();
    localStorage.clear();
    removeJwtToken();
    navigate("/");
  };

  return (
    <button
      className={className}
      type="button"
      onClick={handleLogout}
      {...rest}
    >
      {children}
    </button>
  );
}
