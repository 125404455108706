import { useNavigate } from "react-router-dom";
import {
  CheckIcon,
  FeaturesIcon,
  FlightIcon,
  GlobeIcon,
  LogisticsIcon,
  PriceIcon,
  ETAIcon,
  GlobeLiveIcon,
} from "../Icons";
import IndiaFlag from "../../assets/icons/flagIcons/IndiaFlag";
import UsaFlag from "../../assets/icons/flagIcons/UsaFlag";
import BrazilFlag from "../../assets/icons/flagIcons/BrazilFlag";
import MexicoFlag from "../../assets/icons/flagIcons/MexicoFlag";
import SingaporeFlag from "../../assets/icons/flagIcons/SingaporeFlag";
import GermanyFlag from "../../assets/icons/flagIcons/GermanyFlag";
import PortugalFlag from "../../assets/icons/flagIcons/PortugalFlag";
import ChinaFlag from "../../assets/icons/flagIcons/ChinaFlag";

const FEATURES_ITEMS = [
  "Book within 5 mins",
  "Instant price estimate, & real time rates",
  "Instant Full Container Load quotes",
  "RFQ for Less Container Load Quick requests",
  "RFQ for Custom Consignment requests",
  "Coverage between 280+ ports globally",
  "Shipping type: Sea, Air, & Land",
];

const fclQuotes = [
  {
    from: "Chennai",
    fromIcon: <IndiaFlag />,
    toIcon: <UsaFlag />,
    to: "Los Angles",
    flagicon: "",
    eta: "45 days",
    price: "$2100",
  },
  {
    from: "Sao Paulo",
    fromIcon: <BrazilFlag />,
    toIcon: <UsaFlag />,
    to: "New York",
    flagicon: "",
    eta: "25 days",
    price: "$1600",
  },
  {
    from: "Manzanillo",
    fromIcon: <MexicoFlag />,
    toIcon: <UsaFlag />,
    to: "New York",
    flagicon: "",
    eta: "45 days",
    price: "$900",
  },
  {
    from: "Shanghai",
    fromIcon: <ChinaFlag />,
    toIcon: <SingaporeFlag />,
    to: "Singapore",
    flagicon: "",
    eta: "25 days",
    price: "$1000",
  },
  {
    from: "Hamburg",
    fromIcon: <GermanyFlag />,
    toIcon: <PortugalFlag />,
    to: "Lisbon",
    flagicon: "",
    eta: "45 days",
    price: "$1500",
  },
];

export default function Initiate() {
  const navigate = useNavigate();

  return (
    <section className="space-y-8 pl-4">
      <div>
        <h2 className="inline-flex items-center gap-2">
          <LogisticsIcon className="size-6" />{" "}
          <span className="text-custom-blue-medium font-semibold text-[20px]">
            Logistics
          </span>
        </h2>
        <p className="text-[#475467] text-sm">
          Taram facilitates booking of freight logistics between locations
          across the world for exporters and importers.
        </p>
      </div>

      <div className="grid grid-cols-[480px_1fr] gap-5">
        <div
          id="fcl-quotes"
          className="p-5 border border-[#F3F3F5] rounded-[10px]"
        >
          <h3 className="mb-4 font-semibold text-[18px] inline-flex items-center gap-2">
            <GlobeLiveIcon className="size-6" /> Live FCL Quotes*
          </h3>

          <table className="w-full text-[#475467] mt-2">
            <thead className="text-xs">
              <tr>
                <th className="font-medium text-start py-2 flex items-center gap-2">
                  <GlobeIcon className="size-4" /> FROM
                </th>
                <th className="font-medium text-start">
                  <div className="flex items-center gap-2">
                    <FlightIcon className="size-4" /> TO
                  </div>
                </th>
                <th className="font-medium text-start flex items-center gap-2">
                  <ETAIcon className="size-4" /> ETA
                </th>
                <th className="font-medium">
                  <div className="flex items-center justify-center gap-2">
                    <PriceIcon className="size-4" /> PRICE
                  </div>
                </th>
              </tr>
            </thead>
            <tbody className="text-xs font-medium">
              {fclQuotes.map((el, i) => (
                <tr key={i}>
                  <td className="py-2 flex items-center justify-between">
                    <div className="inline-flex items-center gap-2">
                      {el.fromIcon} <p>{el.from}</p>
                    </div>
                    <span className="pr-8 text-[22px]">&rarr;</span>
                  </td>
                  <td>
                    <span className="flex items-center gap-2">
                      {el.toIcon} <p>{el.to}</p>
                    </span>
                  </td>
                  <td>{el.eta}</td>
                  <td className="text-center">{el.price}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="p-5 border border-[#F3F3F5] rounded-[10px]">
          <h3 className="mb-4 font-semibold text-[18px] inline-flex items-center gap-2">
            <FeaturesIcon className="size-6" /> Features
          </h3>

          <div className="space-y-[10px] mt-3">
            {FEATURES_ITEMS.map((el, i) => (
              <p key={i} className="inline-flex items-center gap-2 text-sm">
                <CheckIcon className="size-4 text-custom-blue-medium" />
                <span className="text-[#475467]">{el}</span>
              </p>
            ))}
          </div>
        </div>

        <div className="col-span-full">
          <p className="text-sm space-x-1">
            <span className="text-[#DC0000]">*Disclaimer:</span>
            <span className="text-[#475467]">
              All logistics quotes shown above are for 20 ft containers and are
              subject to further approvals
            </span>
          </p>
        </div>
      </div>

      <div className="mt-4 pt-6 flex justify-between items-center border-t border-[#EAECF0]">
        <div>
          <label className="flex items-center gap-2 text-[#475467] text-sm cursor-pointer">
            <input type="checkbox" className="size-4" />
            Skip this page while initiating the next logistics transaction
          </label>
        </div>

        <button
          className="w-[146px] h-[44px] rounded-md bg-custom-blue-medium text-white font-medium"
          onClick={() => navigate("cargo-details")}
        >
          Next
        </button>
      </div>
    </section>
  );
}
