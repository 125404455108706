import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import VTabBarlayout from "./layout/VTabBarLayout";

import { SESenderBeneficiary } from "./pages/SESenderBeneficiary";
import { SEDetails } from "./pages/SEDetails";
import { SEConditions } from "./pages/SEConditions";
import { SESummary } from "./pages/SESummary";

// import { IFQuotationSelection } from "./pages/IFQuotationSelection";

import QualityAssurancePage from "./pages/QualityAssurance";
import LCargoDetails from "./pages/LCargoDetails";
import { LQuotes } from "./pages/LQuotes";
import { LSummary } from "./pages/LSummary";
// import { Dashboard } from './pages/DashboardQuotationStatus';

// import { QuotationStatus } from "./pages/QuotaionStatus";

import { InsuranceDetails } from "./pages/InsuranceDetails";
import { InsurancePolicyHolder } from "./pages/InsurancePolicyHolder";

import DashboardRequestQuote from "./pages/DashboardRequestQuote";
import DashboardReviewSubmit from "./pages/DashboardReviewSubmit";

import NewRegistrationPage from "./pages/FullRegistrationPage";
// import SimpleRegistrationPage from "./pages/SimpleRegistration";
import Dashboard from "./pages/Dashboard";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

// import { useAppContext } from "./context/AppContext";
import { SimplePaymentLayout } from "./layout/SimplePaymentLayout";
import { SmartEscrowLayout } from "./layout/SmartEscrowLayout";
import { InvoiceFactoringLayout } from "./layout/InvoiceFactoringLayout";
import { LogisticsLayout } from "./layout/LogisticsLayout";
import { QualityAssuranceLayout } from "./layout/QualityAssuranceLayout";
import { InsuranceLayout } from "./layout/InsuranceLayout";
import { LandingPage } from "./pages/LandingPage";
import ErrorPage from "./pages/ErrorPage";

import CookieService from "./context/cookies";
import VerifyEmailPage from "./pages/VerifyEmailPage";

import ScrollToTop from "./components/ScrollToTop";
import Security from "./pages/Security";
import DashboardInsights from "./pages/DashboardInsights";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import { QualityAssuranceSummary } from "./pages/QualityAssuranceSummary";
import { InsuranceSummary } from "./pages/InsuranceSummary";
import HelpAndSupport from "./pages/HelpAndSupport";
import QualityAssuranceForm from "./components/QualityAssurance/QualityAssuranceForm";

import TradingInfo from "./pages/TradingInfo";

import AdminLogin from "./pages/Admin/AdminLogin";
import AdminDashboard, { BuyerList } from "./pages/Admin/AdminDashboard";
import BuyerDetails from "./pages/Admin/BuyerDetails";
import LoginPage from "./pages/LoginPage";
import LoginSignupLayout from "./layout/LoginSignupLayout";
import SignupPage from "./pages/SignupPage";
import AdminPanelLayout from "./layout/AdminPanelLayout";

import VendorPage from "./pages/Admin/VendorPage";

import AdminTasks from "./pages/Admin/AdminTasks";
import AdminQuotes from "./pages/Admin/AdminQuotes";
import CustomerProfiles from "./pages/Admin/CustomerProfiles";
import CustomerProfileDetails from "./pages/Admin/CustomerProfileDetails";
import CustomerHistoryDetails from "./pages/Admin/CustomerHistoryDetails";
import Initiate from "./components/Logistics/Initiate";
import UploadPage from "./pages/UploadPage";
import TransactionSummary from "./pages/TransactionSummary";
import AdminQuotesDetails from "./pages/Admin/AdminQuotesDetails";

// const router = createBrowserRouter(createRoutesFromElements());

function App() {
  return (
    <Router basename="/">
      <ScrollToTop />

      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/" element={<LoginSignupLayout />}>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<SignupPage />} />
        </Route>

        <Route
          path="/"
          element={
            <ProtectedRoute element={<VTabBarlayout />} redirectTo="/" />
          }
        >
          <Route path="simple-payment" element={<SimplePaymentLayout />} />

          <Route path="smart-escrow" element={<SmartEscrowLayout />}>
            <Route index element={<Navigate to={"sender-beneficiary"} />} />

            <Route
              path="sender-beneficiary"
              element={<SESenderBeneficiary />}
            />
            <Route path="details" element={<SEDetails />} />
            <Route path="conditions" element={<SEConditions />} />
            <Route path="summary" element={<SESummary />} />
          </Route>

          <Route
            path="invoice-factoring"
            element={<InvoiceFactoringLayout />}
          />

          <Route path="logistics" element={<LogisticsLayout />}>
            {/* <Route index element={<Navigate to={"cargo-details"} />} /> */}

            <Route index element={<Initiate />} />
            <Route path="cargo-details" element={<LCargoDetails />} />
            <Route path="quotes" element={<LQuotes />} />
            <Route path="summary" element={<LSummary />} />
          </Route>

          <Route path="quality-assurance" element={<QualityAssuranceLayout />}>
            <Route index element={<QualityAssuranceForm />} />
            <Route path="summary" element={<QualityAssuranceSummary />} />
          </Route>

          <Route path="insurance" element={<InsuranceLayout />}>
            <Route index element={<Navigate to={"policy-holder"} />} />

            <Route path="policy-holder" element={<InsurancePolicyHolder />} />
            <Route path="details" element={<InsuranceDetails />} />
            <Route path="summary" element={<InsuranceSummary />} />
          </Route>

          <Route path="request-a-quote" element={<DashboardRequestQuote />} />
          <Route path="transaction-summary" element={<TransactionSummary />} />
          <Route path="review-submit" element={<DashboardReviewSubmit />} />

          <Route path="dashboard" element={<Dashboard />} />
          <Route path="dashboard/insights" element={<DashboardInsights />} />

          <Route path="security" element={<Security />} />

          <Route path="registration" element={<NewRegistrationPage />} />
          <Route path="help-and-support" element={<HelpAndSupport />} />
        </Route>

        <Route path="verify-email" element={<VerifyEmailPage />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="reset-password" element={<ResetPassword />} />

        <Route path="trading-info" element={<TradingInfo />} />
        <Route path="upload" element={<UploadPage />} />

        <Route path="admin">
          <Route path="login" element={<AdminLogin />} />
          <Route
            element={
              <ProtectedAdminRoute
                element={<AdminPanelLayout />}
                redirectTo="/admin/login"
              />
            }
          >
            <Route index path="dashboard" element={<AdminDashboard />} />
            <Route path="tasks" element={<AdminTasks />} />
            <Route path="quotes" element={<AdminQuotes />} />
            <Route path="quotes/:id" element={<AdminQuotesDetails />} />

            <Route path="vendors" element={<VendorPage />} />

            <Route path="customer-profiles">
              <Route index element={<CustomerProfiles />} />
              <Route path=":uid" element={<CustomerProfileDetails />} />
              <Route path=":uid/history" element={<CustomerHistoryDetails />} />
            </Route>

            <Route path="buyer" element={<BuyerList />} />
            <Route path="buyer/:id" element={<BuyerDetails />} />
          </Route>
        </Route>
        <Route path="*" element={<ErrorPage />} />
      </Routes>

      <ToastContainer />
    </Router>
  );
}

export default App;

function ProtectedRoute({
  element,
  redirectTo,
}: {
  element: React.ReactNode;
  redirectTo: string;
}) {
  const jwtToken = CookieService.getCookie("jwtToken");

  if (!jwtToken) {
    return <Navigate to={redirectTo} />;
  }

  return <>{element}</>;
}

function ProtectedAdminRoute({
  element,
  redirectTo,
}: {
  element: React.ReactNode;
  redirectTo: string;
}) {
  const token = CookieService.getCookie("adminToken");

  if (!token) {
    return <Navigate to={redirectTo} />;
  }

  return <>{element}</>;
}
