interface data {
  data: any;
}
export const QAShowData = ({ data }: data) => {
  return (
    <div
      className="bg-white space-y-3 py-10 pl-10"
      style={{ textAlign: "left" }}
    >
      <p>Company ID: {data?.quality_assurance_data?.cid}</p>
      <p>
        Supplier Audit:{" "}
        {data?.quality_assurance_data?.supplier_audit ? "Yes" : "No"}
      </p>
      <p>
        Quality Assurance:{" "}
        {data?.quality_assurance_data?.quality_assurance ? "Yes" : "No"}
      </p>
      <p>
        Loading Inspection:{" "}
        {data?.quality_assurance_data?.loading_inspection ? "Yes" : "No"}
      </p>
      {data?.quality_assurance_data?.supplier_audit === 1 && (
        <p>
          Audit Specification:{" "}
          {data?.quality_assurance_data?.audit_specification}
        </p>
      )}
      {data?.quality_assurance_data?.quality_assurance === 1 && (
        <div className="space-y-2">
          {data.quality_assurance_data?.quality_specifications_file.map(
            (el, index) => (
              <div key={index} className="w-[200px] flex justify-between">
                <p>Document File:{index + 1}</p>
                <a
                  href={el.url}
                  className="text-custom-blue underline"
                  target="_blank"
                >
                  View File
                </a>
              </div>
            )
          )}

          <p>
            Consignment Value:{" "}
            {data?.quality_assurance_data?.qa_consignment_value}
          </p>
        </div>
      )}
      <p></p>
      {data?.quality_assurance_data?.loading_inspection === 1 && (
        <div>
          <p>
            Loading Consignment Value:{" "}
            {data?.quality_assurance_data?.loading_consignment_value}
          </p>
          <p>
            Number of Containers: {data.quality_assurance_data.no_of_containers}
          </p>
          <p>
            Goods Specifications:{" "}
            {data.quality_assurance_data.goods_specifications}
          </p>
        </div>
      )}

      {/* <p>
    Created At:{" "}
    {data.quality_assurance_data.created_at}
  </p> */}

      <h2>Quality Supplier Data</h2>
      <p>Company Name: {data?.quality_supplier_data?.company_name}</p>
      <p>Registration Number: {data?.quality_supplier_data?.reg_no}</p>
      <p>Company Address: {data?.quality_supplier_data?.company_address}</p>
      <p>
        Contact Person Name: {data?.quality_supplier_data?.contact_person_name}
      </p>
      <p>Phone Number: {data?.quality_supplier_data?.phone_number}</p>
      <p>Email: {data?.quality_supplier_data?.email}</p>
      <p>
        Account Holder Name: {data?.quality_supplier_data?.account_holder_name}
      </p>
      <p>
        Account Reference Number:{" "}
        {data?.quality_supplier_data?.account_reference_number}
      </p>
      <p>Bank: {data?.quality_supplier_data?.bank}</p>
      <p>Bank Address: {data?.quality_supplier_data?.bank_address}</p>
      <p>Account Number: {data?.quality_supplier_data?.account_number}</p>
    </div>
  );
};
