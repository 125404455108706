import Footer from "../components/Footer";
import HowItWorks from "../components/landingPageComponents/HowItWorks";
// import ServiceSection from "../components/landingPageComponents/ServiceSection";
// import AboutSection from "../components/landingPageComponents/AboutSection";
// import { SignupForm } from "../components/landingPageComponents/LoginSignUpForm";
import ServiceDetails from "../components/landingPageComponents/serviceDetails";

import HeroSection from "../components/landingPageComponents/HeroSection";
import PartnersSection from "../components/landingPageComponents/PartnersSection";
import EToESolutionSection from "../components/landingPageComponents/EToESolutionSection";
import AIChat from "../components/landingPageComponents/AIChat";

export const LandingPage = () => {


  return (
    <>
      <HeroSection />

      <main>
        <PartnersSection />

        <EToESolutionSection />
        <HowItWorks />
        <ServiceDetails />
      </main>
      <AIChat/>
      <Footer />
    </>
  );
};
