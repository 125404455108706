import { NavBar } from "../components/NavBar";
import Footer from "../components/Footer";
import TradingInfoForm from "../components/TradingInfoForm";

const TradingInfo = () => {
  return (
    <>
      <NavBar />
      <TradingInfoForm />
      <Footer />
    </>
  );
};

export default TradingInfo;
