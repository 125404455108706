import { useNavigate, useParams } from "react-router-dom";
import {
  AmountIcon,
  EyeIcon,
  FeesIcon,
  UserIcon,
} from "../../components/Icons";
import { useAppContext } from "../../context/AppContext";
import { useFetch } from "../../hooks/useFetch";

import { admin_get_all_users } from "../../constants/apis";
import type { FullRegistrationFormType as User } from "../../schemas/FullRegistrationFormSchema";

export default function CustomerHistoryDetails() {
  return (
    <section className="grid grid-cols-2 gap-6 pt-10">
      <TransactionSummary />
      <DocumentsVault />
      <InvoiceBilling />
    </section>
  );
}

const transactions = [
  {
    title: "Payment",
    fromTo: "Manoj → Vinoj",
    fee: "$20",
    amount: "$20000",
    status: "Executed",
  },
  {
    title: "Smart Escrow",
    fromTo: "Manoj → Vinoj",
    fee: "$20",
    amount: "$20000",
    status: "Executed",
  },
  {
    title: "Invoice Factoring",
    amount: "$1,800",
    fee: "$20",
    interest: "2% p.a",
    status: "Executed",
  },
  {
    title: "Freight Logistics",
    amount: "$1,800",
    fee: "$20",
    interest: "2% p.a",
    status: "Executed",
  },
  {
    title: "Quality Assurance",
    amount: "$1,800",
    fromTo: "Vendor Due Diligence",
    status: "Approved",
  },
  {
    title: "Insurance",
    amount: "$1,800",
    value: "$200,000",
    terms: "24 Hours",
    status: "Executed",
  },
  {
    title: "Customs Clearance",
    fee: "$20",
    amount: "$20000",
    status: "Executed",
  },
];

const TransactionSummary = () => {
  const { uid } = useParams<{ uid: string }>();
  const navigate = useNavigate();

  const { adminJWTToken } = useAppContext();

  const { data, loading } = useFetch<User>(
    `${admin_get_all_users}`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${adminJWTToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uid }),
    },
    uid
  );

  return (
    <div className="card col-span-full">
      <div className="mb-6 flex justify-between items-center pb-3 border-b">
        <h2 className="text-xl font-bold text-gray-800">
          Customer Profiles -{" "}
          <span className="text-base font-medium text-gray-500">
            {data?.company_name}
          </span>{" "}
          - <span className="text-base font-medium text-gray-500">History</span>
        </h2>
        <button
          className="text-sm text-gray-600 border px-3 py-1.5 rounded-lg hover:bg-gray-100"
          onClick={() => navigate(-1)}
        >
          ← Back to History
        </button>
      </div>

      <div className="mb-4 flex gap-8">
        <p>
          <strong>Transaction ID:</strong> IN44589
        </p>
        <p>
          <strong>Client:</strong> {data?.company_name}
        </p>
        <p>
          <strong>Date:</strong> {data?.created_at}
        </p>
      </div>
      <hr />
      <div className="space-y-4">
        {transactions.map((transaction, index) => (
          <TransactionCard key={index} {...transaction} />
        ))}
      </div>
    </div>
  );
};

const TransactionCard = ({
  title,
  fromTo,
  fee,
  amount,
  interest,
  value,
  terms,
  status,
}: any) => {
  return (
    <div className="flex justify-between items-center py-4">
      <div className="flex items-center">
        <div className="w-[200px]">
          <h3 className="text-lg font-semibold text-custom-dark-blue-new">
            {title}
          </h3>
          <p className="text-sm text-gray-500">Taram</p>
        </div>

        <div className="flex items-center gap-2">
          {fromTo && (
            <p className="flex items-center gap-1 border rounded-md border-gray-300 bg-gray-50 px-3 py-1">
              <UserIcon className="size-4" />{" "}
              <span className="text-gray-500">From/To:</span>{" "}
              <span className="font-medium">{fromTo}</span>
            </p>
          )}
          {amount && (
            <p className="flex items-center gap-1 border rounded-md border-gray-300 bg-gray-50 px-3 py-1">
              <AmountIcon className="size-4" />{" "}
              <span className="text-gray-500">Amount:</span> {amount}
            </p>
          )}
          {fee && (
            <p className="flex items-center gap-1 border rounded-md border-gray-300 bg-gray-50 px-3 py-1">
              <FeesIcon className="size-4" />{" "}
              <span className="text-gray-500">Fee:</span> {fee}
            </p>
          )}
          {interest && (
            <p className="flex items-center gap-1 border rounded-md border-gray-300 bg-gray-50 px-3 py-1">
              <span className="text-gray-500">🔗 Interest:</span> {interest}
            </p>
          )}
          {value && (
            <p className="flex items-center gap-1 border rounded-md border-gray-300 bg-gray-50 px-3 py-1">
              <span className="text-gray-500">💼 Value:</span> {value}
            </p>
          )}
          {terms && (
            <p className="flex items-center gap-1 border rounded-md border-gray-300 bg-gray-50 px-3 py-1">
              <span className="text-gray-500">⏳ Terms:</span> {terms}
            </p>
          )}
        </div>
      </div>

      <div className="flex items-center gap-3">
        <button className="flex items-center gap-1 text-custom-blue-new border border-custom-blue-new px-3 py-2 rounded-lg">
          <EyeIcon className="size-5" /> View Detail
        </button>
        <span
          className={`text-sm font-medium px-3 py-1 rounded-xl ${
            status === "Approved"
              ? "bg-green-50 text-green-500 border border-green-400"
              : "bg-gray-50 text-gray-500 border border-gray-400"
          }`}
        >
          ↑ {status}
        </span>
      </div>
    </div>
  );
};

const DocumentsVault = () => {
  return (
    <div className="card text-gray-900">
      <h2 className="text-lg font-semibold">Documents Vault</h2>
      <div className="flex flex-col items-start my-3 text-gray-900">
        <button className="text-sm my-3 ">Purchase Order</button>
        <button className="text-sm my-3">Invoice</button>
        <button className="text-sm my-3">Bank Statement</button>
        <button className="text-sm my-3">Supplier Audit Document</button>
        <button className="text-sm my-3">FDA Approval Document</button>
      </div>
    </div>
  );
};
const InvoiceBilling = () => {
  return (
    <div className="card text-gray-900">
      <h2 className="text-lg font-semibold"> Invoice/Billing </h2>
      <div className="flex flex-col items-start my-3">
        <button className="text-sm my-3 ">Invoice Factoring Bill</button>
        <button className="text-sm my-3">Logistics Invoice</button>
        <button className="text-sm my-3">Quality Assurance Invoice</button>
        <button className="text-sm my-3">Insurance Invoice</button>
        <button className="text-sm my-3">Customs Clearance Invoice</button>
      </div>
    </div>
  );
};
