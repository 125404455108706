import { useFetch } from "../../hooks/useFetch";
import { useAppContext } from "../../context/AppContext";
import { useCheckAvailabilityContext } from "../../context/CheckAvailabilityContext";

import { nium_currency_exchange } from "../../constants/apis";

import InputWithLabel from "../InputWithLabel";
import Tooltip from "../Tooltip";

type ConversionRateRes = {
  exchangeRate: number;
  sourceCurrencyCode: string;
  destinationCurrencyCode: string;
};

const tooltipContent =
  "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum ipsam officiis labore, quasi dolores maxime veritatis, quaerat, minus beatae aspernatur illo aliquid animi pariatur temporibus. Reiciendis repudiandae suscipit corrupti repellendus.";

export default function ConversionRateField() {
  const { jwtToken } = useAppContext();
  const { data: checkAvailabilityData } = useCheckAvailabilityContext();

  const { data, loading } = useFetch<ConversionRateRes>(
    nium_currency_exchange,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        source_currency_code: checkAvailabilityData?.origin_iso,
        destination_currency_code: checkAvailabilityData?.destination_iso,
      }),
    },
    checkAvailabilityData?.origin_iso || checkAvailabilityData?.destination_iso
  );

  let value =
    checkAvailabilityData?.origin_iso !== checkAvailabilityData?.destination_iso
      ? `1 ${data?.sourceCurrencyCode} = ${data?.exchangeRate} ${data?.destinationCurrencyCode}`
      : "Currency is same";

  if (loading)
    return (
      <div className="animate-pulse w-full space-y-1">
        <div className="rounded-lg bg-slate-300 h-3 w-[50%]"></div>
        <div className="rounded-lg bg-slate-300 h-11"></div>
      </div>
    );

  return (
    <InputWithLabel
      label="Conversion Rate"
      element={<Tooltip content={tooltipContent} />}
      placeholder="1 USD = 0.98 EURO"
      defaultValue={value}
      disabled
    />
  );
}
