import { useState } from "react";
import { ArrowLeftIcon, ArrowRightIcon } from "../Icons";

const CustomerHistory = () => {
  const [currentPage, setCurrentPage] = useState(1);

  return (
    <section className="card">
      <h2 className="text-[#030303] font-semibold text-xl mb-2 pb-3 border-b border-gray-200">
        History
      </h2>
      <table className="w-full">
        <thead>
          <tr className="border-b">
            <th className="font-normal text-[#475467] py-3">Transaction ID</th>
            <th className="font-normal text-[#475467] px-4 py-3">Client</th>
            <th className="font-normal text-[#475467] px-4 py-3">Date</th>
          </tr>
        </thead>
        <tbody>
          {[1, 2, 3].map((el) => (
            <tr key={el}>
              <td className="flex justify-center items-center gap-2 py-4">
                {el}
              </td>
              <td className="text-center">{"Company Name"}</td>
              <td className="text-center">{"20,Febraury 2025"}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="flex justify-between items-center mt-20">
        <button
          disabled={currentPage === 1}
          className="px-4 py-1 border-2 border-gray-200 text-[#344054] font-medium rounded-lg flex items-center gap-1 disabled:text-gray-400"
        >
          <ArrowLeftIcon className="size-4" />
          Previous
        </button>

        <div className="flex items-center space-x-2">
          {[1, 2, 3, "...", 8, 9, 10].map((page, index) => (
            <button
              key={index}
              // onClick={() => typeof page === "number" && handlePageChange(page)}
              className={`px-3 py-1 rounded-lg text-sm font-medium transition-all ${
                currentPage === page
                  ? "bg-blue-500 text-white"
                  : "text-gray-600 hover:bg-gray-200"
              }`}
            >
              {page}
            </button>
          ))}
        </div>

        <button className="px-4 py-1 border-2 border-gray-200 text-[#344054] font-medium rounded-lg flex items-center gap-1 disabled:text-gray-400">
          Next <ArrowRightIcon className="size-4" />
        </button>
      </div>
    </section>
  );
};
export default CustomerHistory;
