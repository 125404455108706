import { useAppContext } from "../context/AppContext";

import {
  add_beneficary_details,
  get_constants,
  update_beneficary_details,
} from "../constants/apis";
import { toast } from "react-toastify";
import CrossIcon from "./Icons/CrossIcon";

import InputWithLabel from "./InputWithLabel";
import {
  Control,
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  SPAddBeneficiaryFormSchema,
  SPAddBeneficiaryFormType,
} from "../schemas/SPSenderBeneficiaryType";
import { useEffect, useState } from "react";
import { capitalizeFirstLetter, getItemFromLocalStore } from "../utils";
import {
  Company as PartnerCompany,
  usePartnersDataContext,
} from "../context/PartnersDataContext";
import Tooltip from "./Tooltip";
import {
  account_holder_name,
  account_no_iban,
  account_ref_name,
  company_address,
  company_name,
  company_reg_number,
  currency,
  email,
  siwft_ifsc,
} from "../constants/tooltipContents";
import Dropdown from "./DropDown";
import { businessDetails, businessRegion } from "../constants/variable";
import { useFetch } from "../hooks/useFetch";

type AddNewBeneficiaryProps = {
  onClose: () => void;
  formType: "add" | "update" | undefined;
  selectedCompany: PartnerCompany | undefined;
};

const initialBeneficiaryData = {
  company_name: "",
  company_address: "",
  company_registration_number: "",
  contact_person_name: "",
  email: "",
  phone_number: "",
  limit_request: 0,
  payment_terms: "",
};

const initialBeneficiaryAccountData = {
  account_holder_name: "",
  account_no: "",
  account_ref_name: "",
  bank: "",
  bank_address: "",
  currency: "",
  swift_ifsc: "",
  beneficiaryAccountType: "",
  bank_city: "",
  beneficiaryCountryCode: "",
  payoutMethod: "",
};

const BENEFICIARY_FORMDATA = "beneficiaryFormData";

const ACCOUNT_TYPE = ["Corporate", "Individual"];
const PAYOUT_METHOD = ["LOCAL", "SWIFT", "WALLET", "CARD", "PROXY"];

const AddNewBeneficiary: React.FC<AddNewBeneficiaryProps> = ({
  onClose,
  formType,
  selectedCompany,
}) => {
  const [persistedData] = useState(
    (getItemFromLocalStore(BENEFICIARY_FORMDATA) as SPAddBeneficiaryFormType) ||
      null
  );
  const { jwtToken } = useAppContext();
  const { refetch } = usePartnersDataContext();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    getValues,
    reset,
  } = useForm<SPAddBeneficiaryFormType>({
    resolver: zodResolver(SPAddBeneficiaryFormSchema),
    values:
      formType === "update"
        ? selectedCompany
        : {
            ...initialBeneficiaryData,
            account_details: [initialBeneficiaryAccountData],
          },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "account_details",
  });

  useEffect(() => {
    if (persistedData) {
      reset(persistedData);
    }
  }, [persistedData, reset]);

  // Save form data to localStorage on change
  const saveAsDraft = () => {
    if (!selectedCompany) {
      const data = getValues();

      localStorage.setItem(BENEFICIARY_FORMDATA, JSON.stringify(data));

      const isBeneficiaryCompanyEmpty = Object.values(data).every(
        (val) => val === ""
      );
      const isBeneficiaryAccountsEmpty = data.account_details.every((account) =>
        Object.values(account).every((val) => val === "")
      );

      if (isBeneficiaryCompanyEmpty && isBeneficiaryAccountsEmpty) {
        toast.info("Beneficiary details form incomplete!");
      } else {
        toast.success("Beneficiary details save as draft");
      }
    }
  };

  const onSubmitHandler: SubmitHandler<SPAddBeneficiaryFormType> = async (
    values
  ) => {
    // console.log(values);

    localStorage.removeItem(BENEFICIARY_FORMDATA);

    const url =
      formType === "update"
        ? update_beneficary_details
        : add_beneficary_details;

    try {
      const response = await fetch(url, {
        method: "POST",

        headers: {
          Authorization: `Bearer ${jwtToken}`,
          "content-type": "application/json",
        },
        body: JSON.stringify(formType === "update" ? values : [values]),
      });
      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }
      const data = await response.json();

      if (data.status === "success") {
        toast.success("Beneficiary Account Updated Succesfully");

        refetch();

        onClose();
      }
    } catch (error) {
      console.log(error);
    }
  };

  // console.log(errors);

  return (
    <div className="w-[50vw] p-8">
      <div className="flex justify-between mb-5">
        <h1 className="text-xl font-bold">
          {capitalizeFirstLetter(formType!)} Beneficiary Company Details
        </h1>
        <button
          type="button"
          onClick={() => {
            // saveAsDraft();
            onClose();
          }}
          className="hover:scale-90"
        >
          <CrossIcon className="size-8" />
        </button>
      </div>

      <form className="space-y-3" onSubmit={handleSubmit(onSubmitHandler)}>
        <div className="flex items-center gap-4">
          <InputWithLabel
            label={"Company Name *"}
            type="text"
            element={<Tooltip content={company_name} />}
            error={errors.company_name?.message}
            {...register("company_name")}
          />
          <InputWithLabel
            label={"Company Address *"}
            type="text"
            element={<Tooltip content={company_address} />}
            error={errors.company_address?.message}
            {...register("company_address")}
          />
        </div>
        <div className="flex items-center gap-4">
          <InputWithLabel
            label={"Company Registration Number *"}
            element={<Tooltip content={company_reg_number} />}
            type="text"
            error={errors.company_registration_number?.message}
            {...register("company_registration_number")}
          />
          <InputWithLabel
            label={"Contact Person Name *"}
            type="text"
            error={errors.contact_person_name?.message}
            {...register("contact_person_name")}
          />
        </div>
        <div className="flex items-center gap-4">
          <InputWithLabel
            label={"Email Address*"}
            element={<Tooltip content={email} />}
            type="text"
            error={errors.email?.message}
            {...register("email")}
          />
          <InputWithLabel
            label={"Phone Number *"}
            type="text"
            error={errors.phone_number?.message}
            {...register("phone_number")}
          />
        </div>

        {fields?.map((item, index) => (
          <div key={item.id}>
            <hr className="border-t-custom-blue-medium" />
            <h3 className="mt-2 text-gray-800 font-medium">
              Beneficiary Account - {index + 1}
            </h3>
            <div className="py-4 space-y-3">
              <div className="flex items-center gap-4">
                <InputWithLabel
                  element={<Tooltip content={account_ref_name} />}
                  label={`Account Reference Name *`}
                  type="text"
                  error={
                    errors.account_details?.[index]?.account_ref_name?.message
                  }
                  {...register(`account_details.${index}.account_ref_name`)}
                />

                <InputWithLabel
                  label={`Account Holder Name *`}
                  element={<Tooltip content={account_holder_name} />}
                  type="text"
                  error={
                    errors.account_details?.[index]?.account_holder_name
                      ?.message
                  }
                  {...register(`account_details.${index}.account_holder_name`)}
                />
              </div>

              <div className="flex items-center gap-4">
                <Dropdown
                  label="Account Type *"
                  placeholder="Select a account type"
                  options={ACCOUNT_TYPE.map((el) => ({ value: el, label: el }))}
                  error={
                    errors.account_details?.[index]?.beneficiaryAccountType
                      ?.message
                  }
                  {...register(
                    `account_details.${index}.beneficiaryAccountType`
                  )}
                />

                <Dropdown
                  label="Payout Method *"
                  placeholder="Select a payout method"
                  options={PAYOUT_METHOD.map((el) => ({
                    value: el,
                    label: el,
                  }))}
                  error={errors.account_details?.[index]?.payoutMethod?.message}
                  {...register(`account_details.${index}.payoutMethod`)}
                />
              </div>

              <InputWithLabel
                label={`Bank Name *`}
                type="text"
                error={errors.account_details?.[index]?.bank?.message}
                {...register(`account_details.${index}.bank`)}
              />

              <InputWithLabel
                label={`Bank Address *`}
                type="text"
                error={errors.account_details?.[index]?.bank_address?.message}
                {...register(`account_details.${index}.bank_address`)}
              />

              <div className="flex items-center gap-4">
                <InputWithLabel
                  label={`Bank City *`}
                  type="text"
                  error={errors.account_details?.[index]?.bank_city?.message}
                  {...register(`account_details.${index}.bank_city`)}
                />

                <CountryDropdown
                  label="Bank Country"
                  placeholder="Select a country"
                  category={businessDetails.COUNTRY_NAME}
                  region={businessRegion}
                  name={`account_details.${index}.beneficiaryCountryCode`}
                  control={control}
                  error={
                    errors.account_details?.[index]?.beneficiaryCountryCode
                      ?.message
                  }
                />
              </div>

              <div className="flex items-center gap-4">
                <InputWithLabel
                  label="Account No. / IBAN *"
                  element={<Tooltip content={account_no_iban} />}
                  type="text"
                  placeholder="xxxx-xxxx-8796"
                  error={errors.account_details?.[index]?.account_no?.message}
                  {...register(`account_details.${index}.account_no`)}
                />

                <InputWithLabel
                  label="SWIFT / IFSC *"
                  element={<Tooltip content={siwft_ifsc} />}
                  type="text"
                  error={errors.account_details?.[index]?.swift_ifsc?.message}
                  {...register(`account_details.${index}.swift_ifsc`)}
                />
              </div>

              <InputWithLabel
                label="Currency *"
                element={<Tooltip content={currency} />}
                type="text"
                error={errors.account_details?.[index]?.currency?.message}
                {...register(`account_details.${index}.currency`)}
              />
            </div>

            <div className="flex justify-end items-center">
              {index !== 0 && (
                // <button
                //   className="text-red-600 mt-2"
                //   onClick={() => remove(index)}
                // >
                //   Remove
                // </button>
                <div className="flex justify-end">
                  <button
                    className="w-[150px] h-[44px] text-white rounded-md bg-red-600 my-2"
                    onClick={() => remove(index)}
                  >
                    Remove
                  </button>
                </div>
              )}
            </div>
          </div>
        ))}

        <button
          type="button"
          className="h-[44px] px-2 text-custom-blue-medium font-semibold border border-custom-blue-medium rounded-lg"
          onClick={() => append(initialBeneficiaryAccountData)}
        >
          Add a New Bank Account
        </button>

        <div className="pt-4 flex justify-end gap-2">
          <button
            type="button"
            onClick={() => {
              // saveAsDraft();
              onClose();
            }}
            className="w-[87px] h-11 text-custom-blue-medium font-medium border border-custom-blue-medium rounded-lg"
          >
            Cancel
          </button>

          <button
            disabled={isSubmitting}
            className="w-28 h-11 bg-custom-blue-medium text-white font-medium rounded-lg"
          >
            {isSubmitting ? "submitting.." : "Save"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddNewBeneficiary;

function CountryDropdown({
  label,
  placeholder,
  name,
  category,
  region,
  error,
  control,
}: {
  label: string;
  placeholder: string;
  name: string;
  category: string;
  region: string;
  error?: string;
  control: Control<SPAddBeneficiaryFormType>;
}) {
  const { jwtToken } = useAppContext();

  const { data, loading } = useFetch<{ code: string; description: string }[]>(
    get_constants,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ category, region }),
    },
    "",
    category
  );

  if (loading)
    return (
      <div className="animate-pulse w-full">
        <div className="rounded-lg bg-slate-300 h-10"></div>
      </div>
    );

  return (
    <Controller
      control={control}
      name={name as keyof SPAddBeneficiaryFormType}
      render={({ field }) => (
        <Dropdown
          label={label}
          placeholder={placeholder}
          options={
            data?.map((el) => ({
              label: el.description,
              value: el.code,
            })) || []
          }
          {...field}
          error={error ? error : ""}
        />
      )}
    />
  );
}
