import { useRef } from "react";
import { useAppContext } from "../../context/AppContext";

import Modal from "../Modal";
import {
  AmountIcon,
  ETAIcon,
  EyeIcon,
  LogisticsIcon,
  MapPinIcon,
} from "../Icons";
import { LogisticsData } from "../../types/TransactionSummary";

export default function LogisticCard({
  logisticsData,
}: {
  logisticsData: LogisticsData;
}) {
  const { user } = useAppContext();
  const viewServiceModalRef = useRef<HTMLDialogElement>(null);

  const toggleModal = () => {
    viewServiceModalRef.current?.close();
  };

  const { freights } = logisticsData;

  return (
    <div>
      <div className="card">
        <div className="flex justify-between items-start mb-4">
          <div className="flex items-center gap-2">
            <div className="border p-1 rounded-full">
              <LogisticsIcon className="size-8" />
            </div>
            <div>
              <p className="text-custom-dark-blue-new text-lg font-bold">
                Logistic
              </p>
              <p className="text-custom-gray text-sm">{user?.company_name}</p>
            </div>
          </div>

          <p className="px-2 py-1 text-xs font-medium rounded-2xl bg-green-50 text-green-400 border border-green-300">
            &uarr; Executed
          </p>
        </div>

        <div className=" border-2 rounded-sm border-dashed px-2 h-[64px] ">
          <div className=" flex justify-between items-center  py-2">
            <div className="flex justify-between items-center gap-4 ">
              <p className="bg-gray-100 p-2 border border-gray-300 rounded-md flex items-center gap-1">
                <MapPinIcon className="size-4" />
                <span className="text-gray-600  ">From:</span>{" "}
                {freights.at(0)?.from_city}
              </p>

              <p className="bg-gray-100 p-2 border border-gray-300 rounded-md flex items-center gap-1">
                <MapPinIcon className="size-4" />
                <span className="text-gray-600">To:</span>{" "}
                {freights.at(0)?.to_city}
              </p>

              <p className="bg-gray-100 p-2 border border-gray-300 rounded-md flex items-center gap-1">
                <AmountIcon className="size-4 " />
                <span className="text-gray-600">Cost:</span> $
                {freights.at(0)?.cost}
              </p>

              <p className="bg-gray-100 p-2 flex items-center gap-1 border border-gray-300 rounded-md">
                <ETAIcon className="size-4" />
                <span className="text-gray-600">ETA:</span>{" "}
                {freights.at(0)?.days}
              </p>
            </div>

            <div className="flex flex-col justify-center items-center">
              <button
                className="px-2 py-1 text-custom-blue hover:underline border border-custom-blue rounded-md  flex items-center gap-2"
                onClick={() => viewServiceModalRef.current?.showModal()}
              >
                <EyeIcon className="size-5 " />
                View Detail
              </button>
            </div>
          </div>
        </div>
      </div>

      <Modal
        ref={viewServiceModalRef}
        className="backdrop:bg-black/40 w-[600px] rounded-xl p-8"
        toggleDialog={toggleModal}
      >
        <div className="my-2 pl-10 leading-8">
          {/* {JSON.stringify(quote)} */}
          <h3 className="text-custom-blue font-medium">Logistics details</h3>
          <p>Company Name: {logisticsData?.freights.at(0)?.name}</p>
          <p>From: {logisticsData?.freights.at(0)?.from_city}</p>
          <p>To: {logisticsData?.freights.at(0)?.to_city}</p>
          <p>ETA: {logisticsData?.freights.at(0)?.days} days</p>
          <p>Price: ${logisticsData?.freights.at(0)?.cost}</p>

          {/* <p>Rate Breakdown:</p> */}
          {/* <p>From City: {logisticsData?.from_city}</p> */}

          <p>
            Departure Location:{" "}
            {logisticsData?.freights.at(0)?.route_info?.portFrom.name},{" "}
            {logisticsData?.freights.at(0)?.route_info?.portFrom.countryCode}
          </p>

          <p>
            Arrival Location:{" "}
            {logisticsData?.freights.at(0)?.route_info?.portTo.name},{" "}
            {logisticsData?.freights.at(0)?.route_info?.portTo.countryCode}
          </p>

          {/* <p>To City: {logisticsData?.to_city}</p> */}
        </div>
      </Modal>
    </div>
  );
}
