import Dropdown from "../DropDown";
import {
  CheckIcon,
  FeaturesIcon,
  IFIcon,
  PricingIcon,
  StepsIcon,
} from "../Icons";
import InputWithLabel from "../InputWithLabel";

const NO_OF_DAYS = [
  {
    label: "30",
    value: "30",
  },
  {
    label: "60",
    value: "60",
  },
  {
    label: "90",
    value: "90",
  },
  {
    label: "120",
    value: "120",
  },
];

const FEATURES_ITEMS = [
  "Flexible Financing Options up to 120 days",
  "Competitive Interest Rates of 10 -14% p.a.",
  "Pre-approved limits, ready to deploy",
  "Invoice based collateral-free credit",
  "Upfront payout up to 85% of invoice",
  "Quick 48 hrs to fund the transaction",
];

const HOW_IT_WORKS_STEPS = [
  {
    id: 1,
    title: "Credit Limit Application",
    description:
      "Supplier fills the credit limit application with relevant information & documents.",
  },
  {
    id: 2,
    title: "Onboarding Process",
    description:
      "Credit due diligence of buyer & supplier using financial documents for credit limit approval",
  },
  {
    id: 3,
    title: "Invoice Issuance",
    description: "Supplier invoices Buyer",
  },
  {
    id: 4,
    title: "Funding Supplier",
    description:
      "Upon verification, credit partner funds advance amount to the supplier.",
  },
  {
    id: 5,
    title: "Invoice Maturity",
    description:
      "Upon maturity of invoice, buyer remits funds to the credit partner.",
  },
  {
    id: 6,
    title: "Residual Payment",
    description:
      "Credit partner credits the remaining amount minus fees and charges to supplier.",
  },
];

export default function Initiate() {
  return (
    <section className="space-y-8">
      <div>
        <h2 className="inline-flex items-center gap-2">
          <IFIcon className="size-6" />{" "}
          <span className="text-custom-blue-medium font-semibold text-[20px]">
            Invoice Factoring
          </span>
        </h2>
        <p className="text-[#475467] text-sm">
          Taram facilitates access to collateral-free and affordable trade
          finance credit, for exporters and importers.
        </p>
      </div>

      <div className="grid grid-cols-[440px_1fr] gap-5">
        <div className="p-5 border border-[#F3F3F5] rounded-[10px]">
          <h3 className="mb-4 font-semibold text-[18px] inline-flex items-center gap-2">
            <PricingIcon className="size-6" /> Pricing Estimate*
          </h3>

          <div className="space-y-2">
            <div className="flex gap-4">
              <InputWithLabel
                id="invoice-amount"
                label="Amount ($ millions)"
                placeholder="Enter Here ..."
              />
              <Dropdown
                id="invoice-days"
                label="No. of days"
                options={NO_OF_DAYS}
              />
            </div>

            <InputWithLabel
              id="invoice-turnover"
              label="Yearly Turnover ($ millions)"
              placeholder="Enter Here ..."
            />

            <button
              id="invoice-calculate"
              type="button"
              className="px-3 py-[6px] w-full rounded-md bg-custom-blue-medium text-white font-medium"
            >
              Calculate
            </button>

            <p className="text-sm text-[#758599] space-x-1">
              <span className="text-[#475467]">Results:</span>
              <span>Estimated Interest Rate (% p.a.)</span>
              <span className="text-green-500 font-bold">12.4 %</span>
            </p>
          </div>
        </div>

        <div className="p-5 border border-[#F3F3F5] rounded-[10px]">
          <h3 className="mb-4 font-semibold text-[18px] inline-flex items-center gap-2">
            <FeaturesIcon className="size-6" /> Features
          </h3>

          <div className="space-y-[10px] mt-3">
            {FEATURES_ITEMS.map((el, i) => (
              <p key={i} className="inline-flex items-center gap-2 text-sm">
                <CheckIcon className="size-4 text-custom-blue-medium" />
                <span className="text-[#475467]">{el}</span>
              </p>
            ))}
          </div>
        </div>

        <div className="col-span-full p-5 border border-[#F3F3F5] rounded-[10px]">
          <h3 className="mb-10 font-semibold text-[18px] inline-flex items-center gap-2">
            <StepsIcon className="size-6" />
            How it works:
          </h3>

          <div className="grid grid-cols-6 gap-[5px] text-center">
            {HOW_IT_WORKS_STEPS.map((el) => (
              <div
                key={el.id}
                className={`relative ${
                  el.id % 2 === 0 ? "bg-[#F3F8FF]" : "bg-[#F3F3F3]"
                } py-6 px-2 rounded-lg`}
              >
                <div
                  className={`absolute -top-7 inset-0 mx-auto w-12 h-12 flex items-center justify-center ${
                    el.id % 2 === 0 ? "bg-[#F3F8FF]" : "bg-[#F3F3F3]"
                  } rounded-full border-4 border-white`}
                >
                  <p className="text-[18px] font-bold text-custom-blue-medium">
                    0{el.id}
                  </p>
                </div>

                <h4 className="text-[#475467] text-sm font-semibold mb-2">
                  {el.title}
                </h4>
                <p className="text-[10px] font-medium text-[#758599]">
                  {el.description}
                </p>
              </div>
            ))}
          </div>
        </div>

        <div className="col-span-full">
          <p className="text-sm space-x-1">
            <span className="text-[#DC0000]">*Disclaimer:</span>
            <span className="text-[#475467]">
              All offers of financing are subject to credit and compliance
              approvals
            </span>
          </p>
        </div>
      </div>
    </section>
  );
}
