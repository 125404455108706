import { useForm, SubmitHandler } from "react-hook-form";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { zodResolver } from "@hookform/resolvers/zod";

import { add_trading_info } from "../constants/apis";

import InputWithLabel from "../components/InputWithLabel";
import Dropdown from "../components/DropDown";
import { CURRENCY_OPTIONS } from "../constants/variable";
import {
  TradingInfoType,
  TradingInfoSchema,
} from "../schemas/TradeInfoFormSchema";

export default function TradingInfoForm() {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<TradingInfoType>({
    resolver: zodResolver(TradingInfoSchema),
  });

  const onSubmit: SubmitHandler<TradingInfoType> = async (values) => {
    console.log(values);
    try {
      const res = await fetch(add_trading_info, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      });

      if (!res.ok) {
        throw new Error("Something went wrong please try again");
      }

      const data = await res.json();
      console.log(data);
      if (data.status === "success") {
        toast.success(data?.message);
        reset();
      }
    } catch (error) {
      console.log(error);
      if (error instanceof Error) {
        toast.error(error.message);
      }
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white shadow-xl rounded-lg my-10">
      <h2 className="text-3xl font-semibold text-gray-800 mb-6 text-center">
        Trading Information Form
      </h2>

      <form
        className="grid grid-cols-1 md:grid-cols-2 gap-3"
        onSubmit={handleSubmit(onSubmit)}
      >
        <h3 className="col-span-full text-xl font-semibold text-gray-800">
          Buyer’s Details
        </h3>

        <InputWithLabel
          label="Buyer’s Legal Name"
          placeholder="Enter here"
          id="buyerLegalName"
          {...register("buyer_name")}
          error={errors.buyer_name?.message}
        />

        <InputWithLabel
          label="Buyer’s GST/Registration Number"
          placeholder="Enter here"
          id="buyerGST"
          {...register("buyer_reg_no")}
          error={errors.buyer_reg_no?.message}
        />
        <div className="col-span-full">
          <label
            htmlFor="beneficiaryBankAddress"
            className="text-[#344054] text-sm font-medium"
          >
            Buyer’s Registered Address
          </label>
          <textarea
            {...register("buyer_address")}
            className="input-new w-full"
          ></textarea>
          <small className="text-red-500 h-[10px] block ml-1">
            {errors && errors.buyer_address?.message}
          </small>
        </div>

        <hr className="col-span-full" />

        <h3 className="col-span-full text-xl font-semibold text-gray-800">
          Supplier’s Details
        </h3>

        <InputWithLabel
          label="Supplier’s Legal Name"
          placeholder="Enter here"
          id="supplierLegalName"
          {...register("supplier_name")}
          error={errors.supplier_name?.message}
        />

        <InputWithLabel
          label="Supplier’s GST/Registration Number"
          placeholder="Enter here"
          id="supplierGST"
          {...register("supplier_reg_no")}
          error={errors.supplier_reg_no?.message}
        />

        <div className="col-span-full">
          <label
            htmlFor="beneficiaryBankAddress"
            className="text-[#344054] text-sm font-medium"
          >
            Supplier’s Registered Address
          </label>
          <textarea
            {...register("supplier_address")}
            className="input-new w-full"
          ></textarea>
          <small className="text-red-500 h-[10px] block ml-1">
            {errors && errors.supplier_address?.message}
          </small>
        </div>

        <hr className="col-span-full" />

        <h3 className="col-span-full text-xl font-semibold text-gray-800">
          Trade Details
        </h3>

        <InputWithLabel
          label="Year of Trading History (in years)"
          placeholder="Enter here"
          id="tradingHistoryYears"
          {...register("trading_history")}
          error={errors.trading_history?.message}
        />
        <InputWithLabel
          label="Trading History with Specific Buyer (years)"
          placeholder="Enter here"
          id="specificTradingHistory"
          {...register("buyer_trading_history")}
          error={errors.buyer_trading_history?.message}
        />
        <InputWithLabel
          label="Trading History with Specific Seller (years)"
          placeholder="Enter here"
          id="specificTradingHistory"
          {...register("supplier_trading_history")}
          error={errors.supplier_trading_history?.message}
        />

        <InputWithLabel
          label="Annual Turnover (Previous Year)"
          placeholder="Enter here"
          id="annualTurnoverPrevious"
          {...register("turnover_previous_year")}
          error={errors.turnover_previous_year?.message}
        />
        <InputWithLabel
          label="Expected Turnover (Current Year)"
          placeholder="Enter here"
          id="expectedTurnover"
          {...register("turnover_current_year")}
          error={errors.turnover_current_year?.message}
        />

        <InputWithLabel
          label="Underlying Goods"
          placeholder="Enter here"
          id="underlyingGoods"
          {...register("underlying_goods")}
          error={errors.underlying_goods?.message}
        />
        <InputWithLabel
          label="Transaction Tenor/Time (Days/Months)"
          placeholder="Enter here"
          id="transactionTenor"
          {...register("transaction_tenor")}
          error={errors.transaction_tenor?.message}
        />

        <Dropdown
          label="Currency"
          options={CURRENCY_OPTIONS}
          placeholder="Enter here"
          {...register("currency")}
          error={errors.currency?.message}
        />

        <InputWithLabel
          label="Credit Limit Required (Per Annum)"
          placeholder="Enter here"
          id="creditLimit"
          {...register("limit_amount")}
          error={errors.limit_amount?.message}
        />
        <InputWithLabel
          label="Incoterms (CIF/FOB/C&F etc.)"
          placeholder="Enter here"
          id="incoterms"
          {...register("incoterms")}
          error={errors.incoterms?.message}
        />
        <InputWithLabel
          label="Advance Ratio"
          placeholder="Enter here"
          id="advanceRatio"
          {...register("advance_ratio")}
          error={errors.advance_ratio?.message}
        />

        <InputWithLabel
          label="Payment Terms"
          placeholder="Enter here"
          id="advanceRatio"
          {...register("payment_terms")}
          error={errors.payment_terms?.message}
        />

        <InputWithLabel
          label="Next Expected Shipment Date"
          type="date"
          placeholder="Enter here"
          id="shipmentDate"
          {...register("expected_shipment_date")}
          error={errors.expected_shipment_date?.message}
        />

        <InputWithLabel
          label="Next Expected Shipment Value"
          placeholder="Enter here"
          id="shipmentDate"
          {...register("expected_shipment_value")}
          error={errors.expected_shipment_value?.message}
        />

        <hr className="col-span-full" />

        <h3 className="col-span-full text-xl font-semibold text-gray-800">
          Contact Details
        </h3>

        <InputWithLabel
          label="Contact Person Name"
          placeholder="Enter here"
          id="contactName"
          {...register("contact_person_name")}
          error={errors.contact_person_name?.message}
        />
        <InputWithLabel
          label="Contact Person Email"
          placeholder="Enter here"
          id="contactEmail"
          {...register("contact_persion_email")}
          error={errors.contact_persion_email?.message}
        />
        <InputWithLabel
          label="Contact Person Mobile (Optional)"
          placeholder="Enter here"
          id="contactMobile"
          {...register("contact_persion_phone")}
          error={errors.contact_persion_phone?.message}
        />

        <hr className="col-span-full" />

        <div className="mt-4 flex justify-between col-span-full">
          <Link
            className="w-[87px] h-[44px] border border-[#D0D5DD] text-[#344054] font-medium rounded-lg flex items-center justify-center"
            to={"/"}
          >
            Cancel
          </Link>

          <button
            type="submit"
            disabled={isSubmitting}
            className="w-[146px] h-[44px] rounded-md bg-custom-blue-medium text-white font-medium"
          >
            {isSubmitting ? "submitting.." : "Submit"}
          </button>
        </div>
      </form>
    </div>
  );
}
