import { Link } from "react-router-dom";

import taramLogo from "../../assets/icons/taramTextLogo.png";

import { HelpCircleIcon, LogOutIcon } from "../../components/Icons";
import LogOutBtn from "../../components/LogOutBtn";
import VTabBarLink from "./VTabBarLink";
import Tour from "./Tour";

import { RoutesType } from "../../layout/VTabBarLayout";

export default function SideNavbar({ routes }: { routes: RoutesType }) {
  return (
    <aside className="w-[264px] h-full fixed top-0 z-50 bg-custom-dark-blue-new px-4 py-8 flex flex-col justify-between">
      <div>
        <div className="mb-10 pl-4">
          <Link to={"/"}>
            <img src={taramLogo} alt="logo" />
          </Link>
        </div>

        {routes.map((el) => (
          <VTabBarLink key={el.id} el={el} />
        ))}
      </div>

      <div className="text-[#C6C8D0] space-y-4">
        <Tour />
        <Link
          to="help-and-support"
          className="pl-4 flex items-center gap-3 hover:underline"
        >
          <HelpCircleIcon className="size-6" /> <span>Help & Support</span>
        </Link>

        <LogOutBtn className="w-full pl-4 rounded-lg hover:underline flex items-center gap-3">
          <LogOutIcon className="size-6" /> <span>Log Out</span>
        </LogOutBtn>
      </div>
    </aside>
  );
}
