import { createContext, useContext } from "react";
import { useAppContext } from "./AppContext";
import { useFetch } from "../hooks/useFetch";
import { get_country_origin } from "../constants/apis";

type CheckAvailabilityType = {
  uid: number;
  origin: string;
  origin_iso: string;
  destination: string;
  destination_iso: string;
};

type CheckAvailabilityData = {
  data: CheckAvailabilityType | null;
  loading: boolean;
  refetch: () => void;
};

const PartnersDatContext = createContext<CheckAvailabilityData | null>(null);

export const CheckAvailabilityContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { jwtToken } = useAppContext();

  const { data, loading, refetch } = useFetch<CheckAvailabilityType>(
    jwtToken ? get_country_origin : null,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        "Content-Type": "application/json",
      },
    }
  );

  return (
    <PartnersDatContext.Provider value={{ data, loading, refetch }}>
      {children}
    </PartnersDatContext.Provider>
  );
};

export const useCheckAvailabilityContext = () => {
  const context = useContext(PartnersDatContext);

  if (!context)
    throw new Error(
      "useCheckAvailabilityContext must be used within an provider"
    );

  return context;
};
