import { useNavigate, useParams } from "react-router-dom";
import CustomerHistory from "../../components/Admin/CustomerHistory";
import CustomerQuotes from "../../components/Admin/CustomerQuotes";
import { ArrowLeftIcon } from "../../components/Icons";
import { admin_get_all_users } from "../../constants/apis";
import { useAppContext } from "../../context/AppContext";
import { useFetch } from "../../hooks/useFetch";
import type { FullRegistrationFormType as User } from "../../schemas/FullRegistrationFormSchema";
import CustomRates from "../../components/Admin/CustomRates";

const CustomerProfileDetails = () => {
  const { uid } = useParams<{ uid: string }>();
  const navigate = useNavigate();

  const { adminJWTToken } = useAppContext();

  const { data, loading } = useFetch<User>(
    `${admin_get_all_users}`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${adminJWTToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uid }),
    },
    uid
  );

  // console.log(data);

  return (
    <section className="mt-10 space-y-8">
      <div className="card flex justify-between items-center">
        <h2 className="text-xl font-bold text-gray-800">
          Customer Profiles -{" "}
          <span className="text-base font-medium text-gray-500">
            {data?.company_name}
          </span>
        </h2>

        <button
          className="px-4 py-1 border-2 border-gray-200 text-[#344054] font-medium rounded-lg flex items-center gap-1"
          onClick={() => navigate(-1)}
        >
          <ArrowLeftIcon className="size-4" /> Back to Menu
        </button>
      </div>

      <CustomerQuotes />
      <CustomerHistory />
      <CustomRates/>
    </section>
  );
};
export default CustomerProfileDetails;
