import { RouteDetails } from "../pages/LSummary";

export const TrackingSteps = ({
  location,
  truck,
  rail,
}: {
  location: string;
  truck: RouteDetails;
  rail: RouteDetails;
}) => {
  return (
    <div>
      <div className="w-[300px] p-2 border rounded-md">
        <h3 className="text-gray-800 text-xs font-medium">{location}</h3>
        <div className="flex justify-between text-sm">
          <p className="text-custom-blue-new">Road FCL (By Truck)</p>
          <p>
            ~ {truck?.distance},{" "}
            {truck?.transitTime === "0" ? "0 days" : truck?.transitTime}
          </p>
        </div>
        <div className="flex justify-between text-sm">
          <p className="text-custom-blue-new">RF Road freight</p>
          <p>USD {truck?.price}</p>
        </div>

        {rail?.distance && rail?.price && rail?.transitTime && (
          <>
            <hr className="text-gray-100 my-1" />

            <div className="flex justify-between text-sm">
              <p className="text-custom-blue-new">Road FCL (By Rail)</p>
              <p>
                ~ {rail?.distance}, {rail?.transitTime}
              </p>
            </div>
            <div className="flex justify-between text-sm">
              <p className="text-custom-blue-new">RF Road freight</p>
              <p>USD {rail?.price}</p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export function VerticalDividerWithCircle() {
  return (
    <div>
      <div className="h-5 w-5 border-[6px] border-custom-blue-new rounded-full mt-2" />
      <div className="w-0 h-24 border-2 border-custom-blue-new ml-2 mt-1" />
    </div>
  );
}
