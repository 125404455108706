import { z } from "zod";

export const AddNewVendorSchema = z.object({
  vendor_id: z.number().optional(),
  company_name: z
    .string()
    .trim()
    .min(3, { message: "Please select a company name" }),
  services_offered: z
    .string()
    .trim()
    .min(3, { message: "Registration number is required" }),

  contact_details: z.array(
    z.object({
      contact_person_name: z
        .string()
        .trim()
        .min(3, { message: "Contact person name is required" }),
      company_division: z
        .string()
        .trim()
        .min(3, { message: "Contact person name is required" }),
      email: z
        .string()
        .trim()
        .email("Enter a valid email")
        .min(3, { message: "Email is required" }),
      phone: z
        .string()
        .trim()

        .min(6, { message: "Enter a valid phone number" }),
    })
  ),
});

export type AddNewVendorFormType = z.infer<typeof AddNewVendorSchema>;
