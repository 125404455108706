import { useRef } from "react";
import { useAppContext } from "../../context/AppContext";

import Modal from "../Modal";
import {
  AmountIcon,
  ContactsIcon,
  ETAIcon,
  EyeIcon,
  QualityAssuranceIcon,
} from "../Icons";
import { QualityAssuranceData } from "../../types/TransactionSummary";
import { QAShowData } from "../QAShowData";

export default function QualityAssuranceCard({
  qualityAssuranceData,
}: {
  qualityAssuranceData: QualityAssuranceData;
}) {
  const { user } = useAppContext();
  const viewServiceModalRef = useRef<HTMLDialogElement>(null);

  const toggleModal = () => {
    viewServiceModalRef.current?.close();
  };

  const { qa_consignment_value } = qualityAssuranceData;

  return (
    <div>
      <div className="card">
        <div className="flex justify-between items-start mb-4">
          <div className="flex items-center gap-2">
            <div className="border p-1 rounded-full">
              <QualityAssuranceIcon className="size-8" />
            </div>
            <div>
              <p className="text-custom-dark-blue-new text-lg font-bold">
                Quality Assurance
              </p>
              <p className="text-custom-gray text-sm">{user?.company_name}</p>
            </div>
          </div>

          <p className="px-2 py-1 text-xs font-medium rounded-2xl bg-green-50 text-green-400 border border-green-300">
            &uarr; Executed
          </p>
        </div>

        <div className=" border-2 rounded-sm border-dashed px-2 h-[64px] ">
          <div className=" flex justify-between items-center  py-2">
            <div className="flex justify-between items-center gap-4 ">
              <p className=" text-black bg-gray-100 p-2 border border-gray-300 rounded-md flex items-center gap-1">
                <AmountIcon className="size-4 " />
                <span className="text-gray-600">Amount:</span> $
                {qa_consignment_value}
              </p>

              <p className="text-black bg-gray-100 p-2 border border-gray-300 rounded-md flex items-center gap-1">
                <ContactsIcon className="size-4" />
                <span className="text-gray-600  ">From:</span> {}
              </p>

              <p className="  text-black bg-gray-100 p-2 border border-gray-300 rounded-md flex items-center gap-1">
                <ContactsIcon className="size-4" />
                <span className="text-gray-600">To:</span> {}
              </p>

              <p className=" text-black bg-gray-100 p-2 border border-gray-300 rounded-md ">
                <span className="text-gray-600">ETA:</span> {"11 days"}
              </p>
            </div>

            <div className="flex flex-col justify-center items-center">
              <button
                className="px-2 py-1 text-custom-blue hover:underline border border-custom-blue rounded-md  flex items-center gap-2"
                onClick={() => viewServiceModalRef.current?.showModal()}
              >
                <EyeIcon className="size-5 " />
                View Detail
              </button>
            </div>
          </div>
        </div>
      </div>

      <Modal
        ref={viewServiceModalRef}
        className="backdrop:bg-black/40 w-[600px] rounded-xl p-8"
        toggleDialog={toggleModal}
      >
        <QAShowData data={qualityAssuranceData} />
      </Modal>
    </div>
  );
}
