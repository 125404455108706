import { SubmitHandler, useForm, useFieldArray } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import { toast } from "react-toastify";
import { CrossIcon } from "../Icons";
import InputWithLabel from "../InputWithLabel";
import { useAppContext } from "../../context/AppContext";
import {
  AddNewVendorFormType,
  AddNewVendorSchema,
} from "../../schemas/VendorFormSchema";
import { add_vendors, update_vendor } from "../../constants/apis";

type AddNewVendorProps = {
  onClose: () => void;
  formType: "add" | "update";
  selectedVendor: AddNewVendorFormType | undefined;
  refetch: () => void;
};

const initialState = {
  company_name: "",
  services_offered: "",
  contact_details: [
    {
      contact_person_name: "",
      company_division: "",
      email: "",
      phone: "",
    },
  ],
};

const AddNewVendor: React.FC<AddNewVendorProps> = ({
  onClose,
  formType,
  selectedVendor,
  refetch,
}) => {
  const { adminJWTToken } = useAppContext();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    control,
  } = useForm<AddNewVendorFormType>({
    resolver: zodResolver(AddNewVendorSchema),
    values: formType === "update" ? selectedVendor : initialState,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "contact_details",
  });

  const onSubmitHandler: SubmitHandler<AddNewVendorFormType> = async (
    values
  ) => {
    const apiUrl = formType === "add" ? add_vendors : update_vendor;

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${adminJWTToken}`,
          "content-type": "application/json",
        },
        body: JSON.stringify(values),
      });
      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }
      const data = await response.json();

      if (data.status === "success") {
        toast.success(data?.message);
        reset();
        onClose();
        refetch();
      }
    } catch (error) {
      console.log(error);
      if (error instanceof Error) {
        toast.error(error.message);
      }
    }
  };

  return (
    <div className="w-[50vw] p-8">
      <div className="flex justify-between mb-5">
        <h1 className="text-xl font-bold">Vendor Details</h1>
        <button type="button" onClick={onClose} className="hover:scale-90">
          <CrossIcon className="size-8" />
        </button>
      </div>
      <form
        className="grid grid-cols-2 gap-3"
        onSubmit={handleSubmit(onSubmitHandler)}
      >
        <InputWithLabel
          label={"Company Name"}
          placeholder="Enter here"
          type="text"
          error={errors.company_name?.message}
          {...register("company_name")}
        />
        <InputWithLabel
          label={"Services Offered"}
          placeholder="Enter here"
          type="text"
          error={errors.services_offered?.message}
          {...register("services_offered")}
        />

        {fields.map((field, index) => (
          <div key={field.id} className="col-span-full grid grid-cols-2 gap-3">
            <h3 className="col-span-full text-gray-800 font-medium">
              Contact Details - {index + 1}
            </h3>

            <InputWithLabel
              label={"Contact Person Name"}
              placeholder="Enter here"
              type="text"
              error={
                errors.contact_details?.[index]?.contact_person_name?.message
              }
              {...register(`contact_details.${index}.contact_person_name`)}
            />
            <InputWithLabel
              label={"Company Division"}
              placeholder="Enter here"
              type="text"
              error={errors.contact_details?.[index]?.company_division?.message}
              {...register(`contact_details.${index}.company_division`)}
            />

            <InputWithLabel
              label={"Email Address"}
              placeholder="Enter here"
              type="text"
              error={errors.contact_details?.[index]?.email?.message}
              {...register(`contact_details.${index}.email`)}
            />
            <InputWithLabel
              label={"Phone Number"}
              placeholder="Enter here"
              type="text"
              error={errors.contact_details?.[index]?.phone?.message}
              {...register(`contact_details.${index}.phone`)}
            />

            {index !== 0 && (
              <div>
                <button
                  type="button"
                  className="text-red-500 hover:underline"
                  onClick={() => {
                    remove(index);
                  }}
                >
                  Remove
                </button>
              </div>
            )}
          </div>
        ))}

        <div className="mt-4">
          <button
            type="button"
            className="px-4 py-2 text-custom-blue-medium font-medium border border-custom-blue-medium rounded-lg"
            onClick={() => {
              append(initialState.contact_details);
            }}
          >
            Add Another Person
          </button>
        </div>

        <div className="col-span-full pt-4 flex justify-end gap-2">
          <button
            type="button"
            onClick={onClose}
            className="w-[87px] h-11 text-custom-blue-medium font-medium border border-custom-blue-medium rounded-lg"
          >
            Cancel
          </button>

          <button
            disabled={isSubmitting}
            className="w-28 h-11 bg-custom-blue-medium text-white font-medium rounded-lg"
          >
            {isSubmitting ? "submitting.." : "Save"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddNewVendor;
