export default function ChatBarIcon({ className }: { className: string }) {
  return (
    <svg
      width={24}
      height={25}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M9 21.6074H4.6C4.03995 21.6074 3.75992 21.6074 3.54601 21.4984C3.35785 21.4026 3.20487 21.2496 3.10899 21.0614C3 20.8475 3 20.5675 3 20.0074V5.20742C3 4.64737 3 4.36734 3.10899 4.15343C3.20487 3.96527 3.35785 3.81229 3.54601 3.71642C3.75992 3.60742 4.03995 3.60742 4.6 3.60742H7.4C7.96005 3.60742 8.24008 3.60742 8.45399 3.71642C8.64215 3.81229 8.79513 3.96527 8.89101 4.15343C9 4.36734 9 4.64737 9 5.20742V7.60742M9 21.6074H15M9 21.6074L9 7.60742M9 7.60742H13.4C13.9601 7.60742 14.2401 7.60742 14.454 7.71642C14.6422 7.81229 14.7951 7.96527 14.891 8.15343C15 8.36734 15 8.64737 15 9.20742V21.6074M15 11.6074H19.4C19.9601 11.6074 20.2401 11.6074 20.454 11.7164C20.6422 11.8123 20.7951 11.9653 20.891 12.1534C21 12.3673 21 12.6474 21 13.2074V20.0074C21 20.5675 21 20.8475 20.891 21.0614C20.7951 21.2496 20.6422 21.4026 20.454 21.4984C20.2401 21.6074 19.9601 21.6074 19.4 21.6074H15"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
