import ArrowIcon from "../components/Icons/ArrowIcon";

const FAQContent = [
  {
    id: 1,
    title: "How can I contact customer support?",
    para: `You can contact our customer support by email at
                support@example.com, or through our contact
                form below.`,
  },
  {
    id: 2,
    title: "What is your refund policy?",
    para: ` We offer a 30-day refund policy for most of our products. Please
                visit the refund section in your account to initiate a refund
                request.`,
  },
  {
    id: 3,
    title: "How do I reset my password?",
    para: `To reset your password, click on the "Forgot Password" link on
                the login page. Follow the instructions sent to your email
                address to reset your password.`,
  },
];

export default function HelpAndSupport() {
  return (
    <div className="max-w-7xl mx-auto px-4 py-12">
      {/*  Header Section  */}
      <header className="text-center mb-12">
        <h1 className="text-3xl font-semibold text-custom-blue-medium">
          Help & Support
        </h1>
        <p className="mt-2 text-lg text-gray-600">
          We're here to help. Find answers to your questions or get in touch
          with us!
        </p>
      </header>

      {/* FAQ Section  */}
      <section className="mb-12">
        <h2 className="text-2xl font-semibold text-custom-blue-medium mb-4">
          Frequently Asked Questions (FAQs)
        </h2>

        <div className="space-y-6">
          {FAQContent.map((el) => (
            <details
              key={el.id}
              className="bg-white rounded-xl group border border-[#f3f3f5]"
            >
              <summary className="text-custom-dark-blue-new font-semibold text-lg p-4 cursor-pointer flex items-center justify-between group-open:bg-custom-dark-blue-new group-open:text-white group-open:rounded-t-lg">
                <span>{el.title}</span>

                <ArrowIcon className="size-6 transition-transform duration-300 group-open:rotate-180" />
              </summary>
              <div className="mt-2 p-5 bg-white rounded-b-lg">
                <p>{el.para}</p>
              </div>
            </details>
          ))}
        </div>
      </section>

      {/* Contact Information Section  */}
      <section className="mb-12">
        <h2 className="text-2xl font-semibold text-custom-blue-medium mb-4">
          Contact Information
        </h2>

        <div className="bg-white p-6 rounded-lg shadow-lg">
          <p className="text-lg text-gray-800 flex items-center gap-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
              />
            </svg>
            <strong>vinju@taram.io</strong>
          </p>
          <p className="text-lg text-gray-800 mt-2 flex items-center gap-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"
              />
            </svg>
            <strong>+1 234 567 890</strong>
          </p>
          <p className="text-lg text-gray-800 mt-4">
            Follow us on social media:
          </p>
          <div className="flex space-x-4 ">
            <svg
              width={15}
              height={16}
              viewBox="0 0 15 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.012 15.6299H0.828C0.608401 15.6299 0.397796 15.5426 0.242516 15.3874C0.0872355 15.2321 0 15.0215 0 14.8019L0 1.45788C0 0.999883 0.37 0.629883 0.828 0.629883H14.172C14.629 0.629883 15 0.999883 15 1.45788V14.8019C15 15.2589 14.63 15.6299 14.172 15.6299H10.35V9.82088H12.3L12.591 7.55688H10.35V6.11188C10.35 5.45688 10.532 5.00988 11.472 5.00988H12.67V2.98388C12.463 2.95588 11.751 2.89388 10.924 2.89388C9.195 2.89388 8.012 3.94888 8.012 5.88688V7.55688H6.057V9.81988H8.012V15.6299Z"
                fill="black"
              />
            </svg>

            <svg
              width={15}
              height={16}
              viewBox="0 0 15 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <mask
                id="mask0_3125_5260"
                style={{ maskType: "luminance" }}
                maskUnits="userSpaceOnUse"
                x={0}
                y={0}
                width={15}
                height={16}
              >
                <path
                  d="M14.3411 0.879883H0.160156V15.3799H14.3411V0.879883Z"
                  fill="white"
                />
              </mask>
              <g mask="url(#mask0_3125_5260)">
                <path
                  d="M8.59974 7.01649L13.8789 0.879883H12.6279L8.04401 6.20821L4.38286 0.879883H0.160156L5.69654 8.93726L0.160156 15.3725H1.41122L6.25194 9.74554L10.1184 15.3725H14.3411L8.59974 7.01649ZM6.88623 9.00825L6.32528 8.20591L1.862 1.82166H3.78356L7.38548 6.97396L7.94644 7.77629L12.6285 14.4735H10.707L6.88623 9.00825Z"
                  fill="black"
                />
              </g>
            </svg>

            <svg
              width="15"
              height="16"
              viewBox="0 0 15 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.782 13.4119H10.558V9.93088C10.558 9.10088 10.543 8.03288 9.402 8.03288C8.245 8.03288 8.067 8.93688 8.067 9.87088V13.4119H5.844V6.25288H7.978V7.23188H8.008C8.305 6.66888 9.031 6.07588 10.113 6.07588C12.366 6.07588 12.782 7.55788 12.782 9.48588V13.4119ZM3.337 5.27488C3.16759 5.27488 2.99985 5.24152 2.84334 5.17669C2.68683 5.11186 2.54462 5.01684 2.42483 4.89705C2.30504 4.77726 2.21002 4.63505 2.1452 4.47854C2.08037 4.32203 2.047 4.15429 2.047 3.98488C2.047 3.81548 2.08037 3.64773 2.1452 3.49122C2.21002 3.33471 2.30504 3.1925 2.42483 3.07272C2.54462 2.95293 2.68683 2.85791 2.84334 2.79308C2.99985 2.72825 3.16759 2.69488 3.337 2.69488C3.67913 2.69488 4.00725 2.83079 4.24917 3.07272C4.49109 3.31464 4.627 3.64275 4.627 3.98488C4.627 4.32701 4.49109 4.65513 4.24917 4.89705C4.00725 5.13897 3.67913 5.27488 3.337 5.27488ZM2.224 13.4119H4.449V6.25288H2.224V13.4119ZM13.89 0.629883H1.107C0.497 0.629883 0 1.11388 0 1.70988V14.5479C0 15.1449 0.496 15.6299 1.107 15.6299H13.89C14.502 15.6299 15 15.1449 15 14.5479V1.70988C15 1.11388 14.502 0.629883 13.89 0.629883Z"
                fill="black"
              />
            </svg>
          </div>
        </div>
      </section>
    </div>
  );
}
