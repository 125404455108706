import * as React from "react";
const GermanyFlag = () => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <rect width={24} height={24} fill="url(#pattern0_5688_7600)" />
    <defs>
      <pattern
        id="pattern0_5688_7600"
        patternContentUnits="objectBoundingBox"
        width={1}
        height={1}
      >
        <use xlinkHref="#image0_5688_7600" transform="scale(0.0208333)" />
      </pattern>
      <image
        id="image0_5688_7600"
        width={48}
        height={48}
        preserveAspectRatio="none"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAcklEQVR4nO3VOQ3AQAxE0emCyuWScB2iRjZpl4E10X+SAfiWAAD4g9Ovk0Ik0HTAjNBteyQOS9z7VT2c0Q5+ZC45KUQCRQfMCN22R8Isce1X1ZzRSn5k8zgpRAJDB8wI3bZHwizx7FfVnNEJfmQAACjQB5X3Dd1MvigZAAAAAElFTkSuQmCC"
      />
    </defs>
  </svg>
);
export default GermanyFlag;
