import React, { useState } from "react";
import Button from "./Button";
import {
  blackColor,
  greenHighlightColor,
  whiteColor,
} from "../constants/colors";
import { SuccessIcon } from "./Icons";

interface AlertPorps {
  alertText?: string;
  handleClick?: () => void;
  buttonWidth?: string;
  buttonHeight?: string;
  borderRadius?: string;
  buttonTitle?: string;
  paddingBlock?: string;
  backgroundColor?: string;
  iconSource?: string;
  altIcon?: string;
  alertText2?: string;
  alertText1?: string;
  iconSize?: string;
  alertTextColor?: string;
  cardWidth?: string;
  cardHeight?: string;
  showIcon?: boolean;
}

export const AltertBox: React.FC<AlertPorps> = ({...props}) => {
  const {
    alertTextColor,
    alertText,
    alertText1,
    alertText2,
    handleClick,
    iconSize,
    iconSource,
    altIcon,
    borderRadius,
    buttonHeight,
    buttonTitle,
    buttonWidth,
    paddingBlock,
    backgroundColor,
    showIcon,
    cardHeight,
    cardWidth,
  } = props;
  return (
    <>
      <div
        style={{
          width: "100%",
          backgroundColor: "rgba(0,0,0,.5)",
          position: "fixed",
          top: "0",
          left: "0",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: cardWidth || "580px",
            borderRadius: "10px",
            height: cardHeight || "380px",
            marginInline: "auto",
            alignSelf: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            backgroundColor: whiteColor,
            paddingInline: "40px",
            paddingBottom: "10px",
          }}
        >
          {/* <img
            style={{
              textAlign: "center",
              width: iconSize || "44px",
              height: iconSize || "44px",
              marginInline: "auto",
              marginTop: "50px",
              justifyContent: "center",
              backgroundColor: greenHighlightColor,
              borderRadius: "50%",
            }}
            src={iconSource}
            alt={altIcon}
          /> */}
          {showIcon && (
            <div className="flex justify-center">
              <SuccessIcon className={"w-[86px] items-center"} />
            </div>
          )}

          {/* <div className="flex justify-center">
            <svg
              width={iconSize || 86}
              height={iconSize || 86}
              className="text-center"
              viewBox="0 0 86 86"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx={43} cy={43} r={43} fill="#3BE637" />
              <path
                d="M57.827 31.8789L37.4391 52.2668L28.1719 42.9996"
                stroke="white"
                strokeWidth={9}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div> */}

          <p
            style={{
              color: alertTextColor || greenHighlightColor,
              textAlign: "center",
              fontSize: 24,
              fontWeight: "600",
              marginBlock: "10px",
            }}
          >
            {alertText}
          </p>
          <p className=" text-custom-gray text-center text-[16px] leading-7">
            {alertText1}
          </p>
          <p style={{ color: blackColor, textAlign: "center", fontSize: 16 }}>
            {alertText2}
          </p>
          <div
            style={{ width: "95%", display:'flex',justifyContent:"center", marginBlock: "20px", marginInline: "auto" }}
            onClick={handleClick}
          >
            <Button
              type="button"
              style={{ height: "50px", width: "100%", borderRadius: "10px" }}
              width={buttonWidth}
              height={buttonHeight}
              backgroundColor={backgroundColor}
              borderRadius={borderRadius}
              titleStyle={{ textAlign: "center" }}
              buttonTitle={buttonTitle}
              paddingBlock={paddingBlock}
            />
          </div>
        </div>
      </div>
    </>
  );
};
