import { Outlet } from "react-router-dom";

const phrases = ["neobank", "Freight forwarder", "trade finance"];

export default function LoginSignupLayout() {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 h-screen">
      <div className="flex items-center justify-center">
        <Outlet />
      </div>

      <div className="hidden md:flex justify-center items-center bg-custom-dark-blue-new">
        <div className="w-[400px] text-white">
          <div className="space-y-6">
            <div className="w-8 h-1 bg-white rounded-md" />
            <p>
              Unlock new markets and opportunities with our B2B global
              cross-border trade platform.
            </p>
            <ul className="space-y-3">
              {phrases.map((el) => (
                <li key={el} className="flex">
                  <CheckCircleIcon />
                  <p className="ml-3">
                    one-stop{" "}
                    <code className="font-mono font-bold">
                      <i>{el}</i>
                    </code>{" "}
                    solution
                  </p>
                </li>
              ))}
            </ul>
            <p>
              Seamlessly connect with international businesses, expand your
              reach, and grow your business globally.
            </p>
          </div>
          <hr className="my-6 border-gray-400" />
          <p className="mb-3">Want to know more about our services?</p>
          <p className="font-semibold">
            <a
              href="#"
              className="text-gray-950 underline decoration-sky-400 underline-offset-3 hover:decoration-2 dark:text-white"
            >
              Read the Terms & policies &rarr;
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}

function CheckCircleIcon() {
  return (
    <svg
      className="h-[1lh] w-5.5 shrink-0"
      viewBox="0 0 22 22"
      fill="none"
      strokeLinecap="square"
    >
      <circle cx="11" cy="11" r="11" className="fill-sky-400/25" />
      <circle cx="11" cy="11" r="10.5" className="stroke-sky-400/25" />
      <path
        d="M8 11.5L10.5 14L14 8"
        className="stroke-sky-800 dark:stroke-sky-300"
      />
    </svg>
  );
}
