export default function GlobeIcon({ className }: { className: string }) {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_5688_7575)">
        <path
          d="M2.04935 4.98822L4.90805 7.03011C5.0568 7.13636 5.13117 7.18948 5.20902 7.20173C5.27751 7.2125 5.34765 7.20164 5.40969 7.17067C5.48019 7.13548 5.53503 7.06236 5.64471 6.91611L6.24877 6.11069C6.27979 6.06933 6.2953 6.04865 6.31353 6.03082C6.32971 6.01498 6.34747 6.00083 6.36652 5.98859C6.38798 5.97481 6.4116 5.96431 6.45883 5.94332L9.0379 4.79707C9.14481 4.74955 9.19826 4.72579 9.23857 4.68869C9.27422 4.65587 9.30229 4.61568 9.32082 4.5709C9.34177 4.52028 9.34566 4.46191 9.35344 4.34518L9.54228 1.51261M8.9987 8.99967L10.7427 9.74711C10.9451 9.83383 11.0462 9.87718 11.0998 9.94921C11.1468 10.0124 11.17 10.0902 11.1651 10.1688C11.1595 10.2584 11.0985 10.35 10.9764 10.5332L10.157 11.7622C10.0991 11.849 10.0702 11.8924 10.032 11.9239C9.9981 11.9517 9.95909 11.9726 9.91716 11.9853C9.8698 11.9997 9.81762 11.9997 9.71327 11.9997H8.3831C8.24487 11.9997 8.17576 11.9997 8.11695 11.9766C8.06502 11.9561 8.01904 11.923 7.98325 11.8802C7.94271 11.8317 7.92085 11.7661 7.87714 11.635L7.40189 10.2093C7.37611 10.1319 7.36322 10.0932 7.35982 10.0539C7.35681 10.0191 7.35931 9.98397 7.36722 9.9499C7.37613 9.91147 7.39437 9.87501 7.43083 9.80208L7.79184 9.08007C7.86518 8.93337 7.90186 8.86002 7.95835 8.81448C8.00813 8.77435 8.0684 8.74938 8.13198 8.74256C8.20412 8.73482 8.28192 8.76075 8.43752 8.81261L8.9987 8.99967ZM14.6654 7.99967C14.6654 11.6816 11.6806 14.6663 7.9987 14.6663C4.3168 14.6663 1.33203 11.6816 1.33203 7.99967C1.33203 4.31778 4.3168 1.33301 7.9987 1.33301C11.6806 1.33301 14.6654 4.31778 14.6654 7.99967Z"
          stroke="currentColor"
          strokeWidth={1.33333}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_5688_7575">
          <rect width={16} height={16} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
