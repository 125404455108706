import Button from "../components/Button";
import { useAppContext } from "../context/AppContext";
import {
  blackColor,
  blueColor,
  offWhiteColor,
  whiteColor,
} from "../constants/colors";
import leftArrow from "../assets/icons/arrow-left.png";
import { useNavigate, useSearchParams } from "react-router-dom";
import Cookies from "js-cookie";
import { useState } from "react";
import helpIcon from "../assets/icons/helpIcon.png";
import ComboBox from "../components/ComboBox";
import Checkbox from "../components/Checkbox";
import InputWithLabel from "../components/InputWithLabel";
import Dropdown from "../components/DropDown";
import {
  BeneficiaryAccountDetails,
  BeneficiaryDetails,
} from "../components/SimplePayment/FormSteps/SenderBeneficiary";
import { useFetch } from "../hooks/useFetch";
import {
  add_smart_escrow_details,
  get_smart_escrow_details,
  update_smart_escrow_details,
} from "../constants/apis";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  SEDetailsFCPFormSchema,
  SEDetailsFCPFormType,
  SEDetailsSCPFormSchema,
  SEDetailsSCPFormType,
} from "../schemas/SEDetailsType";
import {
  currencyOptions,
  paymentModeOptions,
} from "../components/SimplePayment/FormSteps/Details";
import { toast } from "react-toastify";
import { TWO_PERCENT } from "../constants/variable";

const escrowFeesPaidByOptiions = [
  {
    label: "Sender",
    value: "Sender",
  },
  {
    label: "Beneficiary",
    value: "Beneficiary",
  },
  {
    label: "Equal Split",
    value: "Equal Split",
  },
];

export const SEDetails = () => {
  const { checked } = useAppContext();

  const navigate = useNavigate();

  const [isChecked, setIsChecked] = useState(false);
  const tooltipHover =
    'Lorem ipsum dolor sit amet" is a placeholder text commonly used in the design, printing, and typesetting industries.';

  const [searchParams] = useSearchParams();

  const beneficiaryId = searchParams.get("beneficiaryId");
  const beneficiaryAccountId = searchParams.get("beneficiaryAccountId");

  if (checked == false) {
    return (
      <SEDetailsSimpleConditionBasedPaymentForm
        {...{ beneficiaryId, beneficiaryAccountId, checked }}
      />
    );
  } else {
    return (
      <SEDetailsFullConditionBasedPaymentForm
        {...{ beneficiaryId, beneficiaryAccountId, checked }}
      />
    );
  }
};

// TODO: have to put in a separeate component
const SEDetailsSimpleConditionBasedPaymentForm = ({
  beneficiaryId,
  beneficiaryAccountId,
  checked,
}: {
  beneficiaryId: string | null;
  beneficiaryAccountId: string | null;
  checked: boolean;
}) => {
  const navigate = useNavigate();
  const { jwtToken } = useAppContext();

  const {
    data: allSmartEscrowData,
    loading,
    error,
  } = useFetch<{
    smart_escrow: SEDetailsSCPFormType & {
      smart_escrow_id: number;
      conditions: [];
    };
  }>(get_smart_escrow_details, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      "Content-Type": "application/json",
    },
  });

  // console.log(allSmartEscrowData);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    control,
  } = useForm<SEDetailsSCPFormType>({
    resolver: zodResolver(SEDetailsSCPFormSchema),
    defaultValues: {
      request_fx_hedging: false,
      amount_sent_currency: "USD",
      amount_received_currency: "USD",
    },
    values: {
      ...allSmartEscrowData?.smart_escrow!,
      request_fx_hedging: allSmartEscrowData?.smart_escrow.request_fx_hedging
        ? true
        : false,
    },
  });

  // console.log(errors);

  const onSubmitHandler: SubmitHandler<SEDetailsSCPFormType> = async (
    values
  ) => {
    // console.log(values, allSmartEscrowData?.smart_escrow.smart_escrow_id);

    try {
      const res = await fetch(
        allSmartEscrowData?.smart_escrow?.smart_escrow_id
          ? update_smart_escrow_details
          : add_smart_escrow_details,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            smart_escrow_id: allSmartEscrowData?.smart_escrow?.smart_escrow_id,
            beneficiary_id: beneficiaryId,
            beneficiary_account_id: beneficiaryAccountId,
            condition_based_payment: checked ? 1 : 0,
            conditions: allSmartEscrowData?.smart_escrow?.conditions || [],
            ...allSmartEscrowData?.smart_escrow,
            ...values,
          }),
        }
      );

      const data = await res.json();

      // console.log(data);

      if (data.status === "success") {
        toast.success(data?.msg || data?.message);
        navigate(
          `/smart-escrow/conditions?beneficiaryId=${beneficiaryId}&beneficiaryAccountId=${beneficiaryAccountId}&smart_escrow_id=${
            allSmartEscrowData?.smart_escrow?.smart_escrow_id ||
            data?.smart_escrow_id
          }`
        );
      } else {
        toast.error("Something went wrong, please try again");
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong, please try again");
    }
  };

  return (
    <section>
      <form
        className="mt-5 py-4 px-20 flex flex-col gap-4"
        onSubmit={handleSubmit(onSubmitHandler)}
      >
        <div className="flex justify-between items-center gap-10">
          <InputWithLabel
            label={"AMOUNT SENT"}
            element={
              <Dropdown
                label=""
                options={currencyOptions}
                className="currency-dropdown"
                style={{
                  width: "100px",
                }}
                {...register("amount_sent_currency")}
              />
            }
            id={"amountSent"}
            {...register("amount_sent")}
            error={errors.amount_sent?.message}
            onChange={(e) => {
              let fees = TWO_PERCENT * parseInt(e.currentTarget.value);
              let amountRecieved = parseInt(e.currentTarget.value) - fees;
              setValue("fees", fees);
              setValue("amount_received", amountRecieved);
            }}
          />
          <InputWithLabel
            label={"AMOUNT RECEIVED"}
            id={"amountRecieved"}
            element={
              <Dropdown
                label=""
                options={currencyOptions}
                className="currency-dropdown"
                style={{
                  width: "100px",
                }}
                {...register("amount_received_currency")}
              />
            }
            {...register("amount_received")}
            // error={errors.amount_received?.message}
            disabled
          />

          <InputWithLabel
            label=""
            id="spamountRecieved2"
            style={{
              backgroundColor: whiteColor,
              borderRadius: "30px",
              marginTop: "20px",
            }}
            placeholder="1 USD = 0.98 EURO"
            disabled
          />
        </div>
        <div className="flex justify-between items-center gap-10">
          <InputWithLabel
            label={"FEES"}
            id={"fees"}
            {...register("fees")}
            // error={errors.fees?.message}
            disabled
          />
          {/* <InputWithLabel
        label={"ESTIMATED TIME"}
        type="date"
        id={"estimatedTime"}
        placeholder="By Monday, 12:04 PM"
        {...register("estimatedTime")}
        error={errors.estimatedTime?.message}
      /> */}
          <InputWithLabel
            label={"REFERENCE"}
            type="text"
            id={"reference"}
            placeholder="Reference description"
            {...register("reference")}
            error={errors.reference?.message}
          />
        </div>

        <div className="flex justify-between items-center gap-10">
          {/* <Dropdown
        label="REASON"
        options={reasonOptions}
        style={{ flex: 0.95 }}
        id={"reason"}
        placeholder="Select a reason"
        {...register("reason")}
        error={errors.reason?.message}
      /> */}

          {/* <Controller
            name="payment_mode"
            control={control}
            render={({ field }) => (
              <Dropdown
                {...field}
                label="CHOOSE PAYMENT MODE"
                options={paymentModeOptions}
                style={{ flex: 0.48 }}
                onChange={(selectChange) => field.onChange(selectChange)}
              />
            )}
          /> */}

          <Dropdown
            label="CHOOSE PAYMENT MODE"
            options={paymentModeOptions}
            style={{ flex: 0.48 }}
            id={"paymentMode"}
            {...register("payment_mode")}
            error={errors.payment_mode?.message}
          />
        </div>

        <div className="flex items-center gap-4">
          <input
            type="checkbox"
            id="request_fx_hedging"
            className="w-5 h-5"
            {...register("request_fx_hedging")}
          />
          <label htmlFor="request_fx_hedging">REQUEST FX HEDGING</label>
          <small className="text-red-500">
            {errors && errors.request_fx_hedging?.message}
          </small>
        </div>

        <div className="flex">
          {/* <Controller
            name="fees_payed_by"
            control={control}
            render={({ field }) => (
              <Dropdown
                {...field}
                label="ESCROW FEES PAID BY"
                options={escrowFeesPaidByOptiions}
                style={{ flex: 0.48 }}
              />
            )}
          /> */}

          <Dropdown
            label="ESCROW FEES PAID BY"
            options={escrowFeesPaidByOptiions}
            style={{ flex: 0.48 }}
            id={"fees_payed_by"}
            {...register("fees_payed_by")}
            error={errors.fees_payed_by?.message}
          />
        </div>

        <div className="my-14 inline-flex justify-around">
          <button
            type="button"
            className="w-32 h-14 p-1 border border-custom-blue rounded flex justify-center items-center gap-2 text-custom-blue"
            onClick={() => navigate(-1)}
          >
            <img src={leftArrow} alt="left arrow" />
            <span>Back</span>
          </button>

          <button
            type="submit"
            className="w-32 h-14 p-1 bg-custom-blue text-white rounded"
            disabled={isSubmitting}
          >
            {isSubmitting ? "submitting.." : "Next"}
          </button>
        </div>
      </form>
    </section>
  );
};

const SEDetailsFullConditionBasedPaymentForm = ({
  beneficiaryAccountId,
  beneficiaryId,
  checked,
}: {
  beneficiaryId: string | null;
  beneficiaryAccountId: string | null;
  checked: boolean;
}) => {
  const navigate = useNavigate();
  const { jwtToken } = useAppContext();

  const { data: allSmartEscrowData, loading } = useFetch<{
    smart_escrow: SEDetailsFCPFormType & {
      smart_escrow_id: number;
      conditions: [];
    };
  }>(get_smart_escrow_details, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      "Content-Type": "application/json",
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
  } = useForm<SEDetailsFCPFormType>({
    resolver: zodResolver(SEDetailsFCPFormSchema),
    defaultValues: {
      exchange_rate: "1 USD = 0.98 EURO",
      request_fx_hedging: false,
    },
    values: {
      ...allSmartEscrowData?.smart_escrow!,
      exchange_rate:
        allSmartEscrowData?.smart_escrow?.exchange_rate || "1 USD = 0.98 EURO",
      request_fx_hedging: allSmartEscrowData?.smart_escrow?.request_fx_hedging
        ? true
        : false,
    },
  });

  // console.log(errors);

  const onSubmitHandler: SubmitHandler<SEDetailsFCPFormType> = async (
    values
  ) => {
    console.log(values);

    try {
      const res = await fetch(
        allSmartEscrowData?.smart_escrow?.smart_escrow_id
          ? update_smart_escrow_details
          : add_smart_escrow_details,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            smart_escrow_id: allSmartEscrowData?.smart_escrow?.smart_escrow_id,
            beneficiary_id: beneficiaryId,
            beneficiary_account_id: beneficiaryAccountId,
            condition_based_payment: checked ? 1 : 0,
            conditions: allSmartEscrowData?.smart_escrow?.conditions || [],
            ...allSmartEscrowData?.smart_escrow,
            ...values,
          }),
        }
      );

      const data = await res.json();

      // console.log(data);

      if (data.status === "success") {
        toast.success(data?.msg || data?.message);
        navigate(
          `/smart-escrow/conditions?beneficiaryId=${beneficiaryId}&beneficiaryAccountId=${beneficiaryAccountId}&smart_escrow_id=${
            allSmartEscrowData?.smart_escrow?.smart_escrow_id ||
            data?.smart_escrow_id
          }`
        );
      } else {
        toast.error("Something went wrong, please try again");
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong, please try again");
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmitHandler)}
      className="mt-5 py-4 px-20 flex flex-col gap-4"
    >
      <InputWithLabel
        label={"TRANSACTION TYPE"}
        type="text"
        id={"transation_type"}
        {...register("transation_type")}
        error={errors.transation_type?.message}
      />

      <div className="flex justify-between items-center gap-4">
        <InputWithLabel
          label={"ISSUE DATE"}
          type="date"
          id={"issue_date"}
          {...register("issue_date")}
          error={errors.issue_date?.message}
        />
        <InputWithLabel
          label={"EXPIRY DATE"}
          type="date"
          id={"expiry_date"}
          {...register("expiry_date")}
          error={errors.expiry_date?.message}
        />
        <InputWithLabel
          label={"EXPIRY PLACE"}
          type="text"
          id={"expiry_place"}
          {...register("expiry_place")}
          error={errors.expiry_place?.message}
        />
      </div>
      <div className="flex justify-between items-center gap-4">
        <InputWithLabel
          label={"LATEST SHIPMENT"}
          type="text"
          id={"latest_shipment"}
          {...register("latest_shipment")}
          error={errors.latest_shipment?.message}
        />
        <InputWithLabel
          label={"SHIPMENT PERIOD"}
          type="text"
          id={"shipment_period"}
          {...register("shipment_period")}
          error={errors.shipment_period?.message}
        />
      </div>
      <div className="flex justify-between items-center gap-4">
        <InputWithLabel
          label={"TRANSACTION AMOUNT"}
          type="text"
          id={"transation_amount"}
          {...register("transation_amount")}
          error={errors.transation_amount?.message}
        />
        <InputWithLabel
          label={"TRANSACTION PI CURRENCY"}
          type="text"
          id={"transaction_pi_Field"}
          {...register("transaction_pi_currency")}
          error={errors.transaction_pi_currency?.message}
        />
        <InputWithLabel
          label={"TRANSACTION PO TOLERANCE"}
          type="text"
          id={"transaction_po_tolerance"}
          {...register("transaction_po_tolerance")}
          error={errors.transaction_po_tolerance?.message}
        />
      </div>
      <div className="flex justify-between items-center gap-4">
        <InputWithLabel
          label={"EXCHANGE RATE"}
          type="text"
          id={"exchange_rate"}
          {...register("exchange_rate")}
          error={errors.exchange_rate?.message}
          disabled
        />
        <InputWithLabel
          label={"TRANSACTION AMT TOLERANCE"}
          type="text"
          id={"transaction_amt_tolerance"}
          {...register("transaction_amt_tolerance")}
          error={errors.transaction_amt_tolerance?.message}
        />
      </div>
      <div className="flex justify-between items-center gap-4">
        <InputWithLabel
          label={"DRAWING"}
          type="text"
          id={"drawing"}
          {...register("drawing")}
          error={errors.drawing?.message}
        />
        <InputWithLabel
          label={"DRAWING TERM"}
          type="text"
          id={"drawing_term"}
          {...register("drawing_term")}
          error={errors.drawing_term?.message}
        />
      </div>
      <div className="flex justify-between items-center gap-4">
        <InputWithLabel
          label={"PARTIAL SHIPMENTS"}
          type="text"
          id={"partial_shipments"}
          {...register("partial_shipments")}
          error={errors.partial_shipments?.message}
        />
        <InputWithLabel
          label={"TRANS SHIPMENT"}
          type="text"
          id={"trans_shipment"}
          {...register("trans_shipment")}
          error={errors.trans_shipment?.message}
        />
      </div>
      <div className="flex justify-between items-center gap-4">
        <InputWithLabel
          label={"TRANSPORT FROM"}
          type="text"
          id={"transport_from"}
          {...register("transport_from")}
          error={errors.transport_from?.message}
        />
        <InputWithLabel
          label={"AIRPORT OF LOADING"}
          type="text"
          id={"airport_og_loading"}
          {...register("airport_og_loading")}
          error={errors.airport_og_loading?.message}
        />
        <InputWithLabel
          label={"AIRPORT OF DISCHARGE"}
          type="text"
          id={"airport_of_discharge"}
          {...register("airport_of_discharge")}
          error={errors.airport_of_discharge?.message}
        />
      </div>
      <div className="flex justify-between items-center gap-4">
        <InputWithLabel
          label={"TRANSPORT TO"}
          type="text"
          id={"transport_to"}
          {...register("transport_to")}
          error={errors.transport_to?.message}
        />
        <InputWithLabel
          label={"INCO TERMS"}
          type="text"
          id={"incoterms"}
          {...register("incoterms")}
          error={errors.incoterms?.message}
        />
      </div>
      <div className="flex justify-between items-center gap-4">
        <InputWithLabel
          label={"DESCRIPTION OF DELIVERY"}
          type="text"
          id={"description_of_delivery"}
          {...register("description_of_delivery")}
          error={errors.description_of_delivery?.message}
        />
        <InputWithLabel
          label={"INDUSTRY"}
          type="text"
          id={"industry"}
          {...register("industry")}
          error={errors.industry?.message}
        />
      </div>

      <InputWithLabel
        label={"NOTE"}
        type="text"
        id={"note"}
        {...register("note")}
        error={errors.note?.message}
      />

      {/* <Controller
        name="payment_mode"
        control={control}
        render={({ field }) => (
          <Dropdown
            {...field}
            label="CHOOSE PAYMENT MODE"
            options={paymentModeOptions}
            style={{ flex: 0.48 }}
          />
        )}
      /> */}

      <Dropdown
        label="CHOOSE PAYMENT MODE"
        options={paymentModeOptions}
        style={{ flex: 0.48 }}
        {...register("payment_mode")}
        error={errors.payment_mode?.message}
      />

      <div className="flex items-center gap-4">
        <input
          type="checkbox"
          id="request_fx_hedging"
          className="w-5 h-5"
          {...register("request_fx_hedging")}
        />
        <label htmlFor="request_fx_hedging">REQUEST FX HEDGING</label>
        <small className="text-red-500">
          {errors && errors.request_fx_hedging?.message}
        </small>
      </div>

      {/* <Controller
        name="fees_payed_by"
        control={control}
        render={({ field }) => (
          <Dropdown
            {...field}
            label="ESCROW FEES PAID BY"
            options={escrowFeesPaidByOptiions}
            style={{ flex: 0.48 }}
          />
        )}
      /> */}

      <Dropdown
        label="ESCROW FEES PAID BY"
        options={escrowFeesPaidByOptiions}
        style={{ flex: 0.48 }}
        {...register("fees_payed_by")}
        error={errors.fees_payed_by?.message}
      />

      {/* <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBlock: "10px",
          marginLeft: "5%",
          cursor: "pointer",
        }}
      >
        <Checkbox
          label={"REQUEST FX HEDGING"}
          isChecked={isChecked}
          onChange={handleCheckBox}
        />
      </div> */}
      {/* <div style={{ marginTop: "7%", width: "90%", marginInline: "auto" }}>
        <p
          style={{
            height: "24px",
            top: "563px",
            gap: "0px",
            opacity: "0px",
            fontSize: "20px",
            fontWeight: "400",
            lineHeight: "24px",
            letterSpacing: "-0.02em",
            textAlign: "left",
            color: "black",
            marginTop: "20px",
            marginLeft: "70px",
          }}
        >
          ESCROW FEES PAID BY
        </p>
        <div
          style={{
            display: "flex",
            width: "98%",
            marginInline: "auto",
            marginTop: "20px",
            gap: "3px",
          }}
        >
          {buttons.map((button, index) => (
            <div
              key={index}
              style={{
                width: "33.33%",
                height: "56px",
                backgroundColor:
                  buttonValue == button.id ? whiteColor : blueColor,
                color: buttonValue == button.id ? blueColor : whiteColor,
                textAlign: "center",
                cursor: "pointer",
              }}
              onClick={() => handleButtonColor(button.id)}
            >
              <p>{buttonClicked}</p>
              <p style={{ width: "100%", lineHeight: "56px" }}>{button.name}</p>
            </div>
          ))}
        </div>
      </div> */}

      <div className="my-14 inline-flex justify-around">
        <button
          type="button"
          className="w-32 h-14 p-1 border border-custom-blue rounded flex justify-center items-center gap-2 text-custom-blue"
          onClick={() => navigate(-1)}
        >
          <img src={leftArrow} alt="left arrow" />
          <span>Back</span>
        </button>

        <button
          type="submit"
          className="w-32 h-14 p-1 bg-custom-blue text-white rounded"
          disabled={isSubmitting}
        >
          {isSubmitting ? "submitting.." : "Next"}
        </button>
      </div>
    </form>
  );
};
