import { useEffect, useState } from "react";
import { useAppContext } from "../context/AppContext";
import { add_country_origin, get_country_origin } from "../constants/apis";
import { toast } from "react-toastify";
import ComboBox from "./ComboBox";
import { useMatch } from "react-router-dom";
import { useFetch } from "../hooks/useFetch";
import SearchInput from "./SearchInput";
import SpinnerIcon from "./Icons/SpinnerIcon";
import { useCheckAvailabilityContext } from "../context/CheckAvailabilityContext";

const currencies = [
  "USD",
  "EUR",
  "JPY",
  "GBP",
  "AUD",
  "CAD",
  "CHF",
  "CNY",
  "SEK",
  "NZD",
  "MXN",
  "SGD",
  "HKD",
  "NOK",
  "KRW",
  "TRY",
  "INR",
  "RUB",
  "BRL",
  "ZAR",
];

type CheckAvailabilityType = {
  uid: number;
  origin: string;
  origin_iso: string;
  destination: string;
  destination_iso: string;
};

export default function CheckAvailability() {
  const [values, setvalues] = useState({
    origin: { name: "", countryCode: "" },
    originCurrency: "",
    destination: { name: "", countryCode: "" },
    destinationCurrency: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { jwtToken, setCheckAvailability } = useAppContext();

  const match1 = useMatch("simple-payment/*");
  const match2 = useMatch("smart-escrow/*");
  const match3 = useMatch("transaction-summary");

  // const { data, loading, refetch } = useFetch<CheckAvailabilityType>(
  //   get_country_origin,
  //   {
  //     method: "GET",
  //     headers: {
  //       Authorization: `Bearer ${jwtToken}`,
  //       "Content-Type": "application/json",
  //     },
  //   }
  // );

  const { data, loading, refetch } = useCheckAvailabilityContext();

  useEffect(() => {
    if (data) {
      setvalues({
        origin: {
          name: data.origin.split(",").at(0)!,
          countryCode: data.origin.split(",").at(1)!,
        },
        destination: {
          name: data.destination.split(",").at(0)!,
          countryCode: data.destination.split(",").at(1)!,
        },
        originCurrency: data.origin_iso,
        destinationCurrency: data.destination_iso,
      });

      setCheckAvailability(true);
    }
  }, [data]);

  // Handle the selection from ComboBox

  const handleOriginCurrency = (item: string) => {
    setvalues((prev) => ({ ...prev, originCurrency: item }));
  };

  const handleDestinationCurrency = (item: string) => {
    setvalues((prev) => ({ ...prev, destinationCurrency: item }));
  };

  const handleSubmit = async () => {
    console.log(values);
    const { origin, originCurrency, destination, destinationCurrency } = values;

    if (!origin || !originCurrency || !destination || !destinationCurrency) {
      toast.error("Please fill up the Check Availability details");

      return;
    }

    try {
      setIsSubmitting(true);

      const response = await fetch(add_country_origin, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          origin: `${origin.name}, ${origin.countryCode}`,
          origin_iso: originCurrency,
          destination: `${destination.name}, ${destination.countryCode}`,
          destination_iso: destinationCurrency,
        }),
      });
      const data = await response.json();
      console.log(data);
      if (data.status == "success") {
        toast.success("Updated Successfully");

        setCheckAvailability(true);
        refetch();
      } else {
        toast.info(data.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    !match3 && (
      <div
        className="flex items-center justify-center gap-2"
        id="check-availability"
      >
        <div>
          <p className="w-[150px] font-medium">Check Availability</p>
        </div>
        <div className="flex">
          <SearchInput
            placeholder="ORIGIN"
            name="origin"
            initialCity={data?.origin || ""}
            setFormValues={setvalues}
            className="h-[39px] text-center border border-[#B9B9B9] w-[132px] border-r-0 rounded-l-lg"
          />

          {(match1 || match2) && (
            <ComboBox
              items={currencies}
              width={78}
              height={40}
              selectedItem={data?.origin_iso || ""}
              placeholder="ISO"
              onItemSelect={handleOriginCurrency}
              className="w-[90px] rounded-r-lg"
            />
          )}
        </div>

        <div className="flex">
          <SearchInput
            placeholder="DESTINATION"
            name="destination"
            initialCity={data?.destination || ""}
            setFormValues={setvalues}
            className="h-[39px] text-center border border-[#B9B9B9] w-[132px] border-r-0 rounded-l-lg"
          />

          {(match1 || match2) && (
            <ComboBox
              items={currencies}
              width={78}
              height={40}
              selectedItem={data?.destination_iso || ""}
              placeholder="ISO"
              onItemSelect={handleDestinationCurrency}
              className="w-[90px] rounded-r-lg"
            />
          )}
        </div>
        {(match1 || match2) && (
          <button
            type="button"
            className="h-[39px] w-[100px] bg-custom-blue-medium text-white rounded-md ml-3 flex justify-center items-center"
            onClick={() => handleSubmit()}
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <SpinnerIcon className="size-4 text-gray-200 animate-spin fill-blue-600" />
            ) : (
              "Check"
            )}
          </button>
        )}
      </div>
    )
  );
}
