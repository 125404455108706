import { tasks } from "../../components/Dashboard/Task";
import { SearchIcon } from "../../components/Icons";

const options = [
  { value: "weekly", label: "Weekly" },
  { value: "monthly", label: "Monthly" },
  { value: "yearly", label: "Yearly" },
];

export default function AdminTasks() {
  return (
    <section className="card my-8 h-screen">
      <div className="py-2 flex items-center justify-between mb-4">
        <h2 className="text-[#030303] font-semibold text-xl">Tasks</h2>

        <div className="w-[400px] flex items-center gap-4">
          <div className="w-[150px]">
            <select className="input-new">
              {options.map((el) => (
                <option value={el.value} key={el.label}>
                  {el.label}
                </option>
              ))}
            </select>
          </div>

          <div className="relative">
            <SearchIcon className="size-6 absolute top-[10px] left-2 text-gray-500" />
            <input
              type="search"
              placeholder="Search"
              className="w-full p-[10px] pl-10 border border-gray-300 rounded-lg bg-[#fcfcfd] text-[0.9rem] text-gray-900 transition duration-300 focus:border-blue-500 focus:ring focus:ring-blue-200 outline-none"
            />
          </div>
        </div>
      </div>

      <div className="space-y-5">
        {tasks.map((task) => (
          <div key={task.id} className="flex items-center gap-3">
            <div>
              <div className="w-[48px] h-[48px] bg-gray-400 rounded-full"></div>
            </div>

            <div>
              <p className="text-sm font-semibold leading-2">
                {task.name}{" "}
                <span className="font-normal text-xs text-gray-400">
                  {task.time}
                </span>
              </p>
              <small className="text-gray-400">{task.message}</small>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}
