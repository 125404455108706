const MexicoFlag = () => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <rect width={24} height={24} fill="url(#pattern0_5688_7590)" />
    <defs>
      <pattern
        id="pattern0_5688_7590"
        patternContentUnits="objectBoundingBox"
        width={1}
        height={1}
      >
        <use xlinkHref="#image0_5688_7590" transform="scale(0.0208333)" />
      </pattern>
      <image
        id="image0_5688_7590"
        width={48}
        height={48}
        preserveAspectRatio="none"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAACAElEQVR4nO2YX2vSURjHfRvNCCn68wpal0G9g10EBYuVJQXR8iaUtXYxImZFKI7CpbbN9cu5ftPNib90m6ltOC2zWVvYhDDW1BJfwScYVHTZIvgtng88nNvz4Zzn+3COwSAIgiAI/wPGbhM7qWar/VdVNhh2VCJglBNoyxWSJjZKCrUlRv/ZIKttrFHwWVm0HuaF/SiVpfDuGGSvK3kWs49I9XcybTlA+PIh1HMdJK4doZKd0r9Arb6JlrnL3HQfUz17ftazng5S9k6dCzSq5EuTjGhDFIK3f23+/N7tNXbJxMaKpk+BD5+qNL/WieaC+CatpONuVPO+7Y3nxm9QeDKI1n+SVfUOm42G/gQGxgdplN4S0VSiyVsU1VOELhqZsZgIW/ZTmh3mXTLAe81Dr8esPwEtq1Db2uKYx8l1/0NS6edEEiFWlSuUR8+SutfNx5ezrK0voy0H9ScwX0zjiT6gWa9i16J0TYxx0D1El/MqZp+N41432YRG32gvc7mw/gSarTa5N/N442M4luqcefqKgvMEp10XcMzEiRUzKKkAydzvUVrWk0D9W4vhsIt4RuFxMkJgYgDFb8Md8xPJKjhCN/nc+KJfgeaPfsgvcF91o0a8LCgubH4r+fWV3TGJm/ImNsgJIFeoJf9CSAoZJUbb8jda/pNJLAiCIAiGXch34dVajdwwc8oAAAAASUVORK5CYII="
      />
    </defs>
  </svg>
);
export default MexicoFlag;
