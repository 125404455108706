import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../context/AppContext";

export default function ActionBanner() {
  const navigate = useNavigate();
  const { user } = useAppContext();

  const inProgress = user?.status === "IN_PROGRESS";

  return (
    (user?.status === null || inProgress) && (
      <div className="card flex justify-between items-center mb-8">
        <div>
          <span
            className={`py-2 rounded-md font-medium ${
              inProgress ? "text-orange-400" : "text-red-400"
            }`}
          >
            {inProgress ? "IN PROGRESS" : "ACTION REQUIRED!"}
          </span>
          <span className="mx-10 text-gray-700">
            {inProgress
              ? "Your company registration is currently in progress. Stay tuned for updates!"
              : "Complete onboarding to begin using your account."}
          </span>
        </div>
        {inProgress ? null : (
          <button
            onClick={() => navigate("/registration")}
            className="bg-custom-blue-new px-4 py-1 rounded-md text-sm text-white font-bold"
          >
            Update
          </button>
        )}
      </div>
    )
  );
}
