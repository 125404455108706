import { useState } from "react";

interface FeesState {
  payment: string;
  smartEscrow: string;
  invoiceFactoring: string;
  freightLogistics: string;
  qualityAssurance: string;
  customsClearance: string;
  insurance: string;
}

const CustomRates: React.FC = () => {
  const [fees, setFees] = useState<FeesState>({
    payment: "",
    smartEscrow: "",
    invoiceFactoring: "",
    freightLogistics: "",
    qualityAssurance: "",
    customsClearance: "",
    insurance: "",
  });

  const currentFees: Record<keyof FeesState, string> = {
    payment: "0.05%",
    smartEscrow: "0.05%",
    invoiceFactoring: "14%",
    freightLogistics: "2%",
    qualityAssurance: "0.25%",
    customsClearance: "0.25%",
    insurance: "0.10%",
  };

  const handleChange = (key: keyof FeesState, value: string) => {
    setFees((prev) => ({ ...prev, [key]: value }));
  };

  const handleSubmit = (key: keyof FeesState) => {
    console.log(`Submitted ${key}:`, fees[key]);
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-md w-full">
      <h2 className="text-[#030303] font-semibold text-xl mb-2 pb-3 border-b border-gray-200">
        Custom Rates
      </h2>
      <table className="w-full border-collapse">
        <tbody>
          {Object.keys(fees).map((key) => {
            const typedKey = key as keyof FeesState;
            return (
              <tr key={typedKey}>
                <td className="p-2 capitalize">
                  {typedKey.replace(/([A-Z])/g, " $1")}
                </td>
                <td>
                  {(typedKey === "invoiceFactoring" &&
                    "Interest Rate (% p.a)") ||
                    (typedKey === "payment" && "Transaction Fees") ||
                    (typedKey === "smartEscrow"
                      ? "Transaction Fees"
                      : "Fees")}{" "}
                </td>
                <td className="p-2 text-center">
                  <input
                    type="text"
                    placeholder="Enter Fees"
                    value={fees[typedKey]}
                    onChange={(e) => handleChange(typedKey, e.target.value)}
                    className="border p-2 rounded w-full text-center"
                  />
                </td>
                <td className="p-2 text-center">
                  <button
                    onClick={() => handleSubmit(typedKey)}
                    className="bg-custom-blue text-white px-4 py-2 rounded hover:bg-blue-700"
                  >
                    Submit
                  </button>
                </td>
                <td className="p-2 flex justify-between text-gray-600">
                  <p className="ml-24">
                    {" "}
                    Current{" "}
                    {(typedKey === "invoiceFactoring" &&
                      "Interest Rate (% p.a)") ||
                      (typedKey === "payment" && "Transaction Fees") ||
                      (typedKey === "smartEscrow"
                        ? "Transaction Fees"
                        : "Fees")}{" "}
                  </p>
                  : {currentFees[typedKey]}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default CustomRates;
