import { useEffect, useRef, useState } from "react";
import { driver } from "driver.js";
import type { DriveStep, Driver } from "driver.js";
import { useLocation } from "react-router-dom";
import { TourIcon } from "../Icons";

const dashboardSteps: DriveStep[] = [
  {
    element: "#payment-history",
    popover: {
      title: "Welcome",
      description: "View payment History",
    },
  },
  {
    element: "#dashboard-quotes",
    popover: {
      title: "Next Step",
      description: "View your quotes of your transactions.",
    },
  },
  {
    element: "#dashboard-task",
    popover: {
      title: "Next Step",
      description: "View your tasks!",
    },
  },
  {
    element: "#payment-draft",
    popover: {
      title: "Next Feature",
      description: "View your payment drafts.",
    },
  },
];

const paymentSteps: DriveStep[] = [
  {
    element: "#total-amount-card",
    popover: {
      title: "Welcome",
      description: "View your Account info section.",
    },
  },
  {
    element: "#check-availability",
    popover: {
      title: "First Step",
      description:
        "Fill your country & currency to check if it is a viable transaction.",
    },
  },
  {
    element: "#add-new-beneficiary",
    popover: {
      title: "Next Step",
      description: "Add new company and its bank account details",
    },
  },
];

const invoiceSteps: DriveStep[] = [
  {
    element: "#invoice-amount",
    popover: {
      title: "First Step",
      description: "Enter amount for estimation",
    },
  },
  {
    element: "#invoice-days",
    popover: {
      title: "Next Step",
      description: "Enter your number of days.",
    },
  },
  {
    element: "#invoice-turnover",
    popover: {
      title: "Next Step",
      description: "Enter your Yearly Turnover",
    },
  },
  {
    element: "#invoice-calculate",
    popover: {
      title: "Final Step",
      description: "Click Button to know your Pricing Estimation",
    },
  },
];

const logisticsSteps: DriveStep[] = [
  {
    element: "#fcl-quotes",
    popover: {
      title: "First Step",
      description: "View your FCL Quotes.",
    },
  },
];

const qualityAssuranceSteps: DriveStep[] = [
  {
    element: "#quality-pricing-estimate",
    popover: {
      title: "First Step",
      description: "View Pricing Estimate",
    },
  }, {
    element: "#quality-how-it-works",
    popover: {
      title: "Next Step",
      description: "How Quality Assurance Works",
    },
  },
];

function getSteps(path: string) {
  switch (path) {
    case "/dashboard":
      return dashboardSteps;
    case "/simple-payment":
      return paymentSteps;
    case "/invoice-factoring":
      return invoiceSteps;
    case "/logistics":
      return logisticsSteps;
    case "/quality-assurance":
      return qualityAssuranceSteps;

    default:
      return null;
  }
}

export default function Tour() {
  const driverRef = useRef<Driver | null>(null);
  const [showTour, setShowTour] = useState(true);
  const { pathname } = useLocation();

  useEffect(() => {
    setShowTour(true);
    const steps = getSteps(pathname);

    if (!steps) {
      setShowTour(false);
      return;
    }

    driverRef.current = driver({
      showProgress: true,
      steps,
    });

    return () => driverRef.current?.destroy();
  }, [pathname]);

  const startTour = () => {
    if (driverRef.current) {
      driverRef.current.drive();
    }
  };

  return (
    showTour && (
      <button
        className="pl-4 flex items-center gap-3 hover:underline"
        onClick={startTour}
      >
        {/* <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="2"
          stroke="currentColor"
          className="size-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 3v6m0 0l3-3m-3 3L9 6m9 6v6m0 0l3-3m-3 3l-3-3m-6 3V9m0 0l-3 3m3-3l3 3"
          />
        </svg> */}
        <TourIcon className="size-6" />
        <span>Start Tour</span>
      </button>
    )
  );
}
