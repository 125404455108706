import { useFormContext } from "react-hook-form";

import InputWithLabel from "../../InputWithLabel";
import Dropdown from "../../DropDown";
import Tooltip from "../../Tooltip";
import ConversionRateField from "../ConversionRateField";

import {
  exemptionCode,
  purposeCode,
  sourceOfFunds,
  TWO_PERCENT,
} from "../../../constants/variable";
import { FullSimplePaymentSchemaType } from "../../../components/SimplePayment/SimplePaymentForm";
import { useCheckAvailabilityContext } from "../../../context/CheckAvailabilityContext";
import { choose_payment_mode } from "../../../constants/tooltipContents";

export const currencyOptions = [
  {
    label: "USD",
    value: "USD",
  },
  {
    label: "INR",
    value: "INR",
  },
  {
    label: "EURO",
    value: "EURO",
  },
];

export const paymentModeOptions = [
  {
    label: "Local Payment (Instant)",
    value: "Local Payment (Instant)",
  },
  {
    label: "SWIFT Payment (Slower)",
    value: "SWIFT Payment (Slower)",
  },
];
export const paidFromOptions = [
  {
    label: "External Account",
    value: "External Account",
  },
  {
    label: "Account Balance",
    value: "Account Balance",
  },
  {
    label: "Both Combined",
    value: "Both Combined",
  },
];

const reasonOptions = [
  { label: "Reason 1", value: "Reason 1" },
  { label: "Reason 2", value: "Reason 2" },
  { label: "Reason 3", value: "Reason 3" },
  { label: "Reason 4", value: "Reason 4" },
];

const tooltipContent =
  "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum ipsam officiis labore, quasi dolores maxime veritatis, quaerat, minus beatae aspernatur illo aliquid animi pariatur temporibus. Reiciendis repudiandae suscipit corrupti repellendus.";

export default function Details() {
  const {
    register,
    formState: { errors },
    setValue,
    clearErrors,
  } = useFormContext<FullSimplePaymentSchemaType>();

  const { data, loading } = useCheckAvailabilityContext();

  return (
    <section className="relative space-y-2">
      {/* {loading && (
        <div className="backdrop-blur-sm rounded-lg absolute top-0 left-0 bottom-0 right-0 z-30" />
      )} */}

      <div className="flex justify-between items-center gap-4">
        <InputWithLabel
          label={"Amount Sent"}
          element={
            loading ? (
              <div className="rounded-lg bg-slate-300 h-3 w-10"></div>
            ) : (
              <p className="text-sm font-medium text-custom-dark-blue">
                {data?.origin_iso}
              </p>
            )
          }
          id={"amountSent"}
          type="number"
          {...register("amount_sent")}
          error={errors.amount_sent?.message}
          onChange={(e) => {
            let fees = Number(
              (TWO_PERCENT * parseInt(e.currentTarget.value)).toFixed(2)
            );
            let amountRecieved = Number(
              (parseInt(e.currentTarget.value) - fees).toFixed(2)
            );
            setValue("fees", fees);
            setValue("amount_received", amountRecieved);
            clearErrors("amount_sent");
            clearErrors("amount_received");
          }}
        />
        <InputWithLabel
          label={"Amount Recieved"}
          id={"amountRecieved"}
          type="number"
          element={
            loading ? (
              <div className="rounded-lg bg-slate-300 h-3 w-10"></div>
            ) : (
              <p className="text-sm font-medium text-custom-dark-blue">
                {data?.destination_iso}
              </p>
            )
          }
          {...register("amount_received")}
          error={errors.amount_received?.message}
          // disabled
          onChange={(e) => {
            let fees = Number(
              (TWO_PERCENT * parseInt(e.currentTarget.value)).toFixed(2)
            );
            let amountSent = Number(
              (parseInt(e.currentTarget.value) + fees).toFixed(2)
            );
            setValue("fees", fees);
            setValue("amount_sent", amountSent);
            clearErrors("amount_received");
            clearErrors("amount_sent");
          }}
        />

        <ConversionRateField />
      </div>
      <div className="flex justify-between items-center gap-4">
        <InputWithLabel
          label={"FEES"}
          element={<Tooltip content={tooltipContent} />}
          type="number"
          id={"fees"}
          {...register("fees")}
          // error={errors.fees?.message}
          disabled
        />
        {/* <InputWithLabel
          label={"Estimated Time"}
          type="date"
          id={"estimatedTime"}
          placeholder="By Monday, 12:04 PM"
          {...register("estimated_time")}
          error={errors.estimated_time?.message}
        /> */}
        <InputWithLabel
          label={"Reference"}
          type="text"
          id={"reference"}
          placeholder="Reference description"
          {...register("reference")}
          error={errors.reference?.message}
        />
      </div>

      <div className="flex justify-between items-center gap-4">
        <Dropdown
          label="Reason"
          element={<Tooltip content={tooltipContent} />}
          options={reasonOptions}
          id={"reason"}
          placeholder="Select a reason"
          {...register("reason")}
          error={errors.reason?.message}
        />
        <Dropdown
          label="Paid From"
          element={<Tooltip content={tooltipContent} />}
          options={paidFromOptions}
          id={"paidFrom"}
          placeholder="Select a paid from"
          {...register("paid_from")}
          error={errors.paid_from?.message}
        />

        {/* <Dropdown
          label="Choose Payment Mode"
          element={<Tooltip content={tooltipContent} />}
          options={paymentModeOptions}
          id={"paymentMode"}
          placeholder="Select a payment mode"
          {...register("payment_mode")}
          error={errors.payment_mode?.message}
        /> */}

        <InputWithLabel
          element={<Tooltip content={choose_payment_mode} />}
          label="Payment Mode"
          placeholder="Enter here"
          {...register("payoutMethod")}
          // error={errors.payment_mode?.message}
          disabled
        />
      </div>

      <div className="flex gap-4">
        <Dropdown
          label="Exemption Code"
          element={<Tooltip content={tooltipContent} />}
          placeholder="Select a exemption code"
          options={exemptionCode.map((el) => ({
            label: el.description,
            value: el.code,
          }))}
          {...register("exemptionCode")}
          error={errors.exemptionCode?.message}
        />
        <Dropdown
          label="Source Of Funds"
          element={<Tooltip content={tooltipContent} />}
          placeholder="Select a source of funds"
          options={sourceOfFunds.map((el) => ({ label: el, value: el }))}
          {...register("sourceOfFunds")}
          error={errors.sourceOfFunds?.message}
        />

        <Dropdown
          label="Purpose Code"
          element={<Tooltip content={tooltipContent} />}
          placeholder="Select a purpose code"
          options={purposeCode.map((el) => ({
            label: el.description,
            value: el.code,
          }))}
          {...register("purposeCode")}
          error={errors.purposeCode?.message}
        />
      </div>
    </section>
  );
}
