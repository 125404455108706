import { useNavigate, useParams } from "react-router-dom";
import { get_by_transaction_id } from "../../constants/apis";
import { useAppContext } from "../../context/AppContext";
import { useFetch } from "../../hooks/useFetch";
import {
  SimplePaymentData,
  TransactionSummaryRes,
  InvoiceFactoringData,
  LogisticsData,
  QualityAssuranceData,
} from "../../types/TransactionSummary";
import {
  AmountIcon,
  EyeIcon,
  FeesIcon,
  UserIcon,
} from "../../components/Icons";

import { TWO_PERCENT } from "../../constants/variable";

export default function AdminQuotesDetails() {
  const { id } = useParams<{ id: string }>();

  const navigate = useNavigate();
  const { adminJWTToken } = useAppContext();

  const { data, loading } = useFetch<TransactionSummaryRes>(
    get_by_transaction_id,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${adminJWTToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ transaction_id: id }),
    },
    id
  );

  // console.log(data);

  if (loading) return <p className="text-center text-xl mt-10">loading..</p>;

  return (
    <div className="card my-8">
      <div className="mb-6 flex justify-between items-center pb-3 border-b">
        <h2 className="text-xl font-bold text-gray-800">Quotes</h2>
        <button
          className="text-sm text-gray-600 border px-3 py-1.5 rounded-lg hover:bg-gray-100"
          onClick={() => navigate(-1)}
        >
          ← Back
        </button>
      </div>

      <div className="mb-4 flex gap-8">
        <p>
          <strong>Transaction ID:</strong> {id}
        </p>
        <p>
          <strong>Client:</strong>
        </p>
        <p>
          <strong>Date:</strong>
        </p>
      </div>
      <hr />

      <div className="space-y-4">
        {data?.simple_payment_data && (
          <PaymentService simplePaymentData={data?.simple_payment_data} />
        )}

        {data?.invoice_factoring_data && (
          <InvoiceFactoringService
            invoiceFactoringData={data.invoice_factoring_data}
          />
        )}

        {data?.logistics_data && (
          <LogisticService logisticsData={data.logistics_data} />
        )}

        {data?.quality_assurance_data && (
          <QualityAssuranceService
            qualityAssuranceData={data.quality_assurance_data}
          />
        )}
      </div>
    </div>
  );
}

function PaymentService({
  simplePaymentData,
}: {
  simplePaymentData: SimplePaymentData;
}) {
  return (
    <div className="flex justify-between items-start py-4">
      <div className="flex">
        <div className="w-[200px]">
          <h3 className="text-lg font-semibold text-custom-dark-blue-new">
            Payment
          </h3>
          <p className="text-sm text-gray-500">Taram</p>
        </div>

        <div className="space-y-4">
          <div className="flex items-center gap-2">
            <p className="flex items-center gap-1 border rounded-md border-gray-300 bg-gray-50 px-3 py-1">
              <UserIcon className="size-4" />{" "}
              <span className="text-gray-500">From/To:</span>{" "}
              <span className="font-medium">
                {simplePaymentData?.beneficiary_details?.contact_person_name}
              </span>
            </p>

            <p className="flex items-center gap-1 border rounded-md border-gray-300 bg-gray-50 px-3 py-1">
              <AmountIcon className="size-4" />{" "}
              <span className="text-gray-500">Amount:</span> $
              {simplePaymentData.amount_sent}
            </p>

            <p className="flex items-center gap-1 border rounded-md border-gray-300 bg-gray-50 px-3 py-1">
              <FeesIcon className="size-4" />{" "}
              <span className="text-gray-500">Fee:</span> $
              {simplePaymentData.fees}
            </p>
          </div>

          <div className="space-x-3">
            <button className="bg-red-100 text-red-500 text-sm px-3 py-1 border border-red-500 rounded-xl">
              Deny
            </button>
            <button className="bg-green-100 text-green-500 text-sm px-3 py-1 border border-green-500 rounded-xl">
              Approve
            </button>
          </div>
        </div>
      </div>

      <div className="flex items-center gap-3">
        <button className="flex items-center gap-1 text-custom-blue-new border border-custom-blue-new px-3 py-2 rounded-lg">
          <EyeIcon className="size-5" /> View Detail
        </button>
        <span
          className={`text-sm font-medium px-3 py-1 rounded-xl ${
            status === "Approved"
              ? "bg-green-50 text-green-500 border border-green-400"
              : "bg-gray-50 text-gray-500 border border-gray-400"
          }`}
        >
          ↑ {simplePaymentData.status || "Processing"}
        </span>
      </div>
    </div>
  );
}

function InvoiceFactoringService({
  invoiceFactoringData,
}: {
  invoiceFactoringData: InvoiceFactoringData;
}) {
  return (
    <div className="flex justify-between items-start py-4">
      <div className="flex items-center">
        <div className="w-[200px]">
          <h3 className="text-lg font-semibold text-custom-dark-blue-new">
            Invoice Factoring
          </h3>
          <p className="text-sm text-gray-500">Taram</p>
        </div>

        <div className="space-y-4">
          <div className="flex items-center gap-2">
            <p className="flex items-center gap-1 border rounded-md border-gray-300 bg-gray-50 px-3 py-1">
              <AmountIcon className="size-4" />{" "}
              <span className="text-gray-500">Amount:</span> $
              {invoiceFactoringData.invoice_amount}
            </p>

            <p className="flex items-center gap-1 border rounded-md border-gray-300 bg-gray-50 px-3 py-1">
              <FeesIcon className="size-4" />{" "}
              <span className="text-gray-500">Fee:</span> $
              {Number(invoiceFactoringData.invoice_amount) * TWO_PERCENT}
            </p>
          </div>

          <div className="space-x-3">
            <button className="bg-red-100 text-red-500 text-sm px-3 py-1 border border-red-500 rounded-xl">
              Deny
            </button>
            <button className="bg-green-100 text-green-500 text-sm px-3 py-1 border border-green-500 rounded-xl">
              Approve
            </button>
          </div>
        </div>
      </div>

      <div className="flex items-center gap-3">
        <button className="flex items-center gap-1 text-custom-blue-new border border-custom-blue-new px-3 py-2 rounded-lg">
          <EyeIcon className="size-5" /> View Detail
        </button>
        <span
          className={`text-sm font-medium px-3 py-1 rounded-xl ${
            status === "Approved"
              ? "bg-green-50 text-green-500 border border-green-400"
              : "bg-gray-50 text-gray-500 border border-gray-400"
          }`}
        >
          ↑ {invoiceFactoringData.status || "Processing"}
        </span>
      </div>
    </div>
  );
}

function LogisticService({ logisticsData }: { logisticsData: LogisticsData }) {
  return (
    <div className="flex justify-between items-start py-4">
      <div className="flex items-center">
        <div className="w-[200px]">
          <h3 className="text-lg font-semibold text-custom-dark-blue-new">
            Freight Logistics
          </h3>
          <p className="text-sm text-gray-500">Taram</p>
        </div>

        <div className="space-y-4">
          <div className="flex items-center gap-2">
            <p className="flex items-center gap-1 border rounded-md border-gray-300 bg-gray-50 px-3 py-1">
              <AmountIcon className="size-4" />{" "}
              <span className="text-gray-500">Amount:</span> $
              {logisticsData.freights.at(0)?.cost}
            </p>

            <p className="flex items-center gap-1 border rounded-md border-gray-300 bg-gray-50 px-3 py-1">
              <FeesIcon className="size-4" />{" "}
              <span className="text-gray-500">Fee:</span> $
              {Number(logisticsData.freights.at(0)?.cost) * TWO_PERCENT}
            </p>
          </div>

          <div className="space-x-3">
            <button className="bg-red-100 text-red-500 text-sm px-3 py-1 border border-red-500 rounded-xl">
              Deny
            </button>
            <button className="bg-green-100 text-green-500 text-sm px-3 py-1 border border-green-500 rounded-xl">
              Approve
            </button>
          </div>
        </div>
      </div>

      <div className="flex items-center gap-3">
        <button className="flex items-center gap-1 text-custom-blue-new border border-custom-blue-new px-3 py-2 rounded-lg">
          <EyeIcon className="size-5" /> View Detail
        </button>
        <span
          className={`text-sm font-medium px-3 py-1 rounded-xl ${
            status === "Approved"
              ? "bg-green-50 text-green-500 border border-green-400"
              : "bg-gray-50 text-gray-500 border border-gray-400"
          }`}
        >
          ↑ {logisticsData.status || "Quotes"}
        </span>
      </div>
    </div>
  );
}

function QualityAssuranceService({
  qualityAssuranceData,
}: {
  qualityAssuranceData: QualityAssuranceData;
}) {
  return (
    <div className="flex justify-between items-start py-4">
      <div className="flex items-center">
        <div className="w-[200px]">
          <h3 className="text-lg font-semibold text-custom-dark-blue-new">
            Quality Assurance
          </h3>
          <p className="text-sm text-gray-500">Taram</p>
        </div>

        <div className="space-y-4">
          <div className="flex items-center gap-2">
            <p className="flex items-center gap-1 border rounded-md border-gray-300 bg-gray-50 px-3 py-1">
              <AmountIcon className="size-4" />{" "}
              <span className="text-gray-500">Amount:</span> $
              {qualityAssuranceData.qa_consignment_value}
            </p>

            <p className="flex items-center gap-1 border rounded-md border-gray-300 bg-gray-50 px-3 py-1">
              <FeesIcon className="size-4" />{" "}
              <span className="text-gray-500">Fee:</span> $
              {Number(qualityAssuranceData.loading_consignment_value) *
                TWO_PERCENT}
            </p>
          </div>

          <div className="space-x-3">
            <button className="bg-red-100 text-red-500 text-sm px-3 py-1 border border-red-500 rounded-xl">
              Deny
            </button>
            <button className="bg-green-100 text-green-500 text-sm px-3 py-1 border border-green-500 rounded-xl">
              Approve
            </button>
          </div>
        </div>
      </div>

      <div className="flex items-center gap-3">
        <button className="flex items-center gap-1 text-custom-blue-new border border-custom-blue-new px-3 py-2 rounded-lg">
          <EyeIcon className="size-5" /> View Detail
        </button>
        <span
          className={`text-sm font-medium px-3 py-1 rounded-xl ${
            status === "Approved"
              ? "bg-green-50 text-green-500 border border-green-400"
              : "bg-gray-50 text-gray-500 border border-gray-400"
          }`}
        >
          ↑ {qualityAssuranceData.status || "Processing"}
        </span>
      </div>
    </div>
  );
}
