import { useRef } from "react";
import { useAppContext } from "../../context/AppContext";
import {
  AmountIcon,
  ContactsIcon,
  EyeIcon,
  FeesIcon,
  SimplePaymentIcon,
} from "../Icons/index";
import { SPDataShow } from "../SPDataShow";
import Modal from "../Modal";
import { SimplePaymentData } from "../../types/TransactionSummary";

export default function PaymentCard({
  simplePaymentData,
}: {
  simplePaymentData: SimplePaymentData;
}) {
  const { user } = useAppContext();
  const viewServiceModalRef = useRef<HTMLDialogElement>(null);

  const toggleModal = () => {
    viewServiceModalRef.current?.close();
  };

  const {
    beneficiary_details: { contact_person_name },
    amount_sent,
    fees,
  } = simplePaymentData;

  return (
    <div>
      <div className="card">
        <div className="flex justify-between items-start mb-4">
          <div className="flex items-center gap-2">
            <div className="border p-1 rounded-full">
              <SimplePaymentIcon className="size-8" />
            </div>
            <div>
              <p className="text-custom-dark-blue-new text-lg font-bold">
                Payment
              </p>
              <p className="text-custom-gray text-sm">{user?.company_name}</p>
            </div>
          </div>

          <p className="px-2 py-1 text-xs font-medium rounded-2xl bg-green-50 text-green-400 border border-green-300">
            &uarr; Executed
          </p>
        </div>

        <div className=" border-2 rounded-sm border-dashed px-2 h-[64px] ">
          <div className=" flex justify-between items-center  py-2">
            <div className="flex justify-between items-center gap-4 ">
              <p className="bg-gray-100 p-2 border border-gray-300 rounded-md flex items-center gap-1">
                <ContactsIcon className="size-4" />
                <span className="text-gray-600  ">From/To:</span>
                <span>{`${user?.first_name} ${user?.last_name}`}</span>
                &rarr;
                <span>{contact_person_name}</span>
              </p>

              <p className="bg-gray-100 p-2 border border-gray-300 rounded-md flex items-center gap-1">
                <AmountIcon className="size-4 " />
                <span className="text-gray-600">Amount:</span> ${amount_sent}
              </p>

              <p className="bg-gray-100 p-2 border border-gray-300 rounded-md flex items-center gap-1 ">
                <FeesIcon className="size-4" />
                <span className="text-gray-600">Fees:</span> ${fees}
              </p>
            </div>

            <div className="flex flex-col justify-center items-center">
              <button
                className="px-2 py-1 text-custom-blue hover:underline border border-custom-blue rounded-md  flex items-center gap-2"
                onClick={() => viewServiceModalRef.current?.showModal()}
              >
                <EyeIcon className="size-5 " />
                View Detail
              </button>
            </div>
          </div>
        </div>
      </div>

      <Modal
        ref={viewServiceModalRef}
        className="backdrop:bg-black/40 w-[600px] rounded-xl p-8"
        toggleDialog={toggleModal}
      >
        <p>content here</p>
        <SPDataShow data={simplePaymentData} userData={user} />
      </Modal>
    </div>
  );
}
