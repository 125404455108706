import { TableRowSkeleton } from "../../components/Dashboard/Quotes";
import { SearchIcon } from "../../components/Icons";
import { options } from "../../components/Dashboard/AccountSummary";
import { useAppContext } from "../../context/AppContext";
import { TransactionQuotes } from "../../components/Admin/Quotes";
import { transaction_list } from "../../constants/apis";
import { useFetch } from "../../hooks/useFetch";
import { Link } from "react-router-dom";

export default function AdminQuotes() {
  const { adminJWTToken } = useAppContext();

  const { data, loading } = useFetch<TransactionQuotes[]>(
    `${transaction_list}?page=${1}`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${adminJWTToken}`,
        "Content-Type": "application/json",
      },
    }
  );

  return (
    <section className="card my-8">
      <div className="py-2 flex items-center justify-between">
        <h2 className="text-[#030303] font-semibold text-xl">Quotes</h2>

        <div className="w-[400px] flex items-center gap-4">
          <div className="w-[150px]">
            <select className="input-new">
              {options.map((el) => (
                <option value={el.value} key={el.label}>
                  {el.label}
                </option>
              ))}
            </select>
          </div>

          <div className="relative">
            <SearchIcon className="size-6 absolute top-[10px] left-2 text-gray-500" />
            <input
              type="search"
              placeholder="Search"
              className="w-full p-[10px] pl-10 border border-gray-300 rounded-lg bg-[#fcfcfd] text-[0.9rem] text-gray-900 transition duration-300 focus:border-blue-500 focus:ring focus:ring-blue-200 outline-none"
            />
          </div>
        </div>
      </div>

      <table className="min-w-full mt-4 border border-[#EAECF0] text-left rounded-xl border-separate">
        <thead>
          <tr>
            <th className="font-normal text-[#475467] px-4 py-3">Id</th>
            <th className="font-normal text-[#475467]">Product</th>
            <th className="font-normal text-[#475467]">Date</th>
            <th className="font-normal text-[#475467]">Action</th>
          </tr>
        </thead>

        <tbody className="text-gray-500">
          {loading
            ? [1, 2, 3].map((el) => <TableRowSkeleton key={el} />)
            : data &&
              data?.map((el) => (
                <tr key={el.transaction_id}>
                  <td className="p-4 border-t border-[#EAECF0]">
                    <Link
                      to={el.transaction_id}
                      className="cursor-pointer hover:underline"
                    >
                      {el.transaction_id}
                    </Link>
                  </td>
                  <td className="border-t border-[#EAECF0]">
                    <div className="inline-flex items-center gap-2">
                      {/* <img src={el.logo} alt="logo" /> */}
                      <div className="w-10 h-10 bg-slate-300 rounded-full flex justify-center items-center font-medium">
                        {el.company_name?.at(0)?.toUpperCase()}
                      </div>
                      <span>{el.company_name}</span>
                    </div>
                  </td>
                  <td className="border-t border-[#EAECF0]">
                    {new Date(el.created_at).toDateString()}
                  </td>
                  <td className="border-t border-[#EAECF0]"></td>
                </tr>
              ))}
        </tbody>
      </table>

      {!loading && data === null && (
        <p className="text-gray-500 p-4 text-center">No records found</p>
      )}
    </section>
  );
}
