import Dropdown from "../DropDown";
import { useAppContext } from "../../context/AppContext";
import { useFetch } from "../../hooks/useFetch";
import { get_constants } from "../../constants/apis";
import { Controller, useFormContext } from "react-hook-form";
import { FullRegistrationFormType } from "../../schemas/FullRegistrationFormSchema";
import { ReactNode } from "react";

type Props = {
  label: string;
  placeholder: string;
  name: string;
  category: string;
  error?: string;
  element?: ReactNode;
};

type ResponseType = {
  code: string;
  description: string;
};

export default function CustomDropdown({
  label,
  placeholder,
  name,
  category,
  error,
  element,
}: Props) {
  const { control } = useFormContext<FullRegistrationFormType>();

  const { jwtToken, user } = useAppContext();

  const { data, loading } = useFetch<ResponseType[]>(
    get_constants,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ category, region: user?.company_region }),
    },
    user?.company_region,
    category
  );

  if (loading)
    return (
      <div className="animate-pulse w-full space-y-1">
        <div className="rounded-lg bg-slate-300 h-3 w-[50%]"></div>
        <div className="rounded-lg bg-slate-300 h-11"></div>
      </div>
    );

  return (
    <Controller
      control={control}
      name={name as any}
      render={({ field }) => (
        <Dropdown
          label={label}
          element={element}
          placeholder={placeholder}
          options={
            data?.map((el) => ({
              label: el.description,
              value: el.code,
            })) || []
          }
          {...field}
          error={error}
        />
      )}
    />
  );
}
