import { Link } from "react-router-dom";

export const tasks = [
  {
    id: 1,
    name: "Phoenix Baker",
    message:
      "Welcome to Taram. Perform full KYB process to access all platform features.",
    time: "Just now",
  },
  {
    id: 2,
    name: "You",
    message: "Ful KYB registration will be done within 24 hours",
    time: "2 mins ago",
  },
  {
    id: 3,
    name: "Phoenix Baker",
    message:
      "Welcome to Taram. Perform full KYB process to access all platform features.",
    time: "Just now",
  },
  {
    id: 4,
    name: "Eva Leroy",
    message: "Ful KYB registration will be done within 24 hours",
    time: "2 mins ago",
  },
];

export default function Task() {
  return (
    <section className="card" id="dashboard-task">
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-[#030303] font-semibold text-xl">Task</h2>

        <div>
          <Link to={""} className="text-custom-blue-new font-semibold">
            View all
          </Link>
        </div>
      </div>

      <div className="space-y-5">
        {tasks.map((task) => (
          <div key={task.id} className="flex items-center gap-3">
            <div>
              <div className="w-[48px] h-[48px] bg-gray-400 rounded-full"></div>
            </div>

            <div>
              <p className="text-sm font-semibold leading-2">
                {task.name}{" "}
                <span className="font-normal text-xs text-gray-400">
                  {task.time}
                </span>
              </p>
              <small className="text-gray-400">{task.message}</small>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}
