import { useState } from "react";
import { useIFBuyerDataContext } from "../../context/IFBuyerDataContext";

import Dropdown from "../DropDown";
import InputWithLabel from "../InputWithLabel";
import Tooltip from "../Tooltip";

const options = [
  { value: "weekly", label: "Weekly" },
  { value: "monthly", label: "Monthly" },
  { value: "yearly", label: "Yearly" },
];

export default function Quotes() {
  const { buyerData, loading } = useIFBuyerDataContext();
  const [showId, setShowId] = useState();

  const trancateValue = (value: string) =>
    value.length > 3 ? value.slice(0, 2) + "..." : value;

  return (
    <section className="card" id="dashboard-quotes">
      <div className="flex items-center justify-between">
        <h2 className="text-[#030303] font-semibold text-xl">Quotes</h2>

        <div className="w-[360px] flex items-center justify-end gap-2">
          <div className="w-[100px]">
            <Dropdown label="" options={options} />
          </div>
          <div className="w-[100px]">
            <InputWithLabel label="" placeholder="Search" />
          </div>
        </div>
      </div>

      <table className="min-w-full border border-[#EAECF0] text-left rounded-xl border-separate">
        <thead>
          <tr>
            <th className="font-normal text-[#475467] px-4 py-3">Id</th>
            <th className="font-normal text-[#475467]">Product</th>
            <th className="font-normal text-[#475467]">Date</th>
            <th className="font-normal text-[#475467]">Action</th>
          </tr>
        </thead>

        <tbody className="text-gray-500">
          {loading
            ? [1, 2, 3].map((el) => <TableRowSkeleton key={el} />)
            : buyerData &&
              buyerData?.map((el) => (
                <tr key={el.invoice_buyer_id}>
                  <td className="p-4 border-t text-sm border-[#EAECF0]">
                    <div className="group">
                      <p> {trancateValue(el.reg_no)}</p>
                      <p className="hidden group-hover:block mt-2 absolute px-4 py-1 text-white bg-gray-800 rounded-md">
                        {el.reg_no}
                      </p>
                    </div>
                  </td>
                  <td className="border-t border-[#EAECF0]">
                    <div className="inline-flex items-center gap-2">
                      {/* <img src={el.logo} alt="logo" /> */}
                      <div className="w-7 h-6 bg-slate-300 rounded-full flex justify-center items-center font-medium">
                        {el.company_name.at(0)?.toUpperCase()}
                      </div>
                      <div className="group">
                        <p className="text-sm h-4 overflow-hidden">
                          {el.company_name}
                        </p>
                        <p className="hidden group-hover:block absolute mt-2 px-4 py-1 text-sm text-white shadow-md bg-gray-800 rounded-md">
                          {el.company_name}
                        </p>
                      </div>
                    </div>
                  </td>
                  <td className="border-t text-xs border-[#EAECF0]">
                    {new Date(el.created_at).toDateString()}
                  </td>
                  <td className="border-t border-[#EAECF0]"></td>
                </tr>
              ))}
        </tbody>
      </table>

      {!loading && buyerData === null && (
        <p className="text-gray-500 p-4 text-center">No records found</p>
      )}
    </section>
  );
}

export function TableRowSkeleton() {
  return (
    <tr className="animate-pulse">
      <td className="p-4 border-t border-[#EAECF0]">
        <div className="h-4 bg-slate-300 rounded"></div>
      </td>
      <td className="border-t border-[#EAECF0]">
        <div className="h-4 bg-slate-300 rounded"></div>
      </td>
      <td className="border-t border-[#EAECF0]">
        <div className="h-4 bg-slate-300 rounded"></div>
      </td>
      <td className="border-t border-[#EAECF0]">
        <div className="h-4 bg-slate-300 rounded"></div>
      </td>
    </tr>
  );
}
