import { z } from "zod";

export const BuyerCompanyFormSchema = z.object({
  invoice_buyer_details: z.array(
    z.object({
      invoice_buyer_id: z.number().optional(),
      company_name: z.string().min(3, { message: "Company name is required" }),
      reg_no: z.string().min(3, { message: "Registration number is required" }),
      company_address: z
        .string()
        .min(3, { message: "Company address is required" }),
      // contact_person_name: z
      //   .string()
      //   .min(3, { message: "Contact person name is required" }),
      // phone_number: z
      //   .string()
      //   .min(3, { message: "Contact person name is required" }),
      // email: z
      //   .string()
      //   .email("Enter a valid email ")
      //   .min(3, { message: "Email is required" }),

    })
  ),
});

export type BuyerCompanyFormType = z.infer<typeof BuyerCompanyFormSchema>;

export const CreditLimitFormSchema = z.object({
  limit_request: z.preprocess(
    (arg) => (typeof arg === "string" ? Number(arg) : arg),
    z
      .number({ message: "Expect a number" })
      .min(1, "Enter a valid Limit request amount")
      .max(100000000)
  ),
  // payment_terms: z.string().min(3, { message: "Payment terms is required" }),
  inco_terms: z.string().min(3, { message: "inco terms is required" }),

  // terms_conditions_1: z.boolean().refine((value) => value === true, {
  //   message: "You must accept the terms and conditions",
  // }),
  // terms_conditions_2: z.boolean().refine((value) => value === true, {
  //   message: "You must accept the terms and conditions.",
  // }),
  // company_name: z.string().min(3, { message: "Company name is required" }),
  // reg_no: z.string().min(3, { message: "Registration number is required" }),
  // company_address: z
  //   .string()
  //   .min(3, { message: "Company address is required" }),
});

export type CreditLimitFormType = z.infer<typeof CreditLimitFormSchema>;
