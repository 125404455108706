import { FormEvent, useRef, useState } from "react";

import {
  add_nium_fund_wallet,
  nium_virtual_account_details,
} from "../../constants/apis";
import InputWithLabel from "../../components/InputWithLabel";
import CrossIcon from "../../components/Icons/CrossIcon";

import { useAppContext } from "../../context/AppContext";
import USAFlag from "../../assets/img/USA-flag.png";
import Chart2 from "../../assets/img/chart2.png";
import AddIcon from "../Icons/AddIcon";
import { useWalletFundContext } from "../../context/WalletFundContext";
import SpinnerIcon from "../Icons/SpinnerIcon";
import { toast } from "react-toastify";
import { useFetch } from "../../hooks/useFetch";

export default function WalletCard() {
  const { walletData: walletDataArr, loading } = useWalletFundContext();

  const walletData = walletDataArr?.find((el) => el.curSymbol === "USD");

  return (
    <div className="flip-card card">
      {loading ? (
        <div className="animate-pulse">
          <div className="space-y-6 py-1">
            <div className="h-4 w-[60%] bg-slate-300 rounded"></div>
            <div className="space-y-3">
              <div className="h-8 bg-slate-300 rounded"></div>
              <div className="h-2 w-[40%] bg-slate-300 rounded"></div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flip-card-inner relative w-full h-full">
          <div className="flip-card-front">
            <div className="flex items-center justify-between mb-2">
              <h2 className="font-semibold text-lg">
                {walletData?.curSymbol} - Account
              </h2>
              <img src={USAFlag} alt="usa flag" />
            </div>

            <div className="flex justify-between items-center">
              <div>
                <p>
                  <span className="font-bold text-3xl">
                    {walletData?.balance}
                  </span>{" "}
                  <span className="font-medium text-xs text-[#9C9FAD]">
                    / This Month
                  </span>
                </p>
                <small className="text-[#9C9FAD]">100.00.last month</small>
              </div>

              <div>
                <img src={Chart2} alt="chart" />
              </div>
            </div>
          </div>
          <WalletCardFlip />
        </div>
      )}
    </div>
  );
}

type NiumVirtualAccountDetails = {
  currencyCode: string;
  uniquePaymentId: string;
  uniquePayerId: null;
  bankName: string;
  fullBankName: string;
  routingCodeType1: string;
  routingCodeValue1: string;
  routingCodeType2: string;
  routingCodeValue2: string;
  accountName: string;
  accountType: string;
  bankAddress: string;
  accountCategory: null;
  tags: [];
  status: string;
};

type NiumVirtualAccountDetailsRes = {
  content: NiumVirtualAccountDetails[];
};

function WalletCardFlip() {
  const [btnLoading, setBtnLoading] = useState(false);
  const addWalletBalanceModalRef = useRef<HTMLDialogElement>(null);

  const { jwtToken, user } = useAppContext();
  const { refetch } = useWalletFundContext();

  const { data } = useFetch<NiumVirtualAccountDetailsRes>(
    nium_virtual_account_details,
    {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        "content-type": "application/json",
      },
    }
  );

  const virtualBankData = data?.content?.at(0);

  const addBalanceModal = () => {
    if (user?.status !== "Clear") {
      toast.warning("Your account KYB status is still pending");

      return;
    }

    addWalletBalanceModalRef.current?.showModal();
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setBtnLoading(true);

    const formData = new FormData(e.currentTarget);
    const amount = formData.get("amount");

    const paylaod = {
      amount,
      destinationCurrencyCode: "USD",
      fundingChannel: "PREFUND",
      sourceCurrencyCode: "USD",
    };

    try {
      const res = await fetch(add_nium_fund_wallet, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          "content-type": "application/json",
        },
        body: JSON.stringify(paylaod),
      });
      const data = await res.json();

      console.log(data);

      if (data?.status === "success") {
        refetch();
        addWalletBalanceModalRef.current?.close();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setBtnLoading(false);
    }
  };

  return (
    <>
      <div className="flip-card-back">
        <h3 className="font-medium flex justify-between">
          Account Details:{" "}
          <button
            className="flex items-center gap-1 text-custom-dark-blue hover:underline"
            onClick={addBalanceModal}
          >
            <AddIcon className="size-4" /> add balance
          </button>
        </h3>
        {data?.status === "BAD_REQUEST" || !data ? (
          <div>
            <p className="text-gray-500 text-sm text-center mt-4">
              Account status is not active yet
            </p>
          </div>
        ) : (
          <div>
            <p className="text-[12px] flex justify-between">
              <span>Account No.:</span>{" "}
              <span className="text-[#6C6868]">
                {virtualBankData?.uniquePaymentId}
              </span>
            </p>
            <p className="text-[12px] flex justify-between">
              <span>Bank Name:</span>{" "}
              <span className="text-[#6C6868]">
                {virtualBankData?.fullBankName}
              </span>
            </p>
            <p className="text-[12px] flex justify-between">
              <span>Routing No:</span>{" "}
              <span className="text-[#6C6868]">{`${virtualBankData?.routingCodeType1} ${virtualBankData?.routingCodeValue1}`}</span>
            </p>
            <p className="text-[12px] flex justify-between">
              <span>Bank Address:</span>
              <span className="text-[#6C6868]">
                {virtualBankData?.bankAddress}
              </span>
            </p>
          </div>
        )}
      </div>

      <dialog
        ref={addWalletBalanceModalRef}
        className="p-6 w-[400px] space-y-4 rounded-md backdrop:bg-black/50"
      >
        <div>
          <button
            className="float-end"
            onClick={() => addWalletBalanceModalRef.current?.close()}
          >
            <CrossIcon className="size-6" />
          </button>

          <h2 className="text-xl font-bold text-gray-800">
            Add Wallet Balance
          </h2>
          <p className="text-sm text-gray-500">
            Lorem ipsum dolor sit amet consectetur adipisicing elit.
          </p>
        </div>

        <form onSubmit={handleSubmit}>
          <InputWithLabel
            label="Amount"
            placeholder="Enter a amount"
            name="amount"
            type="number"
            required
          />
          <button
            className="w-full h-[44px] rounded-md bg-custom-blue-medium text-white font-medium"
            disabled={btnLoading}
          >
            {btnLoading ? (
              <SpinnerIcon className="size-4 text-gray-200 animate-spin fill-blue-600 inline-flex justify-center" />
            ) : (
              "Add"
            )}
          </button>
        </form>
      </dialog>
    </>
  );
}
