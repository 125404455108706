import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAppContext } from "../context/AppContext";
import { add_logistic_request, get_logistic_quotes } from "../constants/apis";
import SearchInput from "../components/SearchInput";
import IconTextInput from "../components/IconTextInput";
import ShippingDropDown from "../components/ShippingDropDown";

// import calenderIcon from "../assets/icons/calendar.png";
import shipIcon from "../assets/icons/ships.png";
// import searchicon from "../assets/icons/svg/searchIcon.svg";
import { FormValuesType } from "./LCargoDetails";
import { toast } from "react-toastify";
import { getItemFromLocalStore, today } from "../utils";
import { Quote } from "./LSummary";

export const LQuotes = () => {
  const [quotes, setQuotes] = useState<Quote[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const [formVales, setFormValues] = useState<FormValuesType>(
    getItemFromLocalStore("cargo-details")
  );
  const [shippingTypePopUp, setShippingTypePopUp] = useState(false);
  const [requestLoading, setRequestLoading] = useState(false);

  const { jwtToken } = useAppContext();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const receivedData = location.state?.data;

    // console.log(receivedData);

    const fetchQuotes = async (receivedData: any) => {
      setIsLoading(true);

      try {
        const res = await fetch(get_logistic_quotes, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(receivedData),
        });

        const data = await res.json();
        // console.log(data);

        if (data.status === "success") {
          setQuotes(data.freights);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    if (receivedData) {
      fetchQuotes(receivedData);
    }
  }, [location.state]);

  const onToggle = () => setShippingTypePopUp(!shippingTypePopUp);

  const onSubmitHandler = async (e: React.FocusEvent<HTMLFormElement>) => {
    e.preventDefault();
    // console.log(formVales);

    setIsLoading(true);

    try {
      const res = await fetch(get_logistic_quotes, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          container_size: formVales.shipppingType?.container_size,
          freight_type: formVales.shipppingType?.freight_type,
          from_city: formVales.from.name,
          from_countrycode: formVales.from.countryCode,
          from_lat: formVales.from.lat,
          from_lng: formVales.from.lng,
          height: 0,
          length: 0,
          shipping_date: formVales.date,
          to_city: formVales.to.name,
          to_countrycode: formVales.to.countryName,
          to_lat: formVales.to.lat,
          to_lng: formVales.to.lng,
          weight: "1",
          width: 0,
        }),
      });

      const data = await res.json();
      console.log(data);

      if (data.status === "success") {
        setQuotes(data.freights);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const requestAQuote = async (quote: {}) => {
    // console.log(formVales, quote);
    setRequestLoading(true);

    try {
      const res = await fetch(add_logistic_request, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          cid: 123,
          container_size: formVales.shipppingType.container_size,
          freight_type: formVales.shipppingType.freight_type,
          from_city: formVales.from.name,
          from_countrycode: formVales.from.countryCode,
          from_lat: formVales.from.lat,
          from_lng: formVales.from.lng,
          height: "2.5",
          length: "12",
          shipping_date: formVales.date,
          to_city: formVales.to.name,
          to_countrycode: formVales.to.countryCode,
          to_lat: formVales.to.lat,
          to_lng: formVales.to.lng,
          weight: "2000",
          width: "2.5",
          freights: [quote],
        }),
      });

      const data = await res.json();

      console.log(data);

      if (data.status === "success") {
        localStorage.setItem("quote-details", JSON.stringify(quote));

        toast.success(data.msg);
        navigate("/logistics/summary");
      } else {
        toast.error("Something went wrong, please try again");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setRequestLoading(false);
    }
  };

  return (
    <>
      <div>
        <form onSubmit={onSubmitHandler} className="p-10 flex gap-2">
          <div>
            <label htmlFor="from">From</label>
            <SearchInput
              className="border border-1 p-2 w-[180px] rounded-md"
              placeholder="From"
              name="from"
              initialCity={`${formVales.from.name}, ${formVales.from.countryCode}`}
              setFormValues={setFormValues}
            />
          </div>
          <div>
            <label htmlFor="to">To</label>
            <SearchInput
              className="border border-1 p-2 w-[180px] rounded-md"
              placeholder="To"
              name="to"
              initialCity={`${formVales.to.name}, ${formVales.to.countryCode}`}
              setFormValues={setFormValues}
            />
          </div>
          <div>
            <label htmlFor="date">Date</label>

            <IconTextInput
              type="date"
              placeholder="09-Sep 2024"
              name="date"
              onFocus={(e) => {
                e.currentTarget.showPicker();
              }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setFormValues((prev) => ({
                  ...prev,
                  date: e.target?.value,
                }));
              }}
              value={formVales.date}
              min={today}
              className="w-[180px] !border p-2 !rounded-md cursor-pointer"
            />
          </div>

          <div>
            <label htmlFor="date">ETA</label>

            <div className="relative w-[180px]">
              <div
                className="text-input flex items-center gap-1"
                onClick={onToggle}
              >
                <img src={shipIcon} alt="shipIcon" />
                <p
                  className={`${
                    formVales.shipppingType.freight_type
                      ? "text-black"
                      : "text-[#aaa]"
                  }`}
                >
                  {formVales.shipppingType.freight_type
                    ? `${formVales.shipppingType.freight_type}, ${formVales.shipppingType.container_size}, ${formVales.shipppingType?.transportation_by}`
                    : "Shipping Type"}
                </p>
              </div>

              {shippingTypePopUp && (
                <div className="ml-[-100%] w-[400px] !mb-20">
                  <ShippingDropDown
                    setFormValues={setFormValues}
                    onToggle={onToggle}
                  />
                </div>
              )}
            </div>
          </div>

          <button className="bg-[#0348B7] h-11 px-2 mt-6 rounded-lg">
            {/* <img
              style={{ width: "40px", height: "30px" }}
              src={searchicon}
              alt="search"
            /> */}
            <p className="text-white">Search</p>
          </button>
        </form>

        {isLoading && <p className="text-center text-black my-5">loading...</p>}

        <div className="mx-10 space-y-4 !border-2 rounded-md">
          {quotes.length > 0 && (
            <div className="bg-custom-off-white border-b-2 flex justify-between p-5 rounded-t-md">
              <span>Title</span>
              <span>From</span>
              <span>To</span>
              <span>ETA</span>
              <span>Fees</span>
              <span>Action</span>
            </div>
          )}

          {quotes?.map((quote, index) => (
            <div
              key={quote.shipment_id}
              className={`bg-white text-sm grid grid-cols-6 px-4 pb-4 items-center ${
                index === quotes.length - 1 ? "border-none" : "border-b-2"
              }`}
            >
              <div className="">
                <img src={quote.logo} alt="logo" className="w-10" />
                <p className="text-custom-gray">{quote.name}</p>
              </div>
              <p className=" text-wrap text-custom-gray">{quote.from_city}</p>
              <p className=" text-wrap text-custom-gray">{quote.to_city}</p>
              <p className="ml-10 text-custom-gray">{quote.days}</p>
              <p className="ml-12 text-custom-gray">${quote.cost}</p>
              <div className="flex justify-end">
                <button
                  type="button"
                  className="bg-custom-blue text-white w-[100px] h-10 rounded-lg"
                  onClick={() => {
                    requestAQuote(quote);
                  }}
                  disabled={requestLoading}
                >
                  Request
                </button>
              </div>
            </div>
          ))}
        </div>

        <div>
          {!isLoading && quotes.length === 0 && (
            <p className="text-center text-black my-5">No results</p>
          )}
        </div>
      </div>

      <div className="space-x-4 p-10 flex justify-between items-center">
        <button
          type="button"
          onClick={() => navigate(-1)}
          className="w-[73px] h-[44px] px-2 text-[#344054] font-medium border border-[#D0D5DD] rounded-lg"
        >
          Back
        </button>

        <button
          type="submit"
          className="w-[100px] h-[44px] rounded-md bg-[#0348B7] text-white font-medium"
        >
          {/* {isSubmitting ? "Submitting.." : "Submit"} */}
          Save Now
        </button>
      </div>
    </>
  );
};
