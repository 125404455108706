import { Outlet } from "react-router-dom";
import SideNavbar from "../components/VTabBar/SideNavbar";
import {
  ChartBreakoutCircle,
  ChatBarIcon,
  DashboardIcon,
  QuotesIcon,
} from "../components/Icons";

const routes = [
  {
    id: 1,
    route: "/admin/dashboard",
    name: "Dashboard",
    content: "This is Admin dashboard page for manage admin tasks",
    icon: <DashboardIcon className="size-6" />,
  },
  {
    id: 2,
    route: "/admin/tasks",
    name: "Task",
    content: "Admin tasks page for manage user tasks",
    icon: <ChatBarIcon className="size-6" />,
  },
  {
    id: 3,
    route: "/admin/quotes",
    name: "Quotes",
    content: "Admin quotes page for manage user quotes",
    icon: <QuotesIcon className="size-6" />,
  },
  {
    id: 4,
    route: "/admin/customer-profiles",
    name: "Customer Profiles",
    content: "Admin customer profiles page for manage customer profiles",
    icon: <ChartBreakoutCircle className="size-6" />,
  },

  {
    id: 5,
    route: "/admin/vendors",
    name: "Vendors",
    content: "Admin vendors page for manage vendors",
    icon: <ChartBreakoutCircle className="size-6" />,
  },
];

export default function AdminPanelLayout() {
  return (
    <main className="flex">
      <SideNavbar routes={routes} />

      <section className="flex-1 ml-[264px] min-h-screen pb-10 bg-[#EAECF0]">
        <section className="max-w-[1128px] mx-auto">
          <Outlet />
        </section>
      </section>
    </main>
  );
}
