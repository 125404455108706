import {
  CheckIcon,
  FeaturesIcon,
  PricingIcon,
  QualityAssuranceIcon,
  StepsIcon,
} from "../Icons";

const FEATURES_ITEMS = [
  "Loading Inspection & Testing",
  "Physical Inspection Prior to Shipment",
  "Supplier Due Diligence",
  "Within 48 hours in India, Bangladesh & Nepal",
  "Compliance Checks",
  "Large Networks of Testing Labs",
];

const HOW_IT_WORKS_STEPS = [
  {
    id: 1,
    title: "Request Service",
    description: "Customer submits audit request via Taram Platform",
  },
  {
    id: 2,
    title: "Scheduling Inspection",
    description:
      "Taram schedules on-site inspection at vendor site within 48 hours",
  },
  {
    id: 3,
    title: "Document Preparation",
    description:
      "Taram sends a list of documents required for the inspection to the supplier",
  },
  {
    id: 4,
    title: "On-Site Inspection",
    description:
      "Taram’s agent performs on-site supplier inspection per customer requirements.",
  },
  {
    id: 5,
    title: "Inspection Report",
    description:
      "Taram’s agent compiles the inspection report inclusive of notes, photos, & videos.",
  },
  {
    id: 6,
    title: "Report Submission",
    description:
      "Customer receives the final vendor audit report from Taram within 48 hours of inspection",
  },
];

export default function Introduction() {
  return (
    <section className="space-y-8">
      <div>
        <h2 className="inline-flex items-center gap-2">
          <QualityAssuranceIcon className="size-6" />{" "}
          <span className="text-custom-blue-medium font-semibold text-[20px]">
            Quality Assurance
          </span>
        </h2>
        <p className="text-[#475467] text-sm">
          Taram facilitates booking of quality assurance services like vendor
          due diligence, container loading inspection and goods quality
          assurance for exporters and importers.
        </p>
      </div>

      <div className="grid grid-cols-[415px_1fr] gap-5">
        <div id="quality-pricing-estimate" className="p-5 border border-[#F3F3F5] rounded-[10px]">
          <h3 className="mb-4 font-semibold text-[18px] inline-flex items-center gap-2">
            <PricingIcon className="size-6" /> Pricing Estimate*
          </h3>

          <div className="text-sm text-[#475467] space-y-2">
            <p>Loading Inspection Fee: Rs. 25,000 / container</p>
            <p>Testing Charges: 0.65% of total consignment value</p>
            <ul className="list-disc pl-6">
              <li>Minimum charge of Rs. 25,000 / container per day</li>
            </ul>
            <p>Vendor Audit: Rs. 5,000 / day per manpower</p>
            <p>Comprehensive Service: 0.8% of shipment value</p>
            <ul className="list-disc pl-6">
              <li>Minimum charge of Rs. 50,000 / container</li>
              <li>
                Includes Inspection, Loading Supervision, Sampling, and Testing
              </li>
            </ul>
          </div>
        </div>

        <div className="p-5 border border-[#F3F3F5] rounded-[10px]">
          <h3 className="mb-4 font-semibold text-[18px] inline-flex items-center gap-2">
            <FeaturesIcon className="size-6" /> Features
          </h3>

          <div className="space-y-2 mt-3">
            {FEATURES_ITEMS.map((el, i) => (
              <p key={i} className="inline-flex items-center gap-2 text-sm">
                <CheckIcon className="size-4 text-custom-blue-medium" />
                <span className="text-[#475467]">{el}</span>
              </p>
            ))}
          </div>
        </div>

        <div id="quality-how-it-works" className="col-span-full p-5 border border-[#F3F3F5] rounded-[10px]">
          <h3 className="mb-10 font-semibold text-[18px] inline-flex items-center gap-2">
            <StepsIcon className="size-6" />
            How it works:
          </h3>

          <div className="grid grid-cols-6 gap-[5px] text-center">
            {HOW_IT_WORKS_STEPS.map((el) => (
              <div
                key={el.id}
                className={`relative ${
                  el.id % 2 === 0 ? "bg-[#F3F8FF]" : "bg-[#F3F3F3]"
                } py-6 px-2 rounded-lg`}
              >
                <div
                  className={`absolute -top-7 inset-0 mx-auto w-12 h-12 flex items-center justify-center ${
                    el.id % 2 === 0 ? "bg-[#F3F8FF]" : "bg-[#F3F3F3]"
                  } rounded-full border-4 border-white`}
                >
                  <p className="text-[18px] font-bold text-custom-blue-medium">
                    0{el.id}
                  </p>
                </div>

                <h4 className="text-[#475467] text-sm font-semibold mb-2">
                  {el.title}
                </h4>
                <p className="text-[10px] font-medium text-[#758599]">
                  {el.description}
                </p>
              </div>
            ))}
          </div>
        </div>

        <div className="col-span-full text-[#475467] text-sm space-y-3">
          <p>
            Note: Please contact our team to know the process for Loading
            Inspection & Testing
          </p>
          <p className="space-x-1">
            <span className="text-[#DC0000]">*Disclaimer:</span>
            <span>
              All offers of quality assurance are subject to further approvals
            </span>
          </p>
        </div>
      </div>
    </section>
  );
}
