import { useRef, useState } from "react";
import InputWithLabel from "../components/InputWithLabel";
import { useAppContext } from "../context/AppContext";
import { Link, useNavigate } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  SimpleRegistrationLoginSchema,
  SimpleRegistrationLoginType,
} from "../schemas/SimpleRegistrationFormSchema";
import { zodResolver } from "@hookform/resolvers/zod";
import { user_login, verify_user } from "../constants/apis";
import { toast } from "react-toastify";
import { SpinnerIcon, TaramLogo } from "../components/Icons";

export default function LoginPage() {
  const [isLoginFail, setIsLoginFail] = useState(false);

  const { setJwtToken } = useAppContext();
  const navigate = useNavigate();

  const dialogRef = useRef<HTMLDialogElement>(null);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    getValues,
  } = useForm<SimpleRegistrationLoginType>({
    resolver: zodResolver(SimpleRegistrationLoginSchema),
  });

  const email = getValues("email");

  const handleChange = () => {
    setIsLoginFail(false);
  };

  const onHandleSubmit: SubmitHandler<SimpleRegistrationLoginType> = async (
    values
  ) => {
    try {
      const response = await fetch(user_login, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      });
      const data = await response.json();

      // console.log(data);
      if (data.status === "success") {
        if (data.two_fa) {
          dialogRef.current?.showModal();
        } else {
          setJwtToken(data.jwt);
          toast.success("Login Successfully");

          navigate("/dashboard");
        }
      } else {
        setIsLoginFail(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="w-[400px] p-4 md:p-0">
      <Link to={"/"} className="hover:underline text-gray-600">
        &#x2190; Home
      </Link>

      <div className=" my-4">
        <Link to={"/"}>
          <TaramLogo className="w-28" />
        </Link>
      </div>

      <h2 className="text-3xl font-bold text-gray-800 mb-1 ">Welcome Back</h2>
      <p className="text-gray-600  mb-6">Login to access your account</p>

      <form onSubmit={handleSubmit(onHandleSubmit)} className="space-y-2">
        <InputWithLabel
          label="Email"
          id="email"
          placeholder="Enter your email"
          {...register("email", { onChange: handleChange })}
          error={errors.email?.message}
        />
        <InputWithLabel
          label="Password"
          id="password"
          placeholder="Enter your password"
          element={
            <Link
              to={"/forgot-password"}
              className="text-xs text-custom-blue-new hover:underline"
            >
              Forgot password?
            </Link>
          }
          type="password"
          {...register("password", { onChange: handleChange })}
          error={errors.password?.message}
        />

        <div className="h-[28px]">
          {isLoginFail && (
            <small className="bg-red-50 text-red-400 border border-red-400 p-[2px] rounded-md flex items-center gap-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z"
                />
              </svg>

              <span>Wrong Credentials</span>
            </small>
          )}
        </div>

        <button
          type="submit"
          disabled={isSubmitting}
          className="w-full flex justify-center items-center bg-custom-dark-blue-new text-white py-2 rounded-lg shadow-md hover:bg-blue-900 transition"
        >
          {isSubmitting ? (
            <SpinnerIcon className="size-6 text-gray-200 animate-spin fill-blue-600" />
          ) : (
            "Login"
          )}
        </button>
      </form>

      <p className="text-center text-sm text-gray-600 mt-4">
        Don't have an account?{" "}
        <Link to="/signup" className="text-blue-600 hover:underline">
          Sign up
        </Link>
      </p>

      <dialog
        className="relative py-5 px-10 rounded-lg backdrop:bg-black/40"
        ref={dialogRef}
      >
        <TwoFALogin email={email} />
      </dialog>
    </div>
  );
}

function TwoFALogin({ email }: { email: string }) {
  const [isLoginFail, setIsLoginFail] = useState(false);

  const { setJwtToken } = useAppContext();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<{ twoFAOtp: string }>();

  const handleChange = () => {
    setIsLoginFail(false);
  };

  const handleOnSubmit: SubmitHandler<{ twoFAOtp: string }> = async (
    values
  ) => {
    try {
      const res = await fetch(verify_user, {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          email,
          verify_code: values.twoFAOtp,
        }),
      });

      const data = await res.json();
      // console.log(data);

      if (data.status === "success") {
        setIsLoginFail(false);
        reset();

        setJwtToken(data.jwt);
        toast.success("Login Successfully");

        navigate("/dashboard");
      } else {
        setIsLoginFail(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <form
      className="w-[400px] py-4 space-y-4"
      onSubmit={handleSubmit(handleOnSubmit)}
    >
      <div className="text-center">
        <h2 className="text-[#1A1D21] text-2xl  font-bold mb-1">
          Two-Factor Authentication
        </h2>
        <p className="text-gray-600 text-sm">
          Please check your email for the One-Time Password (OTP) to complete
          your two-factor authentication.
        </p>
      </div>

      <InputWithLabel
        label="Enter Verification Code"
        id="verificationCode"
        type="text"
        {...register("twoFAOtp", {
          required: "Enter a OTP",
          minLength: { value: 6, message: "OTP must be at least 6 characters" },
          maxLength: { value: 6, message: "OTP must be at least 6 characters" },
          setValueAs: (value) => value.trim(),
          onChange: handleChange,
        })}
        error={errors.twoFAOtp?.message}
      />

      <div>
        <div className="h-[24px] mb-2">
          {isLoginFail && (
            <small className="bg-red-50 text-red-400 border border-red-400 p-[2px] rounded-md flex items-center gap-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z"
                />
              </svg>

              <span>Wrong OTP, please try again</span>
            </small>
          )}
        </div>

        <button
          type="submit"
          className="w-full p-2 bg-green-500 text-white rounded-md hover:bg-green-600"
        >
          {isSubmitting ? "Verifying.." : "Verify Code"}
        </button>
      </div>
    </form>
  );
}
