import Chart1 from "../assets/img/chart1.png";
import Chart2 from "../assets/img/chart2.png";
import Chart3 from "../assets/img/chart3.png";

import USAFlag from "../assets/img/USA-flag.png";
import EUFlag from "../assets/img/eu-flag.png";
import {
  AccountSummary,
  Task,
  History,
  Quotes,
  PartnerCompanies,
  Financing,
  Messages,
  Drafts,
  WalletCard,
  ActionBanner,
  GreetUser,
} from "../components/Dashboard";

import AddIcon from "../components/Icons/AddIcon";

const Dashboard = () => {
  return (
    <section>
      <ActionBanner />

      <GreetUser />
      <section className="grid grid-cols-3 gap-6">
        {/* <div className="card">
          <h2 className="font-semibold text-lg mb-2">Total Amount</h2>

          <div className="flex justify-between items-center">
            <div>
              <p>
                <span className="font-bold text-3xl">1257.00</span>{" "}
                <span className="font-medium text-xs text-[#9C9FAD]">
                  / This Month
                </span>
              </p>
              <small className="text-[#9C9FAD]">1050.00.last month</small>
            </div>

            <div>
              <img src={Chart1} alt="chart" />
            </div>
          </div>
        </div> */}

        {/* <div className="flip-card card">
          <div className="flip-card-inner relative w-full h-full">
            <div className="flip-card-front">
              <div className="flex items-center justify-between mb-2">
                <h2 className="font-semibold text-lg">USD - Account</h2>
                <img src={USAFlag} alt="usa flag" />
              </div>

              <div className="flex justify-between items-center">
                <div>
                  <p>
                    <span className="font-bold text-3xl">557.00</span>{" "}
                    <span className="font-medium text-xs text-[#9C9FAD]">
                      / This Month
                    </span>
                  </p>
                  <small className="text-[#9C9FAD]">100.00.last month</small>
                </div>

                <div>
                  <img src={Chart2} alt="chart" />
                </div>
              </div>
            </div>

            <div className="flip-card-back">
              <h3 className="font-medium">Account Details:</h3>
              <div>
                <p className="text-[12px] flex justify-between">
                  <span>Account No.:</span>{" "}
                  <span className="text-[#6C6868]">01299319329201</span>
                </p>
                <p className="text-[12px] flex justify-between">
                  <span>IBAN Account:</span>{" "}
                  <span className="text-[#6C6868]">PADG01299319329201</span>
                </p>
                <p className="text-[12px] flex justify-between">
                  <span>Routing No:</span>{" "}
                  <span className="text-[#6C6868]">12997</span>
                </p>
                <p className="text-[12px] flex justify-between">
                  <span>Bank Address:</span>
                  <span className="text-[#6C6868]">
                    Lorem Ipsum is the dummy dummy
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div> */}

        <WalletCard />

        <div className="card">
          <div className="flex items-center justify-between mb-2">
            <h2 className="font-semibold text-lg">EURO - Account</h2>
            <img src={EUFlag} alt="eu flag" />
          </div>

          <div className="flex justify-between items-center">
            <div>
              <p>
                <span className="font-bold text-3xl">700.00</span>{" "}
                <span className="font-medium text-xs text-[#9C9FAD]">
                  / This Month
                </span>
              </p>
              <small className="text-[#9C9FAD]">650.00.last month</small>
            </div>

            <div>
              <img src={Chart3} alt="chart" />
            </div>
          </div>
        </div>

        <div className="card flex flex-col justify-center items-center gap-2">
          <button className="flex items-center gap-2 font-semibold text-[#6F6D6D]">
            <AddIcon className="size-9" />
            <span>Add New Currency</span>
          </button>
          <p className="text-sm text-[#9C9FAD]">
            Add a new currency to your account
          </p>
        </div>

        {/* <AccountSummary /> */}
        {/* <Task /> */}

        <History />
        <Quotes />
        <Task />

        <AccountSummary />
        <Drafts />

        <PartnerCompanies />
      </section>

      <section className="grid grid-cols-2 gap-6 mt-6">
        <Financing />
        <Messages />
      </section>

      {/* <DashboardTour /> */}
    </section>
  );
};

export default Dashboard;
