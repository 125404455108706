import { useState } from "react";
import { ArrowLeftIcon, ArrowRightIcon } from "../Icons";

const customers = [
  {
    name: "Karur Fabrics",
    location: "Karur, India",
    tasks: 4,
    messages: 3,
  },
  {
    name: "Sharon Impex",
    location: "Salem, India",
    tasks: 2,
    messages: 2,
  },
  {
    name: "Sri Priya Auto Exports",
    location: "Tokyo, Japan",
    tasks: 1,
    messages: 0,
  },
  {
    name: "Red Chamber",
    location: "Los Angeles, USA",
    tasks: 0,
    messages: 0,
  },
];

const services = [
  "Payment",
  "Invoice Factoring",
  "Smart Escrow",
  "Logistics",
  "Quality Assurance",
  "Insurance",
];

const CustomerQuotes = () => {
  const [currentPage, setCurrentPage] = useState(1);

  return (
    <section className="card">
      <div className="mb-2 pb-3 border-b border-gray-200 flex justify-between items-center">
        <h2 className="text-[#030303] font-semibold text-xl">Quotes</h2>

        <div>
          <label className="flex items-center gap-1">
            <span className="text-gray-500 w-full">Start a Transaction</span>
            <select className="input-new">
              <option value="">Add a service</option>
              {services.map((el) => (
                <option key={el} value={el}>
                  {el}
                </option>
              ))}
            </select>
          </label>
        </div>
      </div>
      <table className="w-full">
        <thead>
          <tr className="border-b">
            <th className="font-normal text-[#475467] py-3">Transaction ID</th>
            <th className="font-normal text-[#475467] px-4 py-3">Client</th>
            <th className="font-normal text-[#475467] px-4 py-3">Date</th>
            <th className="font-normal text-[#475467] px-4 py-3">Task</th>
            <th className="font-normal text-[#475467] px-4 py-3">Messages</th>
          </tr>
        </thead>
        <tbody>
          {customers.map((el, i) => (
            <tr key={el.name}>
              <td className="flex justify-center items-center gap-2 py-4">
                {i}
              </td>
              <td className="text-center">{el.name}</td>
              <td className="text-center">{"20,Febraury 2025"}</td>
              <td className="text-center">{el.tasks}</td>
              <td className="text-center">{el.messages}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="flex justify-between items-center mt-20">
        <button
          disabled={currentPage === 1}
          className="px-4 py-1 border-2 border-gray-200 text-[#344054] font-medium rounded-lg flex items-center gap-1 disabled:text-gray-400"
        >
          <ArrowLeftIcon className="size-4" />
          Previous
        </button>

        <div className="flex items-center space-x-2">
          {[1, 2, 3, "...", 8, 9, 10].map((page, index) => (
            <button
              key={index}
              // onClick={() => typeof page === "number" && handlePageChange(page)}
              className={`px-3 py-1 rounded-lg text-sm font-medium transition-all ${
                currentPage === page
                  ? "bg-blue-500 text-white"
                  : "text-gray-600 hover:bg-gray-200"
              }`}
            >
              {page}
            </button>
          ))}
        </div>

        <button className="px-4 py-1 border-2 border-gray-200 text-[#344054] font-medium rounded-lg flex items-center gap-1 disabled:text-gray-400">
          Next <ArrowRightIcon className="size-4" />
        </button>
      </div>
    </section>
  );
};
export default CustomerQuotes;
