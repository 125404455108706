import { useState } from "react";
import { useAppContext } from "../../context/AppContext";
import InputWithLabel from "../InputWithLabel";
import Switch from "../Switch";
import CreditLimitDropDown from "./CreditLimitDropDown";
import RequestCreditLimit from "../RequestCreditLimit";
import BuyerInformation from "./BuyerInformation";

export type ApprovedFinance = {
  approved_finance_id: number;
  created_at: string;
  finance_partner_id: number;
  invoice_buyer_id: number;
  limit_request: string;
  payment_terms: string;
  term_conditions: number;
  uid: number;
};

export default function FundYourTransaction({
  // openRequestNewCreditLimitModal,
  step,
  setStep,
}: {
  step:boolean;
  setStep:React.Dispatch<React.SetStateAction<boolean>>
  // openRequestNewCreditLimitModal: () => void;
}) {
  // const [step, setStep] = useState<boolean>();
  
  return (
    <section className="mt-3">
      <Switch
        checked={step}
        onToggle={() => setStep(!step)}
        checkPayment={false}
        tagOne={"Request Credit Limit"}
        tagTwo={"Fund Your Transaction"}
      />
      {!step ? (
        <RequestCreditLimit
          // onClose={function (): void {
          //   throw new Error("Function not implemented.");
          // }}
        />
      ) : (
        <FundTransaction />
      )}
    </section>
  );
}

const FundTransaction = () => {
  const { user } = useAppContext();

  return (
    <section>
      {/* <h3 className="text-black text-2xl font-semibold mb-5"> */}
      {/* Request Credit Limit
      </h3> */}

      {/* <h3 className="text-black text-2xl font-semibold mb-5">
        Fund Your Transaction
      </h3> */}

      <div className="flex mt-4 gap-4">
        <InputWithLabel
          disabled={true}
          label={"Company Name"}
          defaultValue={user?.company_name}
        />
        <InputWithLabel
          disabled={true}
          label={"Registration Number"}
          defaultValue={user?.company_reg_number}
        />
        <InputWithLabel
          disabled={true}
          label={"Address"}
          defaultValue={`${user?.registered_addressLine1}, ${
            user?.registered_addressLine2
              ? user?.registered_addressLine2 + ","
              : ""
          } ${user?.registered_city}, ${user?.registered_state}, ${
            user?.registered_country
          }`}
        />
      </div>
      <div className="w-[260px]">
        <CreditLimitDropDown />
      </div>
      {/* <CreditLimitDropDown
        setSelectedFinancePartnerId={setSelectedFinancePartnerId}
        buyerData={buyerData}
      /> */}

      {/* <div className="py-5 border-b-2">
        <button
          type="button"
          className="h-[44px] px-2 text-[#0348B7] font-semibold border border-[#0348B7] rounded-lg"
          onClick={openRequestNewCreditLimitModal}
        >
          Request New Credit Limit
        </button>
      </div> */}
      <BuyerInformation
        openBuyerCompanyModal={function (): void {
          throw new Error("Function not implemented.");
        }}
        invoiceFactoringData={null}
      />
    </section>
  );
};
