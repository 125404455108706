import { BuyerCompanyData } from "../../pages/IFDetails";
import { useAppContext } from "../../context/AppContext";

import Dropdown from "../DropDown";

import { useFetch } from "../../hooks/useFetch";
import { get_finance_partner } from "../../constants/apis";
import { useFormContext } from "react-hook-form";
import { IFDetailsType } from "../../schemas/IFDetailsType";
import Tooltip from "../Tooltip";
import { credit_limit } from "../../constants/tooltipContents";

export default function CreditLimitDropDown() {
  const { jwtToken } = useAppContext();
  const {
    register,
    formState: { errors },
  } = useFormContext<IFDetailsType>();

  const { data, loading } = useFetch<
    {
      finance_partner_id: number;
      finance_partner_name: string;
    }[]
  >(get_finance_partner, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      "Content-Type": "application/json",
    },
  });

  const options =
    data?.map((el) => ({
      label: `${el?.finance_partner_name}`,
      value: String(el?.finance_partner_id),
    })) || [];

  return (
    <Dropdown
      element={<Tooltip content={credit_limit}/>}
      label={`Credit Limit ${loading ? "loading.." : ""}`}
      placeholder="Select a Finance Partner"
      options={options}
      id="clientCreditLimit"
      {...register("finance_partner_id")}
      error={errors.finance_partner_id?.message}
    />
  );
}

function getTotalCredit(
  buyerData: BuyerCompanyData[] | null,
  finance_partner_id: number
) {
  if (!buyerData) return 0;

  const filteredData = buyerData.filter(
    (el) => el.finance_partner_id === finance_partner_id
  );

  const totalCredit = filteredData.reduce((accumulator, current) => {
    return accumulator + Number(current.limit_request);
  }, 0);

  return totalCredit;
}
