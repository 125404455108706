import { useCallback, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ChevronUpDownIcon, SearchIcon } from "../Icons";
import { admin_get_all_users } from "../../constants/apis";
import { useFetch } from "../../hooks/useFetch";
import { AllUsersRes } from "../../pages/Admin/CustomerProfiles";
import { useAppContext } from "../../context/AppContext";
import { TableRowSkeleton } from "../Dashboard/Quotes";
import { debounce } from "../../utils";

export default function CustomerProfiles() {
  const [search, setSearch] = useState("");
  const navigate = useNavigate();

  const { adminJWTToken } = useAppContext();

  const { data, loading } = useFetch<AllUsersRes>(
    `${admin_get_all_users}?limit=4&search=${search}`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${adminJWTToken}`,
        "Content-Type": "application/json",
      },
    },
    search
  );

  const allUsersData = data?.user_data;

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const getDebouncedSearch = useCallback(debounce(handleOnChange, 500), []);

  return (
    <section className="card">
      <div className="flex justify-between items-center mb-3 pb-3 border-b border-gray-200">
        <h2 className="text-[#030303] font-semibold text-xl">
          Customer Profiles
        </h2>
        <Link
          className="text-custom-blue font-semibold"
          to={"/admin/customer-profiles"}
        >
          View All
        </Link>
      </div>

      <table className="w-full mt-2">
        <thead>
          <tr>
            <th className="font-normal text-[#475467] py-3">
              <div className="relative">
                <SearchIcon className="size-6 absolute top-[10px] left-2 text-gray-500" />
                <input
                  type="search"
                  placeholder="Search"
                  className="w-full p-[10px] pl-10 border border-gray-300 rounded-lg bg-[#fcfcfd] text-[0.9rem] text-gray-900 transition duration-300 focus:border-blue-500 focus:ring focus:ring-blue-200 outline-none"
                  onChange={getDebouncedSearch}
                />
              </div>
            </th>
            <th className="font-normal text-[#475467] px-4 py-3">
              <div className="flex items-center justify-center gap-1">
                <span>Tasks</span>
                <button>
                  <ChevronUpDownIcon className="size-6" />
                </button>
              </div>
            </th>
            <th className="font-normal text-[#475467] px-4 py-3">
              <div className="flex items-center justify-center gap-1">
                <span>Messages</span>
                <button>
                  <ChevronUpDownIcon className="size-6" />
                </button>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {loading
            ? [1, 2, 3].map((el) => <TableRowSkeleton key={el} />)
            : allUsersData &&
              allUsersData?.map((el) => (
                <tr key={el.uid} onClick={() => navigate(`summary/${2}`)}>
                  <td className="flex items-center gap-2 py-2">
                    <div>
                      <div className="w-[48px] h-[48px] bg-gray-400 rounded-full flex justify-center items-center">
                        {el.company_name.at(0)?.toUpperCase()}
                      </div>
                    </div>
                    <div>
                      <p className="text-sm font-semibold leading-2">
                        {el.company_name}
                      </p>
                      <small className="text-gray-400">
                        {el.company_reg_number}
                      </small>
                    </div>
                  </td>
                  <td className="text-center">1</td>
                  <td className="text-center">1</td>
                </tr>
              ))}
        </tbody>
      </table>
      {!loading && !data && (
        <p className="text-center text-gray-600">No record found</p>
      )}
    </section>
  );
}
