import React, { useEffect, useState } from "react";

import { UseFormGetValues, UseFormSetValue } from "react-hook-form";
import { FullRegistrationFormType } from "../schemas/FullRegistrationFormSchema";

interface SwitchProps {
  checked?: any;
  onToggle?: (checked: boolean) => void;
  ariaLabel?: string;
  className?: string;
  checkPayment?: boolean;
  labelOne: string;
  labelTwo: string;
  setValueWithHookForm: UseFormSetValue<FullRegistrationFormType>;
  getValues: UseFormGetValues<FullRegistrationFormType>;
  index: number;
}
const Switch1: React.FC<SwitchProps> = ({
  // checked,
  checkPayment,
  labelOne,
  labelTwo,
  onToggle,
  ariaLabel,
  className,
  index,
  setValueWithHookForm,
  getValues,
}) => {
  const [checked, setIsChecked] = useState(false);

  useEffect(() => {
    if (getValues(`founders.${index}.founder_type`)) {
      getValues(`founders.${index}.founder_type`) === 1
        ? setIsChecked(true)
        : setIsChecked(false);
    }
  }, []);

  const handleChange = () => {
    setIsChecked((prev) => {
      !prev
        ? setValueWithHookForm(`founders.${index}.founder_type`, 1)
        : setValueWithHookForm(`founders.${index}.founder_type`, 0);
      return !prev;
    });
    // console.log(checkboxRef.current?.checked);
  };

  return (
    <div className="inline-flex items-center gap-5 text-[#344054]">
      <p className={`${!checked ? "font-bold" : "font-medium"}`}>{labelOne}</p>

      <div
        className="w-[40px] h-[25px] bg-custom-blue-medium rounded-2xl inline-flex items-center p-1 cursor-pointer"
        role="switch"
        aria-checked={checked}
        aria-label={ariaLabel}
        onClick={handleChange}
      >
        <div
          className={`w-4 h-4 bg-white rounded-full shadow-lg transition-transform duration-300 ${
            checked ? "translate-x-4" : "translate-x-0"
          }`}
        />
      </div>
      <p className={`${checked ? "font-bold" : "font-medium"}`}>{labelTwo}</p>
    </div>
  );
};

export default Switch1;
