import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  SetStateAction,
  Dispatch,
} from "react";
import CookieService from "./cookies"; // Cookie service file for managing cookies
import { getItemFromLocalStore } from "../utils";
import { FullRegistrationFormType as User } from "../schemas/FullRegistrationFormSchema";
import { get_user_details } from "../constants/apis";
import { useFetch } from "../hooks/useFetch";

interface AppContextType {
  value: string;
  setValue: (value: string) => void;
  checked: boolean;
  setCheck: (checked: boolean) => void;
  jwtToken: string | null;
  adminJWTToken: string | null;
  setJwtToken: (token: string, key?: string) => void;
  removeJwtToken: () => void;
  checkAvailability: boolean;
  setCheckAvailability: Dispatch<SetStateAction<boolean>>;
  user: User | null;
  userLoading: boolean;
  refetchUser: () => void;
  isModalOpen: boolean;
  toggleModal: () => void;
  setModalContent: React.Dispatch<React.SetStateAction<React.ReactNode>>;
  modalContent: React.ReactNode;
}

const AppContext = createContext<AppContextType | undefined>(undefined);

export const AppProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [value, setValue] = useState<string>("default value");
  const [checked, setCheck] = useState<boolean>(false);
  const [jwtToken, setJwtTokenState] = useState<string | null>(
    CookieService.getCookie("jwtToken")
  );

  const [adminJWTToken, setAdminJWTToken] = useState<string | null>(
    CookieService.getCookie("adminToken")
  );

  const [checkAvailability, setCheckAvailability] = useState<boolean>(
    getItemFromLocalStore("check_availability") || false
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState<React.ReactNode>(null);

  const {
    data: user,
    loading: userLoading,
    refetch: refetchUser,
  } = useFetch<User>(
    jwtToken ? get_user_details : null,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        "Content-Type": "application/json",
      },
    },
    jwtToken!
  );

  // console.log("user ---", user);

  // Load isCheck Availability cookie on initial load.
  useEffect(() => {
    localStorage.setItem(
      "check_availability",
      JSON.stringify(checkAvailability)
    );
  }, [checkAvailability]);

  // Function to set JWT token and save it in cookies
  const setJwtToken = (token: string, key = "jwtToken") => {
    CookieService.setCookie(key, token, 7); // Store for 7 days
    key === "jwtToken" ? setJwtTokenState(token) : setAdminJWTToken(token);
  };

  // Function to remove JWT token
  const removeJwtToken = () => {
    CookieService.deleteCookie("jwtToken");
    CookieService.deleteCookie("adminToken");

    setJwtTokenState(null);
  };

  // log in and sign up modal toggle
  const toggleModal = () => {
    setIsModalOpen((prev) => !prev);
  };

  return (
    <AppContext.Provider
      value={{
        checkAvailability,
        setCheckAvailability,
        value,
        setValue,
        checked,
        setCheck,
        jwtToken,
        adminJWTToken,
        setJwtToken,
        removeJwtToken,
        user,
        userLoading,
        refetchUser,
        isModalOpen,
        toggleModal,
        modalContent,
        setModalContent,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (context === undefined) {
    throw new Error("useAppContext must be used within an AppProvider");
  }
  return context;
};
