import { useState } from "react";
import paymentImg from "../../assets/img/simplepaymentImg.png";
import invoicefactoringImg from "../../assets/img/invoiceFactoringImg.png";
import qualityAssuranceImg from "../../assets/img/qualityAssuranceImg.png";
import logisticsImg from "../../assets/img/logisitcsImg.png";
import tabimg2 from "../../assets/img/tabimg2.png";

import frieghtTwo from "../../assets/img/frieghtTwo.png";
import frieghtThree from "../../assets/img/frieghtServicesImg.png";
import qaServiceImg from "../../assets/img/qaServiceImg.png";
import ifServiceImg from "../../assets/img/ifserviceImg.png";
import qasideImg from "../../assets/img/qasideImg.png";
import logisticsSideImg from "../../assets/img/logisitcsSideImg.png";
import insuranceImg from "../../assets/img/insuranceImg.png";

const tabs = ["Payments", "Trade Finance", "Quality Assurance", "Freight"];

const ServiceDetails = () => {
  const [activeTab, setActiveTab] = useState(0); // Manage active tab index

  return (
    <section className="bg-gradient-to-b from-[#155DE9] to-[#9DB4E1]  pb-20">
      <div className="px-4 md:px-0">
        <p className="text-center text-[#E8EAED] pt-20 pb-5 font-bold text-2xl md:text-[37px]">
          Your neobank platform for <br /> international B2B cross-border trade
        </p>
        <p className="text-center text-[#E8EAED] text-[14.88px] pb-10">
          Software and APIs to manage everything from global payments, treasury,
          and spend to embedded finance.
        </p>
      </div>
      <div className="mt-4 px-2 md:px-0">
        <div className="flex justify-between md:gap-10 mb-4 bg-[#134BB8] rounded-full max-w-[500px] md:max-w-[740px] mx-auto">
          {tabs.map((tab, index) => (
            <button
              key={index}
              className={`py-1 md:py-3 px-2 md:px-5 text-center text-sm md:text-[18px] font-medium focus:outline-none ${
                activeTab === index
                  ? "bg-white text-black rounded-full px-2 md:px-6"
                  : "text-white"
              }`}
              onClick={() => setActiveTab(index)}
            >
              {tab}
            </button>
          ))}
        </div>

        <div className="p-4 ">
          {activeTab === 0 && (
            <div className="container px-4 md:px-20 mx-auto grid grid-cols-1 md:grid-cols-[4fr_3fr] grid-rows-1 text-white gap-5">
              <img src={paymentImg} alt="simple_payment_image" />
              <div className="card !bg-custom-blue-medium !border-0 !p-10 space-y-4  text-white text-2xl">
                <p className="text-semibold text-sm">FEATURES</p>
                <p>Open local currency accounts in 100+ currencies</p>
                <p>
                  Document based remittance Smart escrow for fraud prevention
                </p>
                <p>FX Save upto 90% on forex compared to banks</p>
                <p>Near Instant payouts Upto 220+ countries instant payouts</p>
                <p>Low Escrow & transfer fee</p>
                <p>Payment infrastructure made for Exporters & importers</p>
              </div>
            </div>
          )}

          {activeTab === 1 && (
            <div className="container px-4 md:px-20 mx-auto grid grid-cols-1 md:grid-cols-[4fr_3fr] grid-rows-1 text-white gap-5">
              <img src={invoicefactoringImg} alt="invoice_factoring_image" />
              <div className="card !bg-custom-blue-medium !border-0 !p-10 space-y-4  text-white text-2xl">
                <p className="text-semibold text-sm">FEATURES</p>
                <p>Quick Invoice Approvals 48Hrs upon loading docs</p>
                <p>Flexible Financing Options Upto 120 days</p>
                <p>Competitive Rates 12-14% pa interest rate</p>
                <p>Limits Pre-approved limit 1 to 50 Million USD</p>
                <p>No collateral required Upto 90% paid upfront</p>
              </div>
            </div>
          )}

          {activeTab === 2 && (
            <div className="container px-4 md:px-20 mx-auto grid grid-cols-1 md:grid-cols-[4fr_3fr] grid-rows-1 text-white gap-5">
              <img src={qualityAssuranceImg} alt="" className="" />

              <div className="card !bg-custom-blue-medium !border-0 !p-10 text-2xl space-y-4">
                <p className="text-gray-300 font-bold text-sm">FEATURES</p>
                <p>Loading Inspection & Testing</p>
                <p>Physical Inspection Prior to Shipment</p>
                <p>Supplier Due Diligence</p>
                <p>Comprehensive Vendor Surveillance</p>
                <p>Within 48 hours in India, Bangladesh & Nepal</p>
                <p>Compliance Checks</p>
                <p>Large Networks of Testing Labs</p>
              </div>
            </div>
          )}

          {activeTab === 3 && (
            <div className="container px-4 md:px-20 mx-auto grid grid-cols-1 md:grid-cols-[4fr_3fr]  grid-rows-1 text-white gap-5">
              <img src={logisticsImg} alt="" className="" />
              <div className="card !bg-custom-blue-medium !border-0 !p-10 text-2xl space-y-4">
                <p className="text-gray-300 font-bold text-sm">FEATURES</p>
                <p>Book in 5 mins</p>
                <p>Instant quotes</p>
                <p>Instant FCL quotes</p>
                <p>Real-time rates</p>
                <p>RFQ with Price targets</p>
                <p>RFQ for LCL Quick requests</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};
export default ServiceDetails;
