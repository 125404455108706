import { Link } from "react-router-dom";
import { get_buyer_trading_info } from "../../constants/apis";
import { useAppContext } from "../../context/AppContext";

import { TradingInfoType } from "../../schemas/TradeInfoFormSchema";
import { useFetch } from "../../hooks/useFetch";

import Tasks from "../../components/Admin/Tasks";
import Quotes from "../../components/Admin/Quotes";
import CustomerProfiles from "../../components/Admin/CustomerProfiles";
import VendorProfiles from "../../components/Admin/VendorProfiles";
import GreetAdmin from "../../components/Admin/GreetAdmin";

type TradingInfoResType = TradingInfoType & {
  trading_id: number;
  created_at: string;
};

export default function AdminPanel() {
  return (
    <section className="grid grid-cols-2 gap-5">
      <GreetAdmin />
      <Tasks />

      <Quotes />
      <CustomerProfiles />
      <VendorProfiles />
    </section>
  );
}

export function BuyerList() {
  const { adminJWTToken } = useAppContext();

  const { data: buyerData, loading } = useFetch<TradingInfoResType[]>(
    adminJWTToken ? get_buyer_trading_info : null,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${adminJWTToken}`,
        "Content-Type": "application/json",
      },
    },
    "",
    "ALL_BUYER_DATA"
  );

  return (
    <div className="p-6 max-w-7xl mx-auto">
      <h1 className="text-2xl font-semibold mb-4">Buyers List</h1>

      <div className="bg-white shadow-md rounded-lg p-4">
        <table className="w-full text-left border-collapse">
          <thead>
            <tr className="bg-gray-200">
              <th className="p-3">Name</th>
              <th className="p-3">Email</th>
              <th className="p-3">Ceated at</th>
              <th className="p-3">Action</th>
            </tr>
          </thead>
          <tbody>
            {!loading ? (
              buyerData?.map((buyer) => (
                <tr
                  key={buyer.trading_id}
                  className="border-b hover:bg-gray-100"
                >
                  <td className="p-3">{buyer.buyer_name}</td>
                  <td className="p-3">{buyer.contact_persion_email}</td>
                  <td className="p-3">{buyer.created_at}</td>
                  <td className="p-3">
                    <Link
                      to={`buyer/${buyer.trading_id}`}
                      className="text-blue-600 hover:underline"
                    >
                      View Details
                    </Link>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td>
                  <p>loading ..</p>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
