export default function ChartBreakoutCircle({
  className,
}: {
  className: string;
}) {
  return (
    <svg
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      className={className}
    >
      <path
        d="M15.5 4.10742V2.60742M19.4393 5.16808L20.5 4.10742M20.5103 9.10742H22.0103M21.9506 13.6074C21.4489 18.6607 17.1853 22.6074 12 22.6074C6.47715 22.6074 2 18.1303 2 12.6074C2 7.42207 5.94668 3.15852 11 2.6568M12 8.60742H16V12.6074M15.6197 8.60742C13.2653 11.935 9.38636 14.1074 5 14.1074C3.9971 14.1074 3.02072 13.9939 2.08302 13.7789"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
