const base = "https://taram.io/new";
export const add_insurance_details = `${base}/apis/insurance_details/add.php`;
export const update_insurance_details = `${base}/apis/insurance_details/update.php`;
export const get_insurance_details = `${base}/apis/insurance_details/get.php`;
export const register = `${base}/apis/user/register.php`;
export const add_sender_details = `${base}/apis/sender_details/add.php`;
export const get_sender_details = `${base}/apis/sender_details/get.php`;
export const update_sender_details = `${base}/apis/sender_details/update.php`;
export const add_sender_accnt_details = `${base}/apis/sender_accnt_details/add.php`;
export const get_sender_accnt_details = `${base}/apis/sender_accnt_details/get.php`;
export const update_sender_accnt_details = `${base}/apis/sender_accnt_details/update.php`;
export const add_beneficary_details = `${base}/apis/beneficiary_details/add.php`;
export const get_beneficary_details = `${base}/apis/beneficiary_details/get.php`;
export const update_beneficary_details = `${base}/apis/beneficiary_details/update.php`;
export const add_beneficary_accnt_details = `${base}/apis/beneficiary_accnt_details/add.php`;
export const get_beneficary_accnt_details = `${base}/apis/beneficiary_accnt_details/get.php`;
export const update_beneficary_accnt_details = `${base}/apis/beneficiary_accnt_details/update.php`;
export const get_simple_payment = `${base}/apis/simple_payment/get.php`;
export const add_simple_payment = `${base}/apis/simple_payment/add.php`;
export const update_simple_payment = `${base}/apis/simple_payment/update.php`;
export const get_all_simple_payment_details = `${base}/apis/simple_payment/get_all.php`;
export const get_country_details = `${base}/apis/common/getconstant.php`;
export const add_country_origin = `${base}/apis/check_availability/add.php`;
export const get_country_origin = `${base}/apis/check_availability/get.php`;
export const add_smart_escrow_details = `${base}/apis/smart_escrow/add.php`;
export const get_smart_escrow_details = `${base}/apis/smart_escrow/get.php`;
export const update_smart_escrow_details = `${base}/apis/smart_escrow/update.php`;
export const get_user_details = `${base}/apis/users/get.php`;
export const add_quality_supplier_details = `${base}/apis/quality_supplier_details/add.php`;
export const get_quality_supplier_details = `${base}/apis/quality_supplier_details/get.php`;
export const update_quality_supplier_details = `${base}/apis/quality_supplier_details/update.php`;
export const get_quality_assurance = `${base}/apis/quality_assurance/get.php`;
export const update_quality_assurance = `${base}/apis/quality_assurance/update.php`;
export const add_quality_assurance = `${base}/apis/quality_assurance/add.php`;
export const get_invoice_buyer_details = `${base}/apis/invoice_buyer_details/get.php`;
export const add_invoice_buyer_details = `${base}/apis/invoice_buyer_details/add.php`;
export const update_invoice_buyer_details = `${base}/apis/invoice_buyer_details/update.php`;
export const add_invoice_factoring_details = `${base}/apis/invoice_factoring/add.php`;
export const get_invoice_factoring_details = `${base}/apis/invoice_factoring/get.php`;
export const update_invoice_factoring_details = `${base}/apis/invoice_factoring/update.php`;
export const update_credit_limit_user_details = `${base}/apis/invoice_factoring/user_update.php`;
export const get_insured_details = `${base}/apis/insured_details/get.php`;
export const add_insured_details = `${base}/apis/insured_details/add.php`;
export const update_insured_details = `${base}/apis/insured_details/update.php`;
export const get_insurance = `${base}/apis/insurance_details/get.php`;
export const add_insurance = `${base}apis/insurance_details/add.php`;
export const update_insurance = `${base}/apis/insurance_details/update.php`;
export const admin_login = `${base}/apis/admin/login.php`;
export const update_buyer_company_details = `${base}/apis/buyer_company_details/update.php`;
export const update_company_account_details = `${base}/apis/company_accnt_details/update.php`;
export const get_company_details = `${base}/apis/company_details/get.php`;
export const update_company_details = `${base}/apis/company_details/update.php`;
export const add_company_details = `${base}/apis/company_details/add.php`;
export const user_login = `${base}/apis/users/login.php`;
export const simple_user_registeration = `${base}/apis/users/register.php`;
export const verify_user = `${base}/apis/users/verify.php`;
export const add_policy_holder = `${base}/apis/insured_details/add.php`;
export const update_policy_holder = `${base}/apis/insured_details/update.php`;
export const get_policy_holder = `${base}/apis/insured_details/get.php`;

export const full_registration = `${base}/apis/users/update.php`;
export const get_simple_registration_data = `${base}/apis/users/get.php`;

export const common_file_upload = `${base}/apis/common/file_upload.php`;

// export const dummyToken = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJqdWdnYWFkIiwiYXVkIjoidXNlciIsImlhdCI6MTcyNzY4MDU2NSwibmJmIjoxNzI3NjgwNTY1LCJleHAiOjE3NTg3ODQ1NjUsImRhdGEiOnsidWlkIjoiMTciLCJuYW1lIjoieW9nZXNod2FyIiwiZW1haWwiOiJraGFuc29sZW1hbjMzMzNAZ21haWwuY29tIn19.ZAONR5lxJp-vtXnSVn9j8HxPyu4H0CQ2Jy7mU14Ks3o'

export const update_registration = `${base}/apis/users/update.php`;

export const get_geonames_api = "https://secure.geonames.org";
export const get_logistic_quotes = `${base}/apis/logistics/get_quotes.php`;
export const add_logistic_request = `${base}/apis/logistics/add.php`;
export const get_logistics_summary = `${base}/apis/logistics/get.php`;

export const reset_password = `${base}/apis/users/reset_password.php`;
export const add_security_questions = `${base}/apis/users/security_questions.php`;
export const get_security_questions = `${base}/apis/users/get_security_questions.php`;
export const update_security_questions = `${base}/apis/users/update_security_questions.php`;

export const two_factor_mail = `${base}/apis/users/two_factor_mail.php`;
export const verify_two_factor_otp = `${base}/apis/users/verify_two_factor_otp.php`;
export const delete_founder = `${base}/apis/users/founders/delete.php`;

export const get_finance_partner = `${base}/apis/common/finance_partner.php`;
export const update_approved_finance = `${base}/apis/invoice_buyer_details/update_approved_finance.php`;
export const get_approved_finance = `${base}/apis/invoice_buyer_details/get_approved_finance.php`;
export const add_approved_finance = `${base}/apis/invoice_buyer_details/add_approved_finance.php`;

export const update_buyer_finance_details = `${base}/apis/invoice_buyer_details/update_buyer_finance_details.php`;
export const get_all_invoice_buyer_details = `${base}/apis/invoice_buyer_details/get_all.php`;
export const get_all_quality_assurance_details = `${base}/apis/quality_assurance/get_all.php`;
export const get_all_invoice_factoring_details = `${base}/apis/invoice_factoring/get_all.php`;
export const delete_invoice_buyer_details = `${base}/apis/invoice_buyer_details/delete.php`;
export const get_constants = `${base}/apis/nium/sender/get_constants.php`;
export const forgot_password = `${base}/apis/users/forgot_password.php`;

// partners
export const get_all_beneficiary_details = `${base}/apis/beneficiary_details/get_all.php`;
export const get_all_insurance_data = `${base}/apis/insurance_details/get_all.php`;

export const service_submit_mail = `${base}/apis/users/service_submit_mail.php`;

// NIUM
export const get_nium_KYC_status = `${base}/apis/users/nium/get_nium_status.php`;
export const get_nium_wallet_balance = `${base}/apis/users/nium/get_wallet_balance.php`;
export const add_nium_fund_wallet = `${base}/apis/users/nium/add_fund_wallet.php`;
export const nium_currency_exchange = `${base}/apis/common/currency_exchange.php`;
export const nium_virtual_account_details = `${base}/apis/users/nium/virtual_account_details.php`;

// add 3rd party buyer
export const add_trading_info = `${base}/apis/trading_info/add.php`;
export const get_buyer_trading_info = `${base}/apis/trading_info/get.php`;

// admin verdors
export const add_vendors = `${base}/apis/admin/vendor/add.php`;
export const update_vendor = `${base}/apis/admin/vendor/update.php`;
export const get_vendors = `${base}/apis/admin/vendor/get.php`;

//  admin -- all users
export const admin_get_all_users = `${base}/apis/admin/users/get.php`;

// whatsapp customer file upload
export const whatsapp_file_upload = `${base}/apis/whatsapp/file_upload.php`;

// user - transaction summary
export const get_transaction_summary = `${base}/apis/transaction_summary/get.php`;

// admin
// transaction_list
export const transaction_list = `${base}/apis/admin/quotes/transaction_list.php`;
export const get_by_transaction_id = `${base}/apis/admin/quotes/get_by_transaction_id.php`;
